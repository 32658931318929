import React, { useState, useEffect } from "react";
import MaskedInput from "react-text-mask";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import translator from "../actions/translator";
import {
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  FormText,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Label,
  Alert,
} from "reactstrap";
import { apiActions } from "../apiCall/api.services";
import { formatDate } from "./helperMethod";

const PaymentModal = (props) => {
  let userAuth = JSON.parse(localStorage.getItem("userAuth"));
  const [customerName, setCustomerName] = useState({});
  const [inputs, setInputs] = useState({});

  useEffect(() => {
    apiActions
      .getCustomerSummary(
        userAuth.access_token,
        userAuth.clientID,
        userAuth.apiKey
      )
      .then((res) => {
        let dividedName = res.data.FullName.split(" ");
        setCustomerName({
          firstName: dividedName[0],
          surName: dividedName[1],
        });
      });
  }, []);

  const modalVisiblity = () => {
    props.isModalOpen(false);
  };

  const handleChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let phoneNumber = 0;
    for (let val of inputs.phoneNumber) {
      if (parseInt(val) || val === "0") {
        phoneNumber += val;
      }
    }

    const data = {
      refJobOrderID: props.jobOrderId,
      paymentAmount: props.totalCost,
      buyerName: inputs.buyerName ? inputs.buyerName : customerName.firstName,
      buyerSurname: inputs.buyerSurname
        ? inputs.buyerSurname
        : customerName.surName,
      buyerMobilePhone: "009" + phoneNumber,
      buyerEmail: inputs.Email,
      currency: "TRY",
      expireDate: formatDate(),
    };

    apiActions
      .createPaymentLink(userAuth.access_token, data)
      .then((response) => {
        if (response.data.Result === "OK") {
          const options = {
            title: "Title",
            message: "Message",
            closeOnEscape: true,
            closeOnClickOutside: true,
            onClickOutside: () => {},
            onKeypressEscape: () => {},
          };
          confirmAlert(options);
          confirmAlert({
            customUI: () => {
              return (
                <div
                  className="d-flex flex-column justify-content-center"
                  style={customAlertStyle}
                >
                  <h2>
                    {translator.paymentSuccess}
                    <i class="m-2 far fa-check-circle"></i>
                  </h2>
                  <h3>
                    <a href={response.data.PaymentLink} target="_blank">
                      {response.data.PaymentLink}
                    </a>
                  </h3>
                </div>
              );
            },
          });
        } else {
          const options = {
            title: "Title",
            message: "Message",
            closeOnEscape: true,
            closeOnClickOutside: true,
            onClickOutside: () => {},
            onKeypressEscape: () => {},
          };
          confirmAlert(options);
          confirmAlert({
            title: `${response.data.Result}`,
            message: "",
            buttons: [],
          });
        }
      })
      .catch((error) => {
        const options = {
          title: "Title",
          message: "Message",
          closeOnEscape: true,
          closeOnClickOutside: true,
          onClickOutside: () => {},
          onKeypressEscape: () => {},
        };
        confirmAlert(options);
        confirmAlert({
          title: `${translator.processError}`,
          message: "",
          buttons: [],
        });
      });
    modalVisiblity();
  };

  return (
    <>
      <Modal centered={true} isOpen={props.paymentModal}>
        <ModalHeader>
          <h2>{translator.createPaymentLink}</h2>
        </ModalHeader>
        <Form role="form" onSubmit={handleSubmit}>
          <ModalBody>
            <Row style={formRowContainer}>
              <FormGroup>
                <Label className="font-weight-bold">
                  {translator.buyerName}
                </Label>
                <Input
                  defaultValue={customerName.firstName}
                  style={inputStyle}
                  placeholder="Buyer Name"
                  id="buyerName"
                  name="buyerName"
                  type="text"
                  maxLength={12}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label className="font-weight-bold">
                  {translator.buyerSurname}
                </Label>
                <Input
                  defaultValue={customerName.surName}
                  style={inputStyle}
                  placeholder="Buyer Surname"
                  id="buyerSurname"
                  name="buyerSurname"
                  type="text"
                  onChange={handleChange}
                />
              </FormGroup>
            </Row>
            <FormGroup>
              <Label className="font-weight-bold">{translator.phone}</Label>
              <MaskedInput
                mask={[
                  "(",
                  /[5]/,
                  /\d/,
                  /\d/,
                  ")",
                  " ",
                  /\d/,
                  /\d/,
                  /\d/,
                  "-",
                  /\d/,
                  /\d/,
                  /\d/,
                  /\d/,
                ]}
                className="form-control"
                placeholder="Enter a phone Number"
                name="phoneNumber"
                onChange={handleChange}
                required
              />
              <FormText>Example (530) 000-0000</FormText>
            </FormGroup>
            <FormGroup>
              <Label className="font-weight-bold">{translator.email}</Label>
              <Input
                placeholder="Email"
                id="Email"
                name="Email"
                type="text"
                required
                onChange={handleChange}
              />
              <FormText>Example sssss@ssss.com</FormText>
            </FormGroup>

            <div className="d-flex justify-content-end">
              <h3 className="text-right mr-2 mt-2">
                {translator.totalCost}: {props.totalCost} TRY
              </h3>
            </div>
          </ModalBody>
          <ModalFooter>
            <Row>
              <Button type="submit" color="success">
                {translator.makePayment}
              </Button>{" "}
              <Button color="warning" onClick={modalVisiblity}>
                Cancel
              </Button>
            </Row>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const inputStyle = {
  width: "initial",
};

const formRowContainer = {
  display: "flex",
  justifyContent: "space-around",
  alignItems: "center",
  flexWrap: "wrap",
};

const customAlertStyle = {
  maxWidth: "40%",
  padding: "20px",
  margin: "0px auto",
  height: "300px",
  textAlign: "center",
  background: "#fff",
  borderRadius: "30px",
  boxShadow: "0 20px 75px rgba(0, 0, 0, 0.13)",
  color: "#666",
};
export default PaymentModal;
