import Index from "./views/Index.jsx";
import IndexTch from "./views/IndexTch.jsx";
import Profile from "./views/examples/Profile.jsx";
import Register from "./views/examples/Register.jsx";
import Login from "./views/examples/Login.jsx";
import ActiveOrders from "./views/examples/ActiveOrders.jsx";
import PastOrders from "./views/examples/PastOrders.jsx";
import ClaimList from "./views/examples/ClaimList.jsx";
import AllProducts from "./views/examples/AllProducts.jsx";
import OrderDetails from "./views/examples/OrderDetails.jsx";
import ClaimDetails from "./views/examples/ClaimDetails.jsx";
import SingleClaimDetails from "./views/examples/SingleClaimDetails.jsx";
import ForgotPassword from "./views/examples/ForgotPassword.jsx";
import userRole from "./actions/userRole";
import translator from "./actions/translator";
import DemandsManagement from "./views/examples/DemandsManagement";
import DemandsDetail from "./views/examples/DemandsDetail";
import { apiActions } from "./apiCall/api.services";
import { history } from "./index";
import Bills from "./views/examples/Bills.jsx";
import CreateBillInnerView from "./views/examples/CreateBillInnerView.jsx";
import RegisterProduct from './views/examples/RegisterProduct.jsx';
import BilkomWarrantyCodeActivation from './views/examples/BilkomWarrantyCodeActivation.jsx';


const urlLocation = window.location.hostname;
const checktch = urlLocation === "tchibodestek.servissoft.net";

const routes = [
  {
    path: "/index",
    component: Index,
    layout: "/admin",
    name: translator.dashboard,
    icon: "ni ni-tv-2 text-primary",
  },
  {
    path: "/active-orders",
    name: translator.activeOrders,
    icon: "ni ni-align-left-2 text-info",
    component: ActiveOrders,
    layout: "/admin",
  },

  {
    path: "/past-orders",
    name: translator.pastOrders,
    icon: "ni ni-align-left-2 text-red",
    component: PastOrders,
    layout: "/admin",
  },
  {
    path: "/all-products",
    name: translator.allProducts,
    icon: "ni ni-box-2 text-grey",
    component: AllProducts,
    layout: "/admin",
  },
  {
    path: "/claim-list",
    name: translator.claimList,
    icon: "ni ni-money-coins text-green",
    component: ClaimList,
    layout: "/admin",
  },
  {
    path: "/demands-management",
    name: translator.demandsManagement,
    icon: "fas fa-window-restore text-yellow",
    component: DemandsManagement,
    layout: "/admin",
  },
  {
    path: "/user-profile",
    name: translator.userInformation,
    icon: "ni ni-single-02",
    component: Profile,
    layout: "/admin",
  },
  // {
  //   path: "/registerProduct",
  //   name: translator.registerProduct,
  //   icon: "ni ni-single-02",
  //   component: RegisterProduct,
  //   layout: "/admin",
  // },
  {
    path: "/demands-detail",
    component: DemandsDetail,
    layout: "/admin",
  },
  {
    path: "/claim-details",
    component: ClaimDetails,
    layout: "/admin",
  },
  {
    path: "/single-claim-details",
    component: SingleClaimDetails,
    layout: "/admin",
  },
  {
    path: "/order-details",
    component: OrderDetails,
    layout: "/admin",
  },
  {
    path: "/login",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/user-profile",
    component: Profile,
    layout: "/admin",
  },
  {
    path: "/forgot-password",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/bills",
    name: checktch && "Fatura Giriş",
    icon: checktch && "fas fa-file-invoice-dollar text-blue",
    component: Bills,
    layout: "/admin",
  },

  {
    path: "/bills-detail",
    component: CreateBillInnerView,
    layout: "/admin",
  },
  {
    path: "/enter-bill",
    component: CreateBillInnerView,
    layout: "/admin",
  }
];

const isAuth = JSON.parse(localStorage.getItem("userAuth"));

if (isAuth !== null) {
  if (userRole().openNewOrder == false) {
    routes.splice(0, 1);
  }

  if (!userRole().canSeeDemandManagement) {
    routes.forEach((item, index) => {
      if (item.path === "/demands-management") {
        routes.splice(index, 1);
      }
    });
  }
  changeRoutesWithRole();
  /*
  if (isAuth.apiCompanyID === '2497') {
    changeRoutesWithCompanyId();
  }
  */
}
/*
function changeRoutesWithCompanyId() {
  routes.forEach((item, index) => {
    if (item.path === '/index') {
      item.component = IndexTchibo;
    }
  });
}
*/
function changeRoutesWithRole() {
  const urlLocation = window.location.hostname;
  let checktch = urlLocation === "tchibodestek.servissoft.net";
  let checkTTTech = urlLocation == "servis.ttteknoloji.com.tr";
  let checkBilkom = urlLocation == "bilkomdestek.servissoft.net";

  if (!userRole().canSeeCompanyClaims) {
    routes.forEach((item, index) => {
      if (item.path === "/claim-list") {
        routes.splice(index, 1);
      }
    });

    routes.forEach((item, index) => {
      if (item.path === "/index") {
        item.path = "/dashboard";
      }

      if (item.path === "/all-products") {
        routes.splice(index, 1);
        routes.unshift(item);
        item.path = "/index";
      }
    });
  }

  if (checkTTTech) {

    routes.push(
      {
        path: "/registerProduct",
        name: translator.registerProduct,
        icon: "ni ni-single-02",
        component: RegisterProduct,
        layout: "/admin",
      });
  }

  if(checkBilkom){
    routes.push(
      {
        path: "/bilkomwarrantycodeactivate",
        name: "Ek Garanti Akviasyonu",
        icon: "ni ni-single-02",
        component: BilkomWarrantyCodeActivation,
        layout: "/admin",
      });
  }

  if (checktch) {
    const invisibleDashboard = {
      path: "/dashboard",
      component: Index,
      layout: "/admin",
    };
    routes.forEach((item, index) => {
      if (item.path === "/dashboard") {
        routes.splice(index, 1);
        routes.unshift(invisibleDashboard);
      }
    });
  }
}

export default routes;
