import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { apiActions } from '../../apiCall/api.services';
import { Redirect } from 'react-router-dom';

class UserHeader extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      fullName: '',
      firmName: '',
    };

    this.getCustomerSummary = this.getCustomerSummary.bind(this);
  }

  getCustomerSummary() {
    const currentComponent = this;
    const getCustomerSummaryPromise = apiActions.getCustomerSummary(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
    );
    getCustomerSummaryPromise.then(function(res) {
      console.log('profile',res);
      currentComponent.setState({
        fullName: res.data.FullName,
      });

      if (res.data.FirmName !== null) {
        currentComponent.setState({
          firmName: res.data.FirmName + ' - ',
        });
      }
    });
  }

  componentDidMount() {
    this.getCustomerSummary();
  }

  render() {
    return (
      <>
        <div
          className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
          style={{
            minHeight: '600px',
            backgroundImage:
              'url(' +
              require('../../assets/img/theme/profile-cover.jpg').default +')',
            backgroundSize: 'cover',
            backgroundPosition: 'center top',
          }}
        >
          {/* Mask */}
          <span className="mask bg-gradient-default opacity-8" />
          {/* Header container */}
          <Container className="d-flex align-items-center" fluid>
            <Row>
              <Col lg="12" md="12">
                <h1 className="display-2 text-white">
                {this.state.firmName} 
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default UserHeader;
