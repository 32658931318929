import React, { useEffect, useState } from "react";
import { apiActions } from "../apiCall/api.services";
import Select from "react-select";

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

const AddressSelection = ({ updateAddress, existAddress = "" }) => {
  const [selectedProvinceValue, setSelectedProvinceValue] = useState();
  const [selectedDistrictValue, setSelectedDistrictValue] = useState();
  const [selectedStreetValue, setSelectedStreetValue] = useState();
  const [addressInfoValue, setAddressInfoValue] = useState();
  const [directionValue, setDirectionValue] = useState();

  const [provincesDataOptions, setProvincesDataOptions] = useState([]);
  const [districtsDataOptions, setDistrictsDataOptions] = useState([]);
  const [streetDataOptions, setStreetDataOptions] = useState([]);

  useEffect(() => {
    getProvinces();
  }, []);

  useEffect(() => {
    if (existAddress) {
      setSelectedProvinceValue({
        label: existAddress[0].Province,
        value: existAddress[0].ProvinceID,
      });
      setSelectedDistrictValue({
        label: existAddress[0].District,
        value: existAddress[0].DistrictID,
      });
      setSelectedStreetValue({
        label: existAddress[0].StreetOrVillage,
        value: existAddress[0].StreetOrVillageID,
      });
      setAddressInfoValue(existAddress[0].SingleAddress);
      setDirectionValue(existAddress[0].AddresSpecs);
    }
  }, [existAddress]);

  useEffect(() => {
    updateAddress({
      province: selectedProvinceValue,
      district: selectedDistrictValue,
      street: selectedStreetValue,
      addressInfo: addressInfoValue,
      direction: directionValue,
    });
  }, [
    selectedProvinceValue,
    selectedDistrictValue,
    selectedStreetValue,
    addressInfoValue,
    directionValue,
  ]);

  useEffect(() => {
    selectedProvinceValue && getDistricts(selectedProvinceValue.value);
  }, [selectedProvinceValue]);

  useEffect(() => {
    selectedDistrictValue && getStreetorVillages(selectedDistrictValue.value);
  }, [selectedDistrictValue]);

  /*
    const getCountries = () => {
        const currentComponent = this;
        //const getCustomerSummaryPromise = apiActions.getCountries(this.state.userAuthOptions.accessToken,this.state.userAuthOptions.apiKey);
        const getCustomerSummaryPromise = apiActions.getCountries();
        getCustomerSummaryPromise.then(function (res) {
            setStreetDataOptions({ getCountriesData: res.data.data });
        });
      };
    */
  const getProvinces = () => {
    const currentComponent = this;
    let arr = [];
    //const getCustomerSummaryPromise = apiActions.getProviences(this.state.userAuthOptions.accessToken,this.state.userAuthOptions.apiKey);
    apiActions.getProviences().then((res) => {
      res.data.data.map((row, index) => {
        arr.push({
          value: row.ProvinceID,
          label: row.ProvinceName,
        });
      });
      setProvincesDataOptions(arr);
    });
  };

  const getDistricts = (ProvinceID, ProvinceName) => {
    let arr = [];
    const currentComponent = this;
    //const getCustomerSummaryPromise = apiActions.getDistricts(this.state.userAuthOptions.accessToken,this.state.userAuthOptions.apiKey,ProvinceID);
    apiActions.getDistricts(ProvinceID).then((res) => {
      res.data.data.map((row, index) => {
        arr.push({
          value: row.DistrictID,
          label: row.DistrictName,
        });
      });

      setDistrictsDataOptions(arr);
    });
  };

  const getStreetorVillages = (DistrictID) => {
    let arr = [];
    const currentComponent = this;
    //const getCustomerSummaryPromise = apiActions.getStreetorVillages(this.state.userAuthOptions.accessToken,this.state.userAuthOptions.apiKey,DistrictID);
    const getCustomerSummaryPromise =
      apiActions.getStreetorVillages(DistrictID);
    getCustomerSummaryPromise.then(function (res) {
      res.data.data.map((row, index) => {
        arr.push({
          value: row.StreetOrVillageID,
          label: row.StreetOrVillageName,
        });
      });

      setStreetDataOptions(arr);
    });
  };

  const handleSelectProvince = (selectedItem) => {
    setSelectedProvinceValue(selectedItem);
    setSelectedDistrictValue("");
    setSelectedStreetValue("");
    getDistricts(selectedItem.value);
  };

  const handleSelectDistrict = (selectedItem) => {
    //this.handleChange(event);
    setSelectedDistrictValue(selectedItem);
    setSelectedStreetValue("");
    getStreetorVillages(selectedItem.value);
  };

  const handleSelectStreet = (selectedItem) => {
    // this.handleChange(event);
    setSelectedStreetValue(selectedItem);
  };

  return (
    <>
      <FormGroup>
        <Select
          id="userCity"
          name="userCity"
          onChange={handleSelectProvince}
          options={provincesDataOptions}
          value={selectedProvinceValue}
          placeholder={"İl Seçiniz"}
        />
      </FormGroup>
      {selectedProvinceValue && (
        <FormGroup>
          <Select
            id="userDisctrict"
            name="userDisctrict"
            onChange={handleSelectDistrict}
            options={districtsDataOptions}
            value={selectedDistrictValue}
            placeholder={"İlçe Seçiniz"}
          />
        </FormGroup>
      )}

      {selectedDistrictValue && (
        <FormGroup>
          <Select
            id="userRegion"
            name="userRegion"
            onChange={handleSelectStreet}
            options={streetDataOptions}
            value={selectedStreetValue}
            placeholder={"Mahalle Seçiniz"}
          />
        </FormGroup>
      )}

      {selectedStreetValue && (
        <>
          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Input
                id="userStreet"
                name="userStreet"
                placeholder="Sokak / Bina Adı ve No / Daire"
                type="text"
                onChange={(e) => setAddressInfoValue(e.target.value)}
                required
                value={addressInfoValue}
              />
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <InputGroup className="input-group-alternative">
              <Input
                id="userAddress"
                name="userAddress"
                placeholder="Adres Tarifi"
                type="textarea"
                rows="4"
                value={directionValue}
                onChange={(e) => setDirectionValue(e.target.value)}
                required
              />
            </InputGroup>
          </FormGroup>
        </>
      )}
    </>
  );
};

export default AddressSelection;
