import React from "react";
import { apiActions } from "../../apiCall/api.services";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from "react-google-places-autocomplete";
// If you want to use the provided css
import "react-google-places-autocomplete/dist/assets/index.css";
import Select from "react-select";
import AddressSelection from "../../components/AddressSelection";
import CagCaptcha from "../../components/CagCaptcha";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroup,
  Row,
  Col,
  Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import { useState } from "react";

class Register extends React.Component {
  constructor(props) {
    super(props);

    //let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      /*userAuthOptions: {
                accessToken: userAuth.access_token,
                apiKey: userAuth.apiKey,
                clientID: userAuth.clientID,
            },*/
      form: {
        userName: "",
        userLastName: "",
        userPhone: "",
        userEmail: "",
        userCity: "",
        userCityName: "",
        userDisctrict: "",
        userRegion: "",
        userStreet: "",
        userAddress: "",
        emailAccepted: 0,
        smsAccepted: 0,
        callAccepted: 0
      },
      name: "",
      email: "",
      password: "",
      allData: "",
      getProvincesID: "",
      getCountriesData: [],
      getProvincesData: [],
      getDistrictsData: [],
      getStreetorVillagesData: [],
      googleAddress: {},
      googleAddressDescription: {},
      googleRawAddress: "",
      allAddressData: {},
      captchaValid: false,
      isSubmitForm: false,
    };

    this.getData = this.getData.bind(this);
    this.postAddCustomer = this.postAddCustomer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.myCostomerFormRef = React.createRef();
    const urlLocation = window.location.hostname;
    if (urlLocation === "destek.evofone.com")
      this.state.pageUrlLocation = "evo";
    else if (urlLocation === "servis.ttteknoloji.com.tr")
      this.state.pageUrlLocation = "ttt";
    else if (urlLocation == "servis.next.com.tr")
      this.state.pageUrlLocation = "next";


    this.state.emailModalOpen = false;
    this.state.smsModalOpen = false;
    this.state.emailModalOpen = false;
  }

  parseGoogleAddress(place_id) {
    geocodeByPlaceId(place_id)
      .then((results) => {
        const addressComponents = results[0].address_components;
        console.log("address results", results);
        var lastData = {};
        var fullData = "";

        var addressComponentsLong = " ";
        var addressCompType = " ";

        addressComponents.map(function (address_components, i) {
          addressCompType = address_components.types[0];
          addressComponentsLong = address_components.long_name;

          if (
            addressCompType == "country" ||
            addressCompType == "administrative_area_level_1" ||
            addressCompType == "administrative_area_level_2"
          ) {
            lastData[addressCompType] = addressComponentsLong;
          } else {
            fullData += addressComponentsLong + ",";
          }
        });
        fullData = fullData.slice(0, -1);
        this.setState({ googleAddress: fullData });
        this.setState({ googleRawAddress: results[0].formatted_address });
        this.setState({ googleAddressDescription: lastData });
      })
      .catch((error) => console.error(error));
  }

  handleChange(event) {
    const tempVal = event.target.value;
    const tempID = event.target.id;
    const store = this.state;
    store.form[event.target.name] = tempVal;
    this.setState(store);
  }

  componentDidMount() {
    this.getData();
    //this.getCountries();
    //this.getProvinces();
  }

  handleSubmit(event) {
    event.preventDefault();
    console.log("eeee", this.state.form.emailAccepted);
    this.setState({ isSubmitForm: true });
    this.postAddCustomer(
      this.state.form.userName,
      this.state.form.userLastName,
      this.state.form.userPhone,
      this.state.form.userEmail,
      this.state.allAddressData,
      this.state.form.emailAccepted, this.state.form.smsAccepted, this.state.form.callAccepted
    );
  }

  getData() {
    const currentComponent = this;
    const promise2 = apiActions.onLoadApi();
    promise2.then(function (val) {
      currentComponent.setState({ allData: val.data[0] });
    });
  }

  postAddCustomer(userName, userLastName, userPhone, userEmail, userAddress, emailAccepted, smsAccepted, callAccepted) {
    //const postUpdateCustomerPromise = apiActions.postAddCustomer(this.state.userAuthOptions.accessToken,this.state.userAuthOptions.clientID,this.state.userAuthOptions.apiKey,userName,userLastName,userPhone,userEmail,userCity,userDisctrict,userRegion,userStreet,userAddress);
    const postUpdateCustomerPromise = apiActions.postAddCustomer(
      userName,
      userLastName,
      userPhone,
      userEmail,
      userAddress,
      emailAccepted,
      smsAccepted,
      callAccepted
    );
    postUpdateCustomerPromise.then((res) => {
      this.myCostomerFormRef.current.reset();
      setTimeout(() => {
        this.setState({ isSubmitForm: false });
      }, 2000);
    });
  }

  handleUpdateAdress = (newAddressData) => {
    this.setState({ allAddressData: newAddressData });
  };

  handleReCAPTCHA = (status) => {
    this.setState({ captchaValid: status });
  };

  handleEmailModalOpen = () => { this.setState({ emailModalOpen: !this.state.emailModalOpen }) };
  handleSmsModalOpen = () => { this.setState({ smsModalOpen: !this.state.smsModalOpen }) };
  handleCallModalOpen = () => { this.setState({ callModalOpen: !this.state.callModalOpen }) };
  handleKvkkModalOpen = () => { console.log("test"); this.setState({ kvkkModalOpen: !this.state.kvkkModalOpen }) };

  render() {
    const { getProvincesData, getDistrictsData, getStreetorVillagesData } =
      this.state;

    const sitekey = "6LfyDwQaAAAAADOrx7Qe5cDFRm37kFhBNBel2cw7";

    return (
      <>
        <Col lg="8" md="12">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img src={this.state.allData} style={{ width: 300 }} alt="" />
              </div>
              <Form
                innerRef={this.myCostomerFormRef}
                role="form"
                onSubmit={this.handleSubmit}
              >
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      id="userName"
                      name="userName"
                      placeholder="Ad"
                      type="text"
                      onChange={this.handleChange}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      id="userLastName"
                      name="userLastName"
                      placeholder="Soyad"
                      type="text"
                      onChange={this.handleChange}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <small>Lütfen başında 0 olmadan giriniz.</small>
                <br></br>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      id="userPhone"
                      name="userPhone"
                      placeholder="Telefon Numarası : (530) 000 00 00"
                      type="number"
                      onChange={this.handleChange}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      id="userEmail"
                      name="userEmail"
                      placeholder="Email"
                      type="email"
                      onChange={this.handleChange}
                      required
                    />
                  </InputGroup>
                </FormGroup>
                {/** 
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <GooglePlacesAutocomplete
                      placeholder="Adres"
                      required
                      // İnönü, Harbiye Çayırı Sokak No:44, Şişli/İstanbul, Turkey
                      //onSelect={console.log}
                      onSelect={({ place_id }) =>
                        this.parseGoogleAddress(place_id)
                      }
                    />
                  </InputGroup>
                </FormGroup>

                */}

                <AddressSelection updateAddress={this.handleUpdateAdress} />
                {this.state.allAddressData.street ? (
                  <>
                    <Row className="my-4">
                      <Col xs="12">

                        {
                          (() => {
                            if (this.state.pageUrlLocation === "evo") {
                              return (
                                <>
                                  <div className="custom-control custom-control-alternative custom-checkbox">
                                    <input
                                      className="custom-control-input"
                                      id="customCheckRegister"
                                      type="checkbox"
                                      required
                                    />
                                    <label>
                                      <span>
                                        6698 sayılı Kişisel Verilen Korunması (KVK)
                                        Aydınlatma metninin okudum ve
                                        onaylıyorum.Sözleşmeye ulaşmak için KVK
                                        aydınlatma metni için
                                        <a
                                          target="_blank"
                                          href="https://www.evofone.com/gizlilik-ve-guvenlik"
                                        >
                                          {" "}
                                          tıklayınız.
                                        </a>
                                      </span>
                                    </label>
                                  </div>

                                </>
                              )
                            }
                            else if (this.state.pageUrlLocation == "next") {
                              return (
                                <>
                                  <Modal isOpen={this.state.kvkkModalOpen} toggle={this.handleKvkkModalOpen}>
                                    <ModalHeader>E-Posta ile Pazarlama Rıza Metni</ModalHeader>
                                    <ModalBody>
                                      Bu e-postada bulunan tum bilgiler ve ekler gizlidir ve yalnizca orijinal aliciya yoneliktir.
                                      Bu mesajin herhangi bir bolumunu herhangi bir ucuncu sahislarla paylasmamalisiniz.
                                      Bu mesaji yanlislikla aldiysaniz, lutfen hemen bize bildirin, boylece boyle bir hatanin tekrar gerceklesmemesini saglayabilir ve bu mesaji sisteminizden silebiliriz.
                                      Musterilerimizin guvenligi ve gizliligi onceligimizdir.
                                      Bu e-postanin icerigini goruntuleyerek meydana gelebilecek herhangi bir zarar icin sorumluluk kabul edilmediginden,
                                      her turlu e-postayi her zaman uygun bir yazilima sahip tehditler icin taramaniz gerekir.
                                      Verilerinizin guvenligi bizim en buyuk sorumluluklarimizdan biridir.
                                      Kisisel bilgilerinize erismek veya degistirmek isterseniz veya veri tabanimizdan tamamen kaldirmak istiyorsaniz bize ulasin (kvkk@next.com.tr).
                                      <br />
                                      <br />
                                      <br />
                                      <br />
                                      All information and attachments contained in this email are confidential and intended for the original recipient only.
                                      You should not share any part of this message with any third parties.
                                      If you have received this message by mistake, please report it to us immediately so we can ensure that such error does not occur again and we can delete it from your system.
                                      The safety and confidentiality of our customers is our priority.
                                      You should always scan any e-mail for threats with the appropriate software, as no liability is accepted for any damage incurred by viewing the content of this e-mail.
                                      The security of your data is one of our biggest responsibilities.
                                      Contact us (kvkk@next.com.tr) if you want to access or change your personal information or if you want to remove it from our database entirely.
                                      <br />
                                      <br />
                                      <br />
                                      A: Battalgazi Mh. Basra Cd. No:10-14 34935 Sultanbeyli / Istanbul, T:444 3 666
                                    </ModalBody>
                                  </Modal>
                                  <a href="#!" onClick={() => { this.handleKvkkModalOpen() }}>KVKK Metni</a>
                                </>
                              )
                            } else if (this.state.pageUrlLocation === "ttt") {
                              return (<>
                                <span style={{ display: "block" }}>
                                  <Modal isOpen={this.state.emailModalOpen} toggle={this.handleEmailModalOpen}>
                                    <ModalHeader>E-Posta ile Pazarlama Rıza Metni</ModalHeader>
                                    <ModalBody>
                                      Burada beyan ettiğim ve ilerleyen dönemlerde TT Teknoloji Ürünleri A.Ş. (“Şirket”) ile
                                      paylaşacağım iletişim bilgilerime, Şirket tarafından tanıtım, reklam, kampanya, teklif,
                                      promosyon, duyuru, kutlama, anket, çekiliş, yarışma, e-bülten gönderimi ve benzeri pazarlama
                                      faaliyetleri kapsamında arama vasıtasıyla ticari elektronik ileti gönderilmesine ve söz konusu
                                      süreçler çerçevesinde kimlik, iletişim, müşteri işlem ve pazarlama veri kategorileri ile sınırlı
                                      kişisel verilerimin, firma / ürün / hizmetlere bağlılık süreçlerinin yürütülmesi, iletişim
                                      faaliyetlerinin yürütülmesi, müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,
                                      organizasyon ve etkinlik yönetimi, reklam / kampanya / promosyon süreçlerinin yürütülmesi ve
                                      ürün / hizmetlerin pazarlama süreçlerinin yürütülmesi amaçlarıyla işlenmesine ve yurt içinde
                                      ve/veya yurt dışında yerleşik Şirket’in hizmet temin ettiği tedarikçilere, topluluk şirketlerine ve iş
                                      ortaklarına aktarılmasına onay veriyorum.
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="primary" onClick={this.handleEmailModalOpen}>
                                        Kabul Et
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                  <input onChange={(e) => this.state.form.emailAccepted = (e.target.checked ? 1 : 0)} type="checkbox" id="acceptEmail" name="acceptEmail" style={{ marginRight: 5 }} /><a href="#!" onClick={() => { this.handleEmailModalOpen() }}>E-Posta ile pazarlama kabul ediyorum</a>
                                </span>
                                <span style={{ display: "block" }}>
                                  <Modal isOpen={this.state.smsModalOpen} toggle={this.handleSmsModalOpen}>
                                    <ModalHeader>SMS ile Pazarlama Rıza Metni</ModalHeader>
                                    <ModalBody>
                                      Burada beyan ettiğim ve ilerleyen dönemlerde TT Teknoloji Ürünleri A.Ş. (“Şirket”) ile
                                      paylaşacağım iletişim bilgilerime, Şirket tarafından tanıtım, reklam, kampanya, teklif,
                                      promosyon, duyuru, kutlama, anket, çekiliş, yarışma, e-bülten gönderimi ve benzeri pazarlama
                                      faaliyetleri kapsamında arama vasıtasıyla ticari elektronik ileti gönderilmesine ve söz konusu
                                      süreçler çerçevesinde kimlik, iletişim, müşteri işlem ve pazarlama veri kategorileri ile sınırlı
                                      kişisel verilerimin, firma / ürün / hizmetlere bağlılık süreçlerinin yürütülmesi, iletişim
                                      faaliyetlerinin yürütülmesi, müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,
                                      organizasyon ve etkinlik yönetimi, reklam / kampanya / promosyon süreçlerinin yürütülmesi ve
                                      ürün / hizmetlerin pazarlama süreçlerinin yürütülmesi amaçlarıyla işlenmesine ve yurt içinde
                                      ve/veya yurt dışında yerleşik Şirket’in hizmet temin ettiği tedarikçilere, topluluk şirketlerine ve iş
                                      ortaklarına aktarılmasına onay veriyorum.
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="primary" onClick={this.handleSmsModalOpen}>
                                        Kabul Et
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                  <input onChange={(e) => this.state.form.smsAccepted = (e.target.checked ? 1 : 0)} type="checkbox" id="acceptSMS" name="acceptSMS" style={{ marginRight: 5 }} /><a href="#!" onClick={() => { this.handleSmsModalOpen() }}>Sms Reklamlarını kabul ediyorum</a>
                                </span>
                                <span style={{ display: "block" }}>
                                  <Modal isOpen={this.state.callModalOpen} toggle={this.handleCallModalOpen}>
                                    <ModalHeader>Arama ile Pazarlama Rıza Metni</ModalHeader>
                                    <ModalBody>
                                      Burada beyan ettiğim ve ilerleyen dönemlerde TT Teknoloji Ürünleri A.Ş. (“Şirket”) ile
                                      paylaşacağım iletişim bilgilerime, Şirket tarafından tanıtım, reklam, kampanya, teklif,
                                      promosyon, duyuru, kutlama, anket, çekiliş, yarışma, e-bülten gönderimi ve benzeri pazarlama
                                      faaliyetleri kapsamında arama vasıtasıyla ticari elektronik ileti gönderilmesine ve söz konusu
                                      süreçler çerçevesinde kimlik, iletişim, müşteri işlem ve pazarlama veri kategorileri ile sınırlı
                                      kişisel verilerimin, firma / ürün / hizmetlere bağlılık süreçlerinin yürütülmesi, iletişim
                                      faaliyetlerinin yürütülmesi, müşteri memnuniyetine yönelik aktivitelerin yürütülmesi,
                                      organizasyon ve etkinlik yönetimi, reklam / kampanya / promosyon süreçlerinin yürütülmesi ve
                                      ürün / hizmetlerin pazarlama süreçlerinin yürütülmesi amaçlarıyla işlenmesine ve yurt içinde
                                      ve/veya yurt dışında yerleşik Şirket’in hizmet temin ettiği tedarikçilere, topluluk şirketlerine ve iş
                                      ortaklarına aktarılmasına onay veriyorum.
                                    </ModalBody>
                                    <ModalFooter>
                                      <Button color="primary" onClick={this.handleCallModalOpen}>
                                        Kabul Et
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                  <input onChange={(e) => this.state.form.callAccepted = (e.target.checked ? 1 : 0)} type="checkbox" id="acceptCall" name="acceptCall" style={{ marginRight: 5 }} /><a href="#!" onClick={() => { this.handleCallModalOpen() }}>Pazarlama çağrılarını kabul ediyorum</a>
                                </span>
                                <span style={{ display: "block" }}>
                                  <Modal isOpen={this.state.kvkkModalOpen} toggle={this.handleKvkkModalOpen}>
                                    <ModalHeader>Arama ile Pazarlama Rıza Metni</ModalHeader>
                                    <ModalBody>
                                      Yapacağınız görüşmeler güvenliğiniz ve kalite standartları gereği kayıt altına alınacaktır. Size daha iyi hizmet verebilmek için müşteri hizmetleri hattı aracılığı ile bazı kişisel verileniz işlenecektir.
                                      <br />
                                      <br />
                                      <br />
                                      Tarafımıza ait ad, soyad ,telefon numarası, adres ve görüşme sırasında belirteceğiniz diğer kişisel veriler size doğru hitap edilebilmesi ,hizmet aldığınız ürüne ilişkin bilgi verilebilmesi, sorularınızın  yanıtlanabilmesi, ve satış             sonrası destek hizmetlerin yürütülmesi amacı ile sınırlı olarak işlemektedir. Bu kişisel veriler kanunun 5.maddesinde belirtilen ilgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla veri sorumlusunun meşru menfaat veya sözleşmeye ifası için zorunlu olması hukuki sebebine bağlı olarak telefon yolu yoluyla otomatik işlenmektedir.
                                      <br />
                                      <br />
                                      <br />
                                      Hukuki uyuşmazlıklarda talep edilen durumlarda ilgili yardım merciinin talebiniz değerlendirilmesi ve işleme alınması kapsamında yurtiçi ve yurtdışında iş ilişkisi içerisinde olan  grup şirketlerimiz, iş ortaklarımız ve    tedarikçilerimiz ile paylaşılabilecektir. Kanunun ilgili kişinin haklarını düzenleyen 11.maddesi kapsamındaki taleplerinizi veri sorumlusuna başvuru usul ve hesapları hakkında tebliğine göre, kişisel verileri koruma kurumuna iletebilirsiniz. Daha detaylı bilgi için TT teknoloji teknik servis hizmetleri kişisel verileri işlenmesi aydınlatma metnini <a href="https://www.ttteknoloji.com.tr">www.ttteknoloji.com.tr</a>  hakkımızda web sitemizde bulabilirsiniz.
                                    </ModalBody>

                                    <ModalFooter>
                                      <Button color="primary" onClick={this.handleKvkkModalOpen}>
                                        Kabul Et
                                      </Button>
                                    </ModalFooter>
                                  </Modal>
                                  <a href="#!" onClick={() => { this.handleKvkkModalOpen() }}>KVKK Metni</a>
                                </span>
                              </>
                              )
                            } else {
                              return (
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckRegister"
                                >
                                  <span className="text-muted">
                                    <a
                                      href="#pablo"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Gizlilik Politikası
                                    </a>
                                    nı kabul ediyorum.
                                  </span>
                                </label>
                              )
                            }
                          })()
                        }
                      </Col>
                    </Row>

                    <div
                      className="text-center"
                      style={{ justifyItems: "center" }}
                    >
                      <div style={{}} className="text-center">
                        <CagCaptcha resultFunc={this.handleReCAPTCHA} />
                      </div>
                      {this.state.captchaValid && (
                        <Button
                          className="mt-4"
                          color="primary"
                          type="submit"
                          disabled={this.state.isSubmitForm}
                        >
                          Kayıt Ol
                        </Button>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center text-muted mb-1">
                      Lütfen adres bilgilerinizi tam girin
                    </div>
                  </>
                )}
              </Form>
            </CardBody>
          </Card>
        </Col >
      </>
    );
  }
}

export default Register;
