import React, { useState, useEffect } from 'react';
import Header from '../../components/Headers/Header';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import translator from '../../actions/translator';
import CreateNewDemands from '../../components/CreateNewDemands';
import { apiActions } from '../../apiCall/api.services';
import { logoutUser } from '../../actions/authActions';

import {
    Button,
    Card,
    Container,
    Row,
  } from 'reactstrap';


const DemandsManagement = (props) => {
  const [newDemandVisibility, setNewDemandVisibility] = useState(false);
  const [tableData, setTableData] = useState([]);
  const userAuth = JSON.parse(localStorage.getItem('userAuth'));


  useEffect(() => {
    console.log("tableData magament",tableData);
  }, [tableData])

  const columns = [
    {
      name: 'DemandID',
      label: translator.productID,
      field: 'DemandID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'UserFullName',
      label: translator.demandant,
      field: 'UserFullName',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'FirmName',
      label: translator.demandedStore,
      field: 'FirmName',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'CostCenter',
      label: translator.costCenter,
      field: 'CostCenter',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Process',
      label: translator.process,
      field: 'Process',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'CreatedTime',
      label: translator.createdDate,  
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'DemandID',
      field: 'DemandID',
      label: translator.supportDetail,
      options: {
        display: true,
        textLabels: 'DemandID',
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button
            className="tableButtonStyle"
            color="primary"
            value={value}
            type="button"
          >
            {translator.detail}
          </Button>
        ),
      },
    },
  ];


  const options = {
    textLabels: {
      body: {
        noMatch: `${translator.noMatchInTable}`
      }
    },
    filterType: 'dropdown',
    onCellClick: (cellIndex) => {
      if (cellIndex.props.value !== undefined) {
        getDemandsDetail(cellIndex.props.value);
      }
    },
  };

  const getDemandsDetail = (getDemandsID) => {
    localStorage.setItem('DemandsDetail', JSON.stringify(getDemandsID));
    props.history.push('/admin/demands-detail');
  }

  useEffect(() => {
    getListData();
  },[])

  useEffect(() => {
    handleRefreshAfterAddDemand();
  },[newDemandVisibility])

  const getListData = () => {

    apiActions.getPortalDemands(userAuth.access_token)
    .then((data) => setTableData(data))
    .catch(error=>{

      console.log("error",error.response.status);
      error.response.status == 401 && logoutUser();
            })

  }


  const handleRefreshAfterAddDemand = () => {

!newDemandVisibility && getListData();


  }

  
  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
      {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              {
              newDemandVisibility
              ? <div>
                  <CreateNewDemands setNewDemandVisibility={setNewDemandVisibility} />
                </div>
              : <div>
                  <div style={containerRight}>
                    <Button onClick={() => setNewDemandVisibility(true)} outline color="success">Talep Oluştur</Button>
                  </div>
                  <MuiThemeProvider theme={getMuiTheme()} >
                    <MUIDataTable
                      title={translator.demands}
                      data={tableData}
                      columns={columns}
                      options={options}
                    />
                  </MuiThemeProvider>
                </div>
              }
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const containerRight = {
  backgroundColor: 'white',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '10px',
}

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        textAlign: 'center',
        padding: '5px',
        fontSize: '1.1vw'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        textAlign: 'center',
        padding: '20px 5px',
        fontSize: '1vw'
      }
    }
  }
})


export default DemandsManagement;