import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import './assets/css/index.css';
import { Provider } from 'react-redux';
//import configureStore from "_store";

import store from './store';

import './assets/vendor/nucleo/css/nucleo.css';
import './assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import './assets/scss/argon-dashboard-react.scss';
import './assets/customStyle.css';

import AdminLayout from './layouts/Admin.jsx';
import AuthLayout from './layouts/Auth.jsx';
/*
if (
  window.location.protocol !== 'https:' &&
  window.location.hostname !== 'localhost' &&
  window.location.hostname !== 'portal2.servissoft.net'
) {
  window.location =
    'https:' + window.location.href.substring(window.location.protocol.length);
}
*/
export const history = createBrowserHistory({});

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route path="/admin" render={(props) => <AdminLayout {...props} />} />
        <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
        <Redirect from="/" to="/admin/index" />
      </Switch>
    </Router>
  </Provider>,
  document.getElementById('root'),
);
