import React from 'react';
// reactstrap components
import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Form,
  Input,
  Col,
} from 'reactstrap';

// core components
import Header from '../../components/Headers/Header.jsx';
import { apiActions } from '../../apiCall/api.services';

import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { If, Then, ElseIf, Else } from 'react-if-elseif-else-render';
import Moment from 'react-moment';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import userRole from '../../actions/userRole';
import oktetApiCall from '../../apiCall/oktetApiCall';
import translator from '../../actions/translator';
import { Digital } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import Modal from 'react-modal';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    backgroundColor: 'transparent',
    zIndex: '130',
  },
};

class SingleClaimDetails extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      testVal: '',
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      getActiveOrdersOptions: [],
      getOrdersDetailsOptions: [],
      detectedFaults: [],
      serviceLabourName: [],
      serviceOtherExpenses: [],
      serviceSparePieceExpenses: [],
      serviceTransportationExpenses: [],
      serviceFiles: [],
      ticketNote: '',
      canOpenOrder: '',
      loadingAnimate: true,
      modalIsOpen: true,
    };

    this.getActiveOrders = this.getActiveOrders.bind(this);
    this.getOrdersDetails = this.getOrdersDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAddNotes = this.getAddNotes.bind(this);
    this.getCustomerSummary = this.getCustomerSummary.bind(this);
  }

  getActiveOrders() {
    const orderID = JSON.parse(localStorage.getItem('singleClaimID'));
    console.log('orderID');
    console.log(orderID);

    const currentComponent = this;
    const getActiveOrdersPromise = apiActions.getOrdersDetails(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      orderID,
    );
    getActiveOrdersPromise.then(function(res) {
      currentComponent.setState({ getActiveOrdersOptions: res.data.data });
    });
  }

  getOrdersDetails() {
    const orderID = JSON.parse(localStorage.getItem('singleClaimID'));
    const currentComponent = this;
    const getOrdersDetailsPromise = apiActions.getSingleOrderForPortal(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      orderID,
    );
    getOrdersDetailsPromise.then(function(res) {
      console.log('promise geçer');
      if (res.data.ServiceRecords.LabourExpenses.length > 0) {
        currentComponent.setState({
          serviceLabourName: res.data.ServiceRecords.LabourExpenses,
        });
      }

      if (res.data.ServiceRecords.OtherExpenses.length > 0) {
        currentComponent.setState({
          serviceOtherExpenses: res.data.ServiceRecords.OtherExpenses,
        });
      }

      if (res.data.ServiceRecords.SparePieceExpenses.length > 0) {
        currentComponent.setState({
          serviceSparePieceExpenses: res.data.ServiceRecords.SparePieceExpenses,
        });
      }

      if (res.data.ServiceRecords.TransportationExpenses.length > 0) {
        currentComponent.setState({
          serviceTransportationExpenses:
            res.data.ServiceRecords.TransportationExpenses,
        });
      }
      if (res.data.DetectedFaults.length > 0) {
        currentComponent.setState({ detectedFaults: res.data.DetectedFaults });
      }
      if (res.data.Files.length > 0) {
        currentComponent.setState({ serviceFiles: res.data.Files });
      }
      currentComponent.setState({ getOrdersDetailsOptions: res.data });
      currentComponent.setState({ loadingAnimate: false });
      currentComponent.setState({ modalIsOpen: false });
    });
  }

  getAddNotes(noteText) {
    const orderID = JSON.parse(localStorage.getItem('singleClaimID'));
    const getAddNotesPromise = apiActions.getAddNotes(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      orderID,
      noteText,
    );
    getAddNotesPromise.then(function(res) {
      const options = {
        title: 'Title',
        message: 'Message',
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      };
      confirmAlert(options);
      confirmAlert({
        title: translator.congratulations,
        message: translator.yourMessageHasBeenSentSuccessfully,
        buttons: [
          {
            label: 'Ok',
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });
    });
  }

  componentDidMount() {
    this.getActiveOrders();
    this.getOrdersDetails();
    this.getCustomerSummary();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { ticketNote } = this.state;
    if (ticketNote) {
      this.getAddNotes(ticketNote);
    }
  }

  getCustomerSummary() {
    let currentComponent = this;
    let getCustomerSummaryPromise = apiActions.getCustomerSummary(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
    );
    getCustomerSummaryPromise.then(function(res) {
      currentComponent.setState({
        canOpenOrder: res.data.CanOpenOrder,
      });
    });
  }

  // getOrdersDetailsOptions.TotalCost - Ücret
  // getOrdersDetailsOptions.ReferenceID - order ID

  render() {
    const {
      getActiveOrdersOptions,
      getOrdersDetailsOptions,
      ticketNote,
      detectedFaults,
      serviceLabourName,
      serviceOtherExpenses,
      serviceSparePieceExpenses,
      serviceTransportationExpenses,
      serviceFiles,
    } = this.state;

    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          //onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Digital
            animating={this.state.loadingAnimate}
            color="#52BFE8"
            size={64}
            speed={1}
          />
        </Modal>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    {translator.referenceID}:{' '}
                    {getOrdersDetailsOptions.ReferenceID}
                  </h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.status}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.detectedFault}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.insertDate}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.deviceInfo}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{getOrdersDetailsOptions.StateText}</td>
                      <td>
                        {detectedFaults.map(row => ' - ' + row.FaultName)}
                      </td>
                      <td>
                        <Moment format="HH:mm DD/MM/YYYY">
                          {getOrdersDetailsOptions.InsertDate}
                        </Moment>
                      </td>
                      <td>
                        {getOrdersDetailsOptions.BrandName}{' '}
                        {getOrdersDetailsOptions.DeviceTypeName}{' '}
                        {getOrdersDetailsOptions.DeviceName}{' '}
                        {getOrdersDetailsOptions.SerialNo}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.complaint}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.descriptionOfTheTransaction}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.transactions}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.totalPrice}
                      </th>
                      <th scope="col" style={{ width: '25%' }}>
                        {translator.paymentStatus}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{getOrdersDetailsOptions.Description}</td>
                      <td>{getOrdersDetailsOptions.CurrentFullFilled}</td>
                      <td>
                        {serviceLabourName.map(
                          row =>
                            row.LabourName +
                            ' - ' +
                            row.Cost +
                            ' - ' +
                            row.Currency,
                        )}
                        <br />
                        {serviceOtherExpenses.map(
                          row =>
                            row.Description +
                            ' - ( ' +
                            row.CurrencyCost +
                            ' Adet ) - ' +
                            row.Cost +
                            ' - ' +
                            row.Currency,
                        )}
                        <br />
                        {serviceSparePieceExpenses.map(
                          row =>
                            row.ItemName +
                            ' - ( ' +
                            row.ActionCurrencyCost +
                            ' Adet ) - ' +
                            row.Cost +
                            ' - ' +
                            row.ActionCurrency,
                        )}
                        <br />
                        {serviceTransportationExpenses.map(row => (
                          <If condition={row.TransportationKind === 1}>
                            <Then>
                              {translator.onDirection} {row.Distance} KM -{' '}
                              {row.Cost}
                            </Then>
                            <Else>
                              {translator.doubleDirection} {row.Distance} KM -{' '}
                              {row.Cost}
                            </Else>
                          </If>
                        ))}
                      </td>
                      <td>{getOrdersDetailsOptions.TotalCost}</td>
                      <td>
                        <button className="btn btn-primary">
                          {translator.pay}
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <br />
          <br />
          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">{translator.files}</h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{translator.fileName}</th>
                      <th scope="col" />
                    </tr>
                  </thead>
                  <tbody>
                    {serviceFiles.map(row => (
                      <tr>
                        <th scope="row">
                          <Media className="align-items-center">
                            <a
                              className="avatar rounded-circle mr-3"
                              href={row.FileLocation}
                              onClick={e => e.preventDefault()}
                            >
                              <ReactFancyBox
                                thumbnail={row.FileLocation}
                                image={row.FileLocation}
                              />
                            </a>
                            <Media>
                              <span className="mb-0 text-sm">
                                {row.FileName}
                              </span>
                            </Media>
                          </Media>
                        </th>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </div>
            <If condition={this.state.canOpenOrder === '1'}>
              <Then>
                <div className="col">
                  <Card className="shadow">
                    <CardHeader className="border-0">
                      <h3 className="mb-0">{translator.ticket}</h3>
                    </CardHeader>
                    <Form role="form" onSubmit={this.handleSubmit}>
                      <Col lg="12">
                        <Input
                          type="textarea"
                          rows="5"
                          id="ticketNote"
                          name="ticketNote"
                          className="form-control-alternative"
                          placeholder="Ticket Notunuzu Giriniz"
                          value={ticketNote}
                          onChange={this.handleChange}
                        />
                      </Col>
                      <br />
                      <Col lg="6">
                        <button className="btn btn-primary">
                          {translator.send}
                        </button>
                      </Col>

                      <br />
                    </Form>
                  </Card>
                </div>
              </Then>
            </If>
          </Row>
          <If condition={userRole().detailPageConversation !== false}>
            <Then>
              <Row>
                <div className="col">
                  <VerticalTimeline>
                    {getActiveOrdersOptions.map(row => (
                      <If condition={row.SentBy === 'Customer'}>
                        <Then>
                          <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date={
                              <Moment format="HH:mm DD/MM/YYYY">
                                {row.InsertDate}
                              </Moment>
                            }
                            position="right"
                            iconStyle={{
                              background: 'rgb(33, 150, 243)',
                              color: '#fff',
                            }}
                          >
                            <h3 className="vertical-timeline-element-title">
                              {row.CustomerName}
                            </h3>
                            <p>{row.Text}</p>
                          </VerticalTimelineElement>
                        </Then>
                        <ElseIf condition={row.SentBy === 'Staff'}>
                          <VerticalTimelineElement
                            className="vertical-timeline-element--work"
                            date={
                              <Moment format="HH:mm DD/MM/YYYY">
                                {row.InsertDate}
                              </Moment>
                            }
                            position="left"
                            iconStyle={{
                              background: 'rgb(33, 150, 243)',
                              color: '#fff',
                            }}
                          >
                            <h3 className="vertical-timeline-element-title">
                              {row.StaffName}
                            </h3>
                            <p>{row.Text}</p>
                          </VerticalTimelineElement>
                        </ElseIf>
                      </If>
                    ))}
                  </VerticalTimeline>
                </div>
              </Row>
            </Then>
          </If>
        </Container>
      </>
    );
  }
}

export default SingleClaimDetails;
