import React from 'react';
import { apiActions } from '../../apiCall/api.services';
import { withRouter } from 'react-router-dom';
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import translator from '../../actions/translator';

class HeaderWithoutCards extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      openOrderCount: '',
      fullName: '',
      firmName: '',
      closedOrderCount: '',
    };

    this.getCustomerSummary = this.getCustomerSummary.bind(this);
    this.getActiveOrders = this.getActiveOrders.bind(this);
    this.getPastOrders = this.getPastOrders.bind(this);
  }

  getCustomerSummary() {
    let currentComponent = this;
    let getCustomerSummaryPromise = apiActions.getCustomerSummary(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
    );
    getCustomerSummaryPromise.then(function(res) {
      currentComponent.setState({
        openOrderCount: res.data.OpenOrderCount,
        closedOrderCount: res.data.ClosedOrderCount,
        fullName: res.data.FullName,
      });
    });
  }

  componentDidMount() {
    this.getCustomerSummary();
  }

  getActiveOrders() {
    this.props.history.push('/admin/active-orders')
  }

  getPastOrders() {
    this.props.history.push('/admin/past-orders');
  }

  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
             
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(HeaderWithoutCards);
