import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Form,
  Navbar,
  Nav,
  Container,
  Media,
} from 'reactstrap';
import { apiActions } from '../../apiCall/api.services';
import { logoutUser } from '../../actions/authActions';
import translator from '../../actions/translator';

import flagTr from '../../assets/img/turkish_flag.png';
import flagEng from '../../assets/img/english_flag.png';

import indexForce from '../../index.js';

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    const langStatus = localStorage.getItem('userLang');

    let langCheck = langStatus;
    let langName = '';
    let langFlag = flagEng;

    if (langStatus === '1') {
      langCheck = langCheck;
      langName = 'English';
      langFlag = flagEng;
    } else if (langStatus === '0') {
      langCheck = langCheck;
      langName = 'Türkçe';
      langFlag = flagTr;
    }

    this.state = {
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      fullName: '',
      firmName: '',
      canOpenOrder: '',
      langCheck: langCheck,
      langName: langName,
      langFlag: langFlag,
    };

    this.getCustomerSummary = this.getCustomerSummary.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleLangClick = this.handleLangClick.bind(this);
  }

  getCustomerSummary() {
    const currentComponent = this;
    const getCustomerSummaryPromise = apiActions.getCustomerSummary(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
    );
    getCustomerSummaryPromise.then(function(res) {
      currentComponent.setState({
        fullName: res.data.FullName,
       
        canOpenOrder: res.data.canOpenOrder,
      });

      if (res.data.FirmName !== null) {
        currentComponent.setState({
          firmName: res.data.FirmName + ' - ',
        });
      }

    });
  }

  componentDidMount() {
    this.getCustomerSummary();
  }

  handleClick() {
    logoutUser();
  }

  handleLangClick() {
    if (localStorage.getItem('userLang') === '0') {
      localStorage.setItem('userLang', 1);
    } else if (localStorage.getItem('userLang') === '1') {
      localStorage.setItem('userLang', 0);
    }

    window.location.reload();
  }

  render() {
    return (
      <>
        <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
          <Container fluid>
            <Form className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"></Form>
            <Nav className="align-items-center d-none d-md-flex" navbar>
              <Button
                className="btn-3"
                color="primary"
                type="button"
                onClick={this.handleLangClick}
              >
                <img
                  src={this.state.langFlag}
                  style={{
                    width: '30px',
                  }}
                />

                <span className="btn-inner--text">{this.state.langName}</span>
              </Button>

              <UncontrolledDropdown nav>
                <DropdownToggle className="pr-0" nav>
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt="..."
                        src={require('../../assets/img/profile_portal.png').default}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                      {this.state.firmName} {this.state.fullName}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-arrow" right>
                  <DropdownItem to="/admin/user-profile" tag={Link}>
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={this.handleClick}>
                    <i className="ni ni-user-run" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Container>
        </Navbar>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps)(AdminNavbar);
