import { Button, Card, Container, Row } from "reactstrap";
import React, { useEffect, useState } from "react";
import Header from "../../components/Headers/Header";
import { Box, createMuiTheme, MuiThemeProvider } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import axios from "axios";

const Bills = (props) => {
  const [tableData, setTableData] = useState([]);
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));
  const getBills = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAuth.access_token,
      },
    };
    return axios.get(
      "https://api.servissoft.net/tchiboinvoicetrack/list",
      config
    );
  };

  useEffect(() => {
    getBills().then((res) => {
      setTableData(res.data);
    });
  }, []);

  const goCreatePage = () => {
    props.history.push("/admin/enter-bill");
  };
  const goDetailPage = (id) => {
    props.history.push("/admin/bills-detail/" + id);
    localStorage.setItem("billId", id);
  };

  const options = {
    filterType: "dropdown",
    downloadOptions: {
      filename: "excel-format.csv",
      separator: ";",
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
  };

  const monthData = [
    { value: 1, label: "Ocak" },
    { value: 2, label: "Şubat" },
    { value: 3, label: "Mart" },
    { value: 4, label: "Nisan" },
    { value: 5, label: "Mayıs" },
    { value: 6, label: "Haziran" },
    { value: 7, label: "Temmuz" },
    { value: 8, label: "Ağustos" },
    { value: 9, label: "Eylül" },
    { value: 10, label: "Ekim" },
    { value: 11, label: "Kasım" },
    { value: 12, label: "Aralık" },
  ];

  const columns = [
    {
      name: "Mont",
      field: "Mont",
      label: "Ay",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          const month = monthData.find((month) => month.value === value);
          return <span>{month?.label}</span>;
        },
      },
    },
    {
      name: "Year",
      field: "Year",
      label: "Year",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <span>{value ? value : ""}</span>;
        },
      },
    },
    {
      name: "DaylightConsumption",
      field: "DaylightConsumption",
      label: "Gündüz Tüketim",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "EveningConsumption",
      field: "EveningConsumption",
      label: "Akşam Tüketim",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "NightConsumption",
      field: "NightConsumption",
      label: "Gece Tüketim",
      options: {
        filter: true,
        sort: true,
      },
    },
    //Passed time kaldırıldı.ColumnIndex kontrolleri için boş bir column bırakıldı
    {
      name: "Total",
      field: "Total",
      label: "Toplam",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "TotalCost",
      field: "TotalCost",
      label: "Tutar",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return <span>{value ? value : ""}</span>;
        },
      },
    },
    {
      name: "Id",
      field: "Id",
      label: "Detay",
      options: {
        download: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <Button
            style={{
              padding: "7px 12px",
              color: "#9BA6B2",
              fontSize: "1.188rem",
              fontWeight: "500",
              background: "#FFFFFF",
              border: "1px solid #9BA6B2",
              borderRadius: "5px",
              cursor: "pointer",
            }}
            onClick={() => {
              goDetailPage(value);
            }}
          >
            Detaya Git
          </Button>
        ),
      },
    },
  ];

  const data = [
    {
      month: "Ocak",
      year: "2020",
      daytimeConsumption: "100",
      eveningConsumption: "200",
      nightConsumption: "300",
      total: "500",
      amount: "1",
      id: "1",
    },
  ];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <Box
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "16px",
                }}
              >
                <Button
                  style={{
                    padding: "10px 30px",
                    color: "#2AD587",
                    fontSize: "1.188rem",
                    fontWeight: "500",
                    background: "#FFFFFF",
                    border: "1px solid #2AD587",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                  onClick={goCreatePage}
                >
                  Fatura Gir
                </Button>
              </Box>
              <MuiThemeProvider theme={getMuiTheme()}>
                <MUIDataTable
                  data={tableData}
                  columns={columns}
                  options={options}
                />
              </MuiThemeProvider>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          textAlign: "center",
          padding: "5px 35px",
          fontSize: "1.1vw",
        },
      },
      MUIDataTableBodyCell: {
        root: {
          textAlign: "center",
          padding: "20px 5px",
          fontSize: "1vw",
        },
      },
    },
  });
export default Bills;
