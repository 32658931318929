import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { apiActions } from "../../apiCall/api.services";
import { loginUser } from "../../actions/authActions";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import FacebookIcon from "@material-ui/icons/Facebook";
import YouTubeIcon from "@material-ui/icons/YouTube";
import ClientCaptcha from 'react-client-captcha'
import { confirmAlert } from 'react-confirm-alert';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

class Login extends React.Component {
  constructor() {
    super();
    this.state = {
      username: "",
      password: "",
      allData: "",
      domain: "",
      captchaCode: "",
      catpchaCodeSubmitted: ""
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }



  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmit(event) {
    event.preventDefault();

    if (this.state.captchaCode != this.state.catpchaCodeSubmitted) {
      const options = {
        title: 'Title',
        message: 'Message',
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => { },
        onKeypressEscape: () => { },
      };
      confirmAlert(options);
      confirmAlert({
        title: 'Hata',
        message: 'Güvenlik kodu doğru girilmedi.',
        buttons: [
          {
            label: 'Ok',
          },
        ],
      });
      console.log("sybmitte", this.state.catpchaCodeSubmitted);
      console.log("code", this.state.captchaCode);
      return;
    }

    const userData = {
      username: this.state.username,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  }

  getData() {
    let currentComponent = this;
    let promise2 = apiActions.onLoadApi();
    promise2.then(function (val) {
      console.log(val);
      currentComponent.setState({ allData: val.data[0] });
    });
  }

  handleCaptcaClick(code) {
    this.state.catpchaCodeSubmitted = code;
  }
  componentDidMount() {
    this.getData();

    const urlLocation = window.location.hostname;
    console.log("urlLocation", urlLocation);
    this.setState({ domain: urlLocation });
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img src={this.state.allData} alt="" width="200" />
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Kullanıcı Adı"
                      id="username"
                      name="username"
                      type="text"
                      required
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Şifre"
                      id="password"
                      name="password"
                      type="password"
                      required
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                {/* <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Beni Hatırla</span>
                  </label>
                </div> */}
                <div className="text-center">
                  <ClientCaptcha width={310} charsCount={6} captchaCode={(code) => { this.handleCaptcaClick(code) }} />
                  Lütfen üstte yer alan güvenlik kodunu giriniz.
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-world-2" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Güvenlik Kodu"
                      id="captchaCode"
                      name="captchaCode"
                      type="captchaCode"
                      required
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Giriş
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>

          {this.state.domain === "tchibodestek.servissoft.net" && (
            <>
              <Row className="mt-3">
                <Col xs="6">
                  <a className="text-light" href="/auth/forgot-password">
                    <small>Şifremi Unuttum</small>
                  </a>
                </Col>
              </Row>
            </>
          )
          }

          {this.state.domain != "tchibodestek.servissoft.net" && (
            <>

              <Row className="mt-3">
                <Col xs="6">
                  <a className="text-light" href="/auth/forgot-password">
                    Şifremi Unuttum
                  </a>
                </Col>
                <Col className="text-right" xs="6">
                  <a className="text-light" href="/auth/register">
                    Yeni Hesap Oluştur
                  </a>
                </Col>
              </Row>

            </>)}
          {this.state.domain === "ferreservis.com" && (
            <>
              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                  >
                    <small>"Yeni Hesap Oluştur" alanından hesap oluşturarak giriş yapabilirsiniz.<br></br>
                      Sms olarak gelen kullanıcı adı ve şifrenizle servis kaydı açabilir ürünlerinize ait yapılan servis işlemlerinizin tamamını takip edebilirsiniz.</small>
                  </a>
                </Col>
              </Row>
            </>)}

          {this.state.domain === "servis.ttteknoloji.com.tr" && (
            <>
              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="tel:08503460822"
                  >
                    0850 346 08 22
                  </a>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="mailto:destek@ttteknoloji.com.tr"
                  >
                    destek@ttteknoloji.com.tr
                  </a>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.ttteknoloji.com.tr"
                  >
                    ttteknoloji.com.tr
                  </a>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://twitter.com/tt_teknoloji"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.facebook.com/TT-Teknoloji-187336168288047/"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://tr.linkedin.com/company/tt-teknoloji"
                  >
                    <LinkedInIcon />
                  </a>

                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.youtube.com/channel/UCeOjXnWliPITy-lyrUTuYhg?view_as=subscriber"
                  >
                    <YouTubeIcon />
                  </a>
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.instagram.com/ttteknolojiofficial/"
                  >
                    <InstagramIcon />
                  </a>
                </Col>
              </Row>
            </>
          )}
          {this.state.domain === "servis.goldmaster.com.tr" && (
            <>
              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="tel:08505325666"
                  >
                    0850 532 56 66
                  </a>
                </Col>
              </Row>



              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="goldmaster.com.tr"
                  >
                    goldmaster.com.tr
                  </a>
                </Col>
              </Row>

              <Row className="mt-3">
                <Col xs="12" className="text-center">
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://twitter.com/goldmasterworld"
                  >
                    <TwitterIcon />
                  </a>
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.facebook.com/Goldmasterworld"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.linkedin.com/company/goldmaster/"
                  >
                    <LinkedInIcon />
                  </a>

                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.youtube.com/goldmasterworld"
                  >
                    <YouTubeIcon />
                  </a>
                  <a
                    style={{ color: "#ffffff", padding: 10 }}
                    href="https://www.instagram.com/goldmasterworld/"
                  >
                    <InstagramIcon />
                  </a>
                </Col>
              </Row>
            </>
          )}
        </Col>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { loginUser })(Login);

/*
import React from "react";
import { connect } from "react-redux";
import loginAction from "actions/loginAction";
import { apiActions } from "../../apiCall/api.services";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col
} from "reactstrap";

class Login extends React.Component {

  state = {
    email: "",
    password: "",
    allData:""
  }

  onChange = (stateName,value) => {
    this.setState({
      [stateName]: value
    })
  }

  
  getData(){
        let currentComponent = this;
        let promise2 = apiActions.onLoadApi();    
        promise2.then(function(val){
            console.log(val);
            currentComponent.setState({allData:val.data[0]});
        });
  }

  componentDidMount() {
         this.getData();
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img src={this.state.allData} alt="" />    
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Email" type="email" onChange={
                      e=>this.onChange(
                        "email",
                        e.target.value
                      )
                    } />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder="Password" type="password" onChange={
                      e=>this.onChange(
                        "password",
                        e.target.value
                      )
                    } />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="button" onClick={
                    ()=>{
                      this.props.loginAction(
                          this.state.email,
                          this.state.password
                      )
                    }
                  }>
                    Sign in
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col xs="6">
              <a
                className="text-light"
                href="/auth/forgot-password"
              >
                <small>Forgot password?</small>
              </a>
            </Col>
            <Col className="text-right" xs="6">
              <a
                className="text-light"
                href="/auth/register"
              >
                <small>Create new account</small>
              </a>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

const mapStateToPropos = (state) => {
  //console.log(state.auth);
  return{
      
  }
}


const mapDispatchToProps = (dispatch) => {
  return {
    loginAction: (email,password) => 
      dispatch(
      loginAction(
        email,password
      ))
  }
}

export default connect(
  mapStateToPropos,
  mapDispatchToProps
)(Login);

*/
