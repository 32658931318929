import react, { useEffect, useState } from "react";

const CagCaptcha = ({ resultFunc }) => {
  const [transactionResult, setTransactionResult] = useState();
  const [result, setResult] = useState();

  const [firstNumber, setFirstNumber] = useState(0);
  const [secondNumber, setSecondNumber] = useState(0);

  useEffect(() => {
    setFirstNumber(Math.floor(Math.random() * 10 + 1));
    setSecondNumber(Math.floor(Math.random() * 10 + 1));
  }, []);

  /*
  const firstNumber = Math.floor(Math.random() * 10);
  const secondNumber = Math.floor(Math.random() * 10);
*/

  const correctResult = firstNumber + secondNumber;
  const handleInputChange = (event) => {
    setTransactionResult(event.target.value);
    if (event.target.value == correctResult) {
      resultFunc(true);
      setResult(true);
    } else {
      resultFunc(false);
      setResult(false);
    }
  };

  return (
    <>
      <div>
        {firstNumber} + {secondNumber} = 
        <input
          type="text"
          value={transactionResult}
          onChange={handleInputChange}
          style={{ width: 30, marginLeft: 10, marginRight: 10 }}
        />
       {transactionResult && (<>
            {result ? (
              <span style={{ fontSize: 25, color: "green" }}>{"✓"}</span>
            ) : (
              <span style={{ fontSize: 20 }}>{"❌"}</span>
            )}
            </>
            )}
      </div>
    </>
  );
};

export default CagCaptcha;
