import React from "react";
import { apiActions } from "../../apiCall/api.services";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col,
} from "reactstrap";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import translator from "../../actions/translator";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem("userAuth"));
    this.state = {
      email: "",
      allData: "",
      portalUrl: window.location.hostname,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const tempVal = event.target.value;
    this.setState({ email: tempVal });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.getResendPassword(this.state.email, this.state.portalUrl);
  }

  getData() {
    const currentComponent = this;
    const promise2 = apiActions.onLoadApi();
    promise2.then(function (val) {
      const answer_array = localStorage.getItem("userGetURL")?.split("/auth");
      currentComponent.setState({
        allData: val && val.data && val.data.length > 0 && val.data[0],
        // userGetURL: answer_array && answer_array.length > 0 && answer_array[0], ////yayında bunun olması gerekiyor
        // //userGetURL:"http://portal.servissoft.net",
      });
    });
  }

  getResendPassword(email, portalUrl) {
    const getCustomerSummaryPromise = apiActions.getResendPassword(
      email,
      portalUrl
    );
    getCustomerSummaryPromise.then();
  }

  componentDidMount() {
    this.getData();
  }

  render() {
    return (
      <>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <img src={this.state.allData} alt="" width={300} />
              </div>
              <div className="text-center text-muted mb-4">
                {translator.forgotPasswordEnterEmail}
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email Adresi"
                      id="email"
                      name="email"
                      type="email"
                      onChange={this.handleChange}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    {translator.sendPassword}
                  </Button>
                  <Button className="my-4" color="primary" href="/auth/login">
                    {translator.backToLogin}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}

export default ForgotPassword;
