import React from "react";
import {
    CardBody,
    Form,
    FormGroup,
    Input
} from "reactstrap"

import Header from "../../components/Headers/Header.jsx";
import translator from '../../actions/translator';
import Select from "react-select";
import { apiActions } from '../../apiCall/api.services';
import { DropzoneArea } from "material-ui-dropzone";
import Swal from "sweetalert2";
import { DatePicker } from 'reactstrap-date-picker'


import { Button, Card, Container, Row, Col, CardHeader } from "reactstrap";

class RegisterProduct extends React.Component {
    constructor(props) {
        super(props);
        let userAuth = JSON.parse(localStorage.getItem("userAuth"));

        this.state = {
            userAuthOptions: {
                accessToken: userAuth.access_token,
                apiKey: userAuth.apiKey,
                clientID: userAuth.clientID,
                roleID: userAuth.roleID,
            },
            brandOptions: [],
            deviceTypeOptions: [],
            modelOptions: [],
            files: [],
            invoiceDate: new Date().toISOString(),
            invoiceDateFormatted: undefined
        };

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
        this.getBrandList();
    }

    getBrandList(noSerialData) {
        const currentComponent = this;
        let arr = [];
        const getBrandListPromise = apiActions.getBrandList(
            this.state.userAuthOptions.accessToken,
            this.state.userAuthOptions.clientID,
            this.state.userAuthOptions.apiKey
        );
        getBrandListPromise.then(function (res) {
            res.data.data.map(function (row, index) {
                arr.push({
                    value: row.DeviceBrandID,
                    label: row.DeviceBrandName,
                });

                // if (index == 0) {
                //     currentComponent.setState({
                //         selectedBrandValue: {
                //             value: row.DeviceBrandID,
                //             label: row.DeviceBrandName,
                //         },
                //     });
                //     // currentComponent.getDeviceTypesByBrand(row.DeviceBrandID);
                // }
            });
            currentComponent.setState({ brandOptions: arr });
        });
    }

    handleBrand = (selectedBrandValue) => {
        this.setState({ selectedBrandValue });
        this.getDeviceTypesByBrand(selectedBrandValue.value)
    };

    getDeviceTypesByBrand(brandID) {
        const currentComponent = this;
        let arr = [];
        const getDeviceTypesByCustomerPromise = apiActions.getDeviceTypesByCustomer(
            this.state.userAuthOptions.accessToken,
            this.state.userAuthOptions.clientID,
            this.state.userAuthOptions.apiKey,
            brandID
        );
        getDeviceTypesByCustomerPromise.then(function (res) {
            console.log("deviceList", res.data);
            res.data.data.map(function (row, index) {
                arr.push({
                    value: row.DeviceTypeID,
                    label: row.DeviceTypeName,
                });

                // if (index == 0) {
                //     currentComponent.setState({
                //         selectedTypeValue: {
                //             value: row.DeviceTypeID,
                //             label: row.DeviceTypeName,
                //         },
                //     });
                //     // currentComponent.getModelListByCustomer(row.DeviceTypeID, "");
                // }
            });

            currentComponent.setState({ deviceTypeOptions: arr });
        });
    }

    handleDeviceType = (selectedTypeValue) => {
        this.setState({ selectedTypeValue });
        this.getModelListByCustomer(selectedTypeValue.value, this.state.selectedBrandValue.value)
    };

    getModelListByCustomer(typeId, brandId) {
        const currentComponent = this;

        let arr = [];
        const getModelListByCustomerPromise = apiActions.getModelListByCustomer(
            currentComponent.state.userAuthOptions.accessToken,
            currentComponent.state.userAuthOptions.clientID,
            currentComponent.state.userAuthOptions.apiKey,
            typeId, brandId
        );
        getModelListByCustomerPromise.then((res) => {
            console.log("res", res.data.data);

            res.data.data.map(function (row, index) {
                arr.push({
                    value: row.DeviceModelID,
                    label: row.DeviceName,
                });

                // if (index == 0) {
                //     currentComponent.setState({
                //         selectedModelValue: {
                //             value: row.DeviceModelID,
                //             label: row.DeviceName,
                //         },
                //     });

                //     //currentComponent.getDevicesByCustomerID(row.DeviceModelID);
                // }
            });
            arr.length === 0 && currentComponent.setState({ selectedModelValue: "" });
            currentComponent.setState({ modelOptions: arr });
            currentComponent.setState({ serialNoOptions: [] });
            currentComponent.setState({ selectedSerialNoValue: "" });
        });
    }

    handleModel = (selectedModelValue) => {
        this.setState({ selectedModelValue });
    };



    handleSerialNoInput = (e) => {
        this.setState({ serialInputValue: e.target.value });
    };


    postRegisterProduct(deviceModelId, serialNo, files, invoiceDate) {

        let postRegisterProductPromise = apiActions.postRegisterProduct(
            this.state.userAuthOptions.accessToken,
            this.state.userAuthOptions.apiKey,
            deviceModelId,
            serialNo,
            files,
            invoiceDate)
            .then((result) => {
                if (result.data > 0) {
                    Swal.fire({
                        title: `İşlem Başarılı!`,
                        icon: "info",
                        html: "Cihaz kaydı tamamlanmıştır.",
                        allowOutsideClick: false,
                        focusConfirm: false,
                        cancelButtonText: "OK",
                        cancelButtonAriaLabel: "OK",
                    }).then((result) => {
                        window.location.replace("/admin/index");
                    });
                }
                else{
                    Swal.fire({
                        title: `İşlem Hatası!`,
                        icon: "error",
                        html: result.data,
                        allowOutsideClick: false,
                        focusConfirm: false,
                        cancelButtonText: "OK",
                        cancelButtonAriaLabel: "OK",
                    }).then((result) => {
                        this.setState({ isSubmitForm: false });
                    });
                }

            });

    }

    handleSave = (files) => {
        this.setState({ files }, () => { });
    };

    handleSubmit(event) {
        event.preventDefault();
        console.log(this.state.files.length);
        if (this.state.selectedModelValue == undefined) {
            Swal.fire({
                title: `İşlem Başarısız!`,
                icon: "info",
                html: "Model seçimi zorunludur.",
                allowOutsideClick: false,
                focusConfirm: false,
                cancelButtonText: "OK",
                cancelButtonAriaLabel: "OK",
            }).then((result) => {
                console.log("OK CLIC1K");
            });
            console.log("testtst");
            return;
        }

        if (this.state.serialInputValue == undefined) {
            Swal.fire({
                title: `İşlem Başarısız!`,
                icon: "info",
                html: "Seri Numarası zorunludur.",
                allowOutsideClick: false,
                focusConfirm: false,
                cancelButtonText: "OK",
                cancelButtonAriaLabel: "OK",
            }).then((result) => {
                console.log("OK CLI2CK");
            });
            return;
        }

        if (this.state.files.length == 0) {
            Swal.fire({
                title: `İşlem Başarısız!`,
                icon: "info",
                html: "Fatura dosyası zorunludur.",
                allowOutsideClick: false,
                focusConfirm: false,
                cancelButtonText: "OK",
                cancelButtonAriaLabel: "OK",
            }).then((result) => {
                console.log("OK CLI3CK");
            });
            return;
        }

        this.setState({ isSubmitForm: true });
        this.postRegisterProduct(this.state.selectedModelValue.value, this.state.serialInputValue, this.state.files, this.state.invoiceDate);
    }

    handleInvoiceDateChange(value, formattedValue) {
        this.setState({ invoiceDate: value });
        this.setState({ invoiceDateFormatted: formattedValue });
    }



    render() {

        const {
            brandOptions,
            deviceTypeOptions,
            modelOptions,
            files
        } = this.state;

        return (<>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{"Ürünüzü Kaydedin"}</h3>
                                        <h5>Ekstra bir yıl garanti için ürününüzü kaydedin.</h5>
                                        <h5 style={{ color: "Red" }}>+1 yıl ek garanti sadece BLAUPUNKT markalı elektrikli ev aletleri için geçerlidir.</h5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Form id="register-product-form" onSubmit={this.handleSubmit}>
                                <CardBody>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-brandTypeValueID"
                                                    >
                                                        {translator.brand}
                                                    </label>
                                                    <Select
                                                        id="brandTypeValueID"
                                                        name="brand"
                                                        onChange={this.handleBrand}
                                                        options={brandOptions}
                                                        value={this.state.selectedBrandValue}
                                                        placeholder={translator.selectBrand}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="deviceTypeValueID"
                                                    >
                                                        {translator.productType}
                                                    </label>

                                                    <Select
                                                        id="deviceTypeValueID"
                                                        name="deviceType"
                                                        onChange={this.handleDeviceType}
                                                        options={deviceTypeOptions}
                                                        value={this.state.selectedTypeValue}
                                                        placeholder={translator.selectDeviceType}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="modelListValueID"
                                                    >
                                                        {translator.model}
                                                    </label>

                                                    <Select
                                                        id="modelListValueID"
                                                        name="model"
                                                        onChange={this.handleModel}
                                                        options={modelOptions}
                                                        isDisabled={this.state.checktch}
                                                        value={this.state.selectedModelValue}
                                                        placeholder={translator.selectModel}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="serialNoSearch"
                                                >
                                                    {translator.serialNo}{" "}
                                                    {this.state.pageUrlLocation === "tt" ? (
                                                        <span class="label-info">
                                                            (*Bu alan zorunlu değildir.)
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>

                                                <Input
                                                    type="text"
                                                    id="serialNoSearch"
                                                    name="serialNoSearch"
                                                    className="form-control-alternative"
                                                    placeholder={translator.enterSerialNumber}
                                                    value={this.state.serialInputValue}
                                                    onChange={(e) =>
                                                        this.handleSerialNoInput(e)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <label
                                                    style={{ paddingTop: 10 }}
                                                    className="form-control-label"
                                                    htmlFor="example-datepicker"
                                                    placeholder="Fatura Tarihi"
                                                >
                                                    Fatura Tarihi
                                                </label>

                                                <DatePicker id="example-datepicker"
                                                    calendarPlacement='left'
                                                    onChange={(v, f) => this.handleInvoiceDateChange(v, f)} />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {translator.uploadInvoice}
                                                        {this.state.pageUrlLocation === "snl" ? (
                                                            <span class="label-info">
                                                                (Lütfen faturanızı yükleyiniz.)
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </label>
                                                    <DropzoneArea
                                                        className="form-control-alternative"
                                                        maxFileSize={5000000}
                                                        filesLimit={10}
                                                        acceptedFiles={[
                                                            "image/jpeg",
                                                            "image/png",
                                                            "image/bmp",
                                                            "application/pdf"
                                                        ]}
                                                        showAlerts={true}
                                                        dropzoneText={
                                                            translator.uploadInvoice
                                                        }
                                                        onChange={this.handleSave}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <div style={{ paddingTop: 30 }}>
                                                    <Button
                                                        color="primary"
                                                        size="lg"
                                                        type="submit"
                                                        disabled={this.state.isSubmitForm}
                                                    //onClick={this.handleSubmit}
                                                    >
                                                        {translator.send}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>)
    }

}

export default RegisterProduct;
