import { Grid } from "@material-ui/core";
import { Divider, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  DropdownItem,
  Form,
  FormGroup,
  Input,
  Media,
  Row,
  Table,
} from "reactstrap";
import Select from "react-select";
import videoButton from "../../assets/img/button_portal.png";
import Header from "../../components/Headers/Header";
import { DropzoneArea } from "material-ui-dropzone";
import axios from "axios";
import { set } from "lodash";
import ReactFancyBox from "react-fancybox";

const CreateBillInnerView = (props) => {
  const [storeDetails, setStoreDetails] = useState([]);
  const [singleBillData, setSingleBillData] = useState({});
  const [selectedBillId, setSelectedBillId] = useState("");
  const [previousConsumption, setPreviousConsumption] = useState();
  //   const _self = this;
  //   _self.setState({ files }, () => {
  //     if (files && files.length > 0) {
  //       files.map(function (file, index) {
  //         _self.postUploadViaWeb(file);
  //       });
  //     }
  //   });
  const [costAndKWsValue, setCostAndKWsValue] = useState({
    cost: "",
    kw: "",
  });
  const [formData, setFormData] = useState({
    Month: "",
    Year: "",
    TotalConsumption: "",
    T1: "",
    T2: "",
    T3: "",
    T4: "",
    P: "",
    PreviousConsumption: 0,
    UnitCost: 0,
    TotalCost: costAndKWsValue?.cost,
    KW: 0,
  });
  console.log(formData, "formData");
  const [files, setFiles] = useState([]);
  const [isSaved, setIsSaved] = useState(false);
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));
  const getStoreDetails = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAuth.access_token,
      },
    };
    return axios.get(
      "https://api.servissoft.net/tchiboinvoicetrack/getStoreDetails",
      config
    );
  };
  const getPreviousConsumption = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAuth.access_token,
      },
    };
    return axios.get(
      "https://api.servissoft.net/tchiboinvoicetrack/getpreviousconsumption",
      config
    );
  };

  const getSingleBill = (id) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAuth.access_token,
      },
      params: {
        id: id,
      },
    };
    return axios.get(
      "https://api.servissoft.net/tchiboinvoicetrack/single",
      config
    );
  };

  const addBill = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAuth.access_token,
      },
    };
    return axios.post(
      "https://api.servissoft.net/tchiboinvoicetrack/add",
      formData,
      config
    );
  };

  const postUploadViaWeb = (orderID, files) => {
    const url = "https://api.servissoft.net/v1/Upload/UploadViaWeb";
    const formData = new FormData();
    formData.append("relatedID", orderID);
    formData.append("apiKey", userAuth.apiKey);
    formData.append("operationName", "tchiboElectric");
    formData.append("qqfile", files);

    return axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + userAuth.access_token,
        Accept: "*/*",
      },
    });
  };

  const uploadFile = (orderID, files) => {
    const url = "https://api.servissoft.net/v1/Upload/UploadViaWeb";
    const config = {
      params: {
        apiKey: userAuth.apiKey,
        operationName: "tchiboElectric",
        relatedID: orderID,
        qqFile: files,
      },
    };

    return axios.post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAuth.access_token,
      },
    });
  };
  const getBills = () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userAuth.access_token,
      },
    };
    return axios.get(
      "https://api.servissoft.net/tchiboinvoicetrack/list",
      config
    );
  };
  useEffect(() => {
    getPreviousConsumption().then((res) => {
      setPreviousConsumption(res.data);
      setFormData((prevState) => ({
        ...prevState,
        PreviousConsumption: res.data,
      }));
    });
    getStoreDetails().then((res) => {
      setStoreDetails(res.data);
      setFormData((prevState) => ({
        ...prevState,
        UnitCost: res.data[0]?.UnitCost,
      }));
    });
  }, []);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      TotalCost: parseInt(formData.KW) * formData.UnitCost,
    }));
  }, [formData.TotalConsumption]);

  const handleSubmit = () => {
    setIsSaved(true);
    if (formData?.TotalConsumption) {
      addBill().then((eventId) => {
        if (files && files.length > 0) {
          files.map((item) => {
            postUploadViaWeb(eventId.data, item);
          });
        }
        getBills();
      });
      props.history.push("/admin/bills");
    }
  };

  useEffect(() => {
    const isEdit = localStorage.getItem("billId");
    setSelectedBillId(isEdit);
    if (isEdit) {
      getSingleBill(isEdit).then((res) => {
        setSingleBillData(res.data[0]);
      });
    }
    return localStorage.removeItem("billId");
  }, []);

  const calculate = () => {
    const costValue = formData.KW * formData.UnitCost;
    setCostAndKWsValue((prevState) => ({
      ...prevState,
      cost: costValue,
      kw: formData.KW,
    }));
  };

  const singleYear = yearsData.find(
    (item) => item.label === singleBillData.Year
  );

  const singleMonth = monthData.find(
    (item) => item.value === singleBillData.Mont
  );

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        {/* Table */}
        <Row>
          <div className="col">
            <Card className="shadow">
              <Grid
                style={{
                  padding: "32px 24px 12px 24px",
                  display: "flex",
                  flexDirection: "column",
                }}
                container
              >
                <Grid item style={{ display: "flex" }} xs={12}>
                  <Grid className="create-bill-indicators" item>
                    Sayaç No: {storeDetails[0]?.CounterNo || "---"}
                  </Grid>
                  <Grid className="create-bill-indicators" item>
                    Tesisat No: {storeDetails[0]?.FittingNo || "---"}
                  </Grid>
                  <Grid className="create-bill-indicators" item>
                    Enerji Şirketi: {storeDetails[0]?.EnergyCompany || "---"}
                  </Grid>
                </Grid>
                <Typography className="create-bill-header">
                  Fatura Kaydı
                </Typography>
                <Typography className="create-bill-subheader">
                  Zorunlu alanları doldurmayı unutmayınız.
                </Typography>
              </Grid>
              <DropdownItem style={{ margin: "0" }} divider />
              <Grid
                style={{
                  padding: "24px",
                  display: "flex",
                  flexDirection: "column",
                  backgroundColor: "#F6FAFD",
                }}
                container
              >
                <Form id="open-order-form">
                  <FormGroup>
                    <Row>
                      <Col xs={7}>
                        <Row xs={12}>
                          <Col>
                            {selectedBillId ? (
                              <Select
                                id="Month"
                                name="Month"
                                options={monthData}
                                value={singleMonth}
                                isDisabled={true}
                              />
                            ) : (
                              <Select
                                id="Month"
                                name="Month"
                                options={monthData}
                                placeholder="Ay Seçiniz"
                                onChange={(e) => {
                                  setFormData({ ...formData, Month: e.value });
                                }}
                              />
                            )}
                          </Col>
                          <Col>
                            {selectedBillId ? (
                              <Select
                                id="Year"
                                name="Year"
                                options={yearsData}
                                value={singleYear}
                                isDisabled={true}
                              />
                            ) : (
                              <Select
                                id="Year"
                                name="Year"
                                options={yearsData}
                                placeholder="Yıl Seçiniz"
                                onChange={(e) => {
                                  setFormData({ ...formData, Year: e.label });
                                }}
                              />
                            )}
                          </Col>
                        </Row>
                        <Typography
                          style={{ paddingTop: "24px" }}
                          className="create-bill-form-headers"
                        >
                          Sayaç Endeksi
                        </Typography>
                        <Row xs={12}>
                          <Col className="mr-top-8">
                            <label
                              className="create-bill-form-labels"
                              htmlFor="TotalConsumption"
                            >
                              T (Toplam Tüketim) *
                            </label>
                            <Input
                              type="number"
                              id="TotalConsumption"
                              name="TotalConsumption"
                              size="sm"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  TotalConsumption: e.target.value,
                                  KW: e.target.value,
                                });
                                setCostAndKWsValue({
                                  cost: "",
                                  kw: "",
                                });
                              }}
                              value={
                                selectedBillId &&
                                singleBillData?.TotalConsumption
                              }
                              disabled={selectedBillId}
                            />
                            {isSaved && !formData.TotalConsumption && (
                              <span style={{ fontSize: "12px", color: "red" }}>
                                Toplam tüketim alanını doldurunuz.
                              </span>
                            )}
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col className="mr-top-8">
                            <label
                              className="create-bill-form-labels"
                              htmlFor="T1"
                            >
                              T1 (Gündüz Tüketimi 06:00-17:00)
                            </label>
                            <Input
                              type="number"
                              id="T1"
                              name="T1"
                              size="sm"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  T1: e.target.value,
                                });
                              }}
                              disabled={selectedBillId}
                              value={selectedBillId && singleBillData?.T1}
                            />
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col className="mr-top-8">
                            <label
                              className="create-bill-form-labels"
                              htmlFor="T2"
                            >
                              T2 (Pant(Akşam) Tüketimi 17:00-22:00)
                            </label>
                            <Input
                              type="number"
                              id="T2"
                              name="T2"
                              size="sm"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  T2: e.target.value,
                                });
                              }}
                              value={selectedBillId && singleBillData?.T2}
                              disabled={selectedBillId}
                            />
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col className="mr-top-8">
                            <label
                              className="create-bill-form-labels"
                              htmlFor="T3"
                            >
                              T3 (Gece Tüketimi 22:00-06:00)
                            </label>
                            <Input
                              type="number"
                              id="T3"
                              name="T3"
                              size="sm"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  T3: e.target.value,
                                });
                              }}
                              value={selectedBillId && singleBillData?.T3}
                              disabled={selectedBillId}
                            />
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col className="mr-top-8">
                            <label
                              className="create-bill-form-labels"
                              htmlFor="T4"
                            >
                              T4
                            </label>
                            <Input
                              type="number"
                              id="T4"
                              name="T4"
                              size="sm"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  T4: e.target.value,
                                });
                              }}
                              value={selectedBillId && singleBillData?.T4}
                              disabled={selectedBillId}
                            />
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col className="mr-top-8">
                            <label
                              className="create-bill-form-labels"
                              htmlFor="P"
                            >
                              P
                            </label>
                            <Input
                              type="number"
                              id="P"
                              name="P"
                              size="sm"
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  P: e.target.value,
                                });
                              }}
                              value={selectedBillId && singleBillData?.P}
                              disabled={selectedBillId}
                            />
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col>
                            <label
                              className="create-bill-form-headers"
                              style={{ fontWeight: "600", marginTop: "32px" }}
                              htmlFor="PreviousConsumption"
                            >
                              Önceki Endeks
                            </label>
                            <Input
                              type="number"
                              id="PreviousConsumption"
                              name="PreviousConsumption"
                              size="sm"
                              value={
                                selectedBillId
                                  ? singleBillData?.PreviousConsumption
                                  : previousConsumption
                              }
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  PreviousConsumption: e.target.value,
                                });
                              }}
                              disabled
                            />
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col>
                            <label
                              className="create-bill-form-headers"
                              style={{ fontWeight: "600", marginTop: "32px" }}
                              htmlFor="UnitCost"
                            >
                              Birim Fiyat
                            </label>
                            <Input
                              type="number"
                              id="UnitCost"
                              name="UnitCost"
                              size="sm"
                              value={
                                selectedBillId
                                  ? singleBillData?.UnitCost
                                  : storeDetails[0]?.UnitCost
                              }
                              disabled
                            />
                          </Col>
                        </Row>
                        <Row xs={12}>
                          <Col>
                            {selectedBillId ? (
                              <div
                                style={{ marginTop: "24px" }}
                                className="col"
                              >
                                <Card className="shadow">
                                  <CardHeader className="border-0">
                                    <h3 className="mb-0">Dosyalar</h3>
                                  </CardHeader>
                                  <Table
                                    className="align-items-center table-flush"
                                    responsive
                                  >
                                    <thead className="thead-light">
                                      <tr>
                                        <th scope="col">Dosya Adı</th>
                                        <th scope="col" />
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {singleBillData?.Documents &&
                                        singleBillData?.Documents.length > 0 &&
                                        singleBillData?.Documents.map((row) => {
                                          var str = row.FileName;
                                          var isVideo = str.includes("mp4");

                                          return (
                                            <tr>
                                              <th scope="row">
                                                <Media className="align-items-center">
                                                  {isVideo ? (
                                                    <>
                                                      <a
                                                        href={row.FileLocation}
                                                        target="_blank"
                                                      >
                                                        <img
                                                          src={videoButton}
                                                          width={40}
                                                        />
                                                      </a>
                                                    </>
                                                  ) : (
                                                    <div>
                                                      <ReactFancyBox
                                                        thumbnail={row.FileUrl}
                                                        image={row.FileUrl}
                                                      />
                                                    </div>
                                                  )}

                                                  <Media>
                                                    <span className="mb-0 text-sm ml-2">
                                                      <a
                                                        href={row.FileLocation}
                                                      >
                                                        {row.FileName}
                                                      </a>
                                                    </span>
                                                  </Media>
                                                </Media>
                                              </th>
                                            </tr>
                                          );
                                        })}
                                    </tbody>
                                  </Table>
                                </Card>
                              </div>
                            ) : (
                              <>
                                <label
                                  className="create-bill-form-headers"
                                  style={{
                                    fontWeight: "600",
                                    marginTop: "32px",
                                  }}
                                  htmlFor="fileUpload"
                                >
                                  Dosya Ekle
                                </label>
                                <DropzoneArea
                                  maxFileSize={5000000}
                                  filesLimit={10}
                                  showAlerts={true}
                                  onChange={(files) => {
                                    setFiles(files);
                                  }}
                                  initialFiles={
                                    selectedBillId && singleBillData?.Documents
                                  }
                                />
                              </>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </FormGroup>
                </Form>
                {!selectedBillId && (
                  <Row style={{ paddingLeft: "16px" }} xs={3}>
                    <button
                      style={{
                        padding: "4px 22px",
                        color: "#FFFFFF",
                        fontSize: "1.188rem",
                        fontWeight: "600",
                        background:
                          formData.TotalConsumption && formData.KW
                            ? "#2DCE88"
                            : "#9BA6B2",
                        border:
                          formData.TotalConsumption && formData.KW
                            ? "1px solid #2AD587"
                            : "1px solid #9BA6B2",
                        borderRadius: "5px",
                        marginTop: "32px",
                        cursor: "pointer",
                      }}
                      disabled={
                        !formData.TotalConsumption ||
                        !formData.KW ||
                        selectedBillId
                      }
                      onClick={calculate}
                    >
                      Hesapla
                    </button>
                  </Row>
                )}
                <Col style={{ padding: "0" }} xs={7}>
                  <Row xs={6}>
                    <Col>
                      <label
                        className="create-bill-form-headers"
                        style={{ fontWeight: "600", marginTop: "32px" }}
                        htmlFor="TotalCost"
                      >
                        Tutar
                      </label>
                      <Input
                        type="text"
                        id="TotalCost"
                        name="TotalCost"
                        size="sm"
                        value={
                          selectedBillId
                            ? singleBillData?.TotalCost
                            : costAndKWsValue?.cost
                        }
                        disabled
                      />
                    </Col>
                    <Col>
                      <label
                        className="create-bill-form-headers"
                        style={{ fontWeight: "600", marginTop: "32px" }}
                        htmlFor="KW"
                      >
                        KW
                      </label>
                      <Input
                        type="text"
                        id="KW"
                        name="KW"
                        size="sm"
                        value={
                          selectedBillId
                            ? singleBillData?.KW
                            : costAndKWsValue?.kw
                        }
                        disabled
                      />
                    </Col>
                  </Row>
                </Col>
                {!selectedBillId && (
                  <Row>
                    <Col
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <Button
                        style={{
                          padding: "4px 22px",
                          color: "#FFFFFF",
                          fontSize: "1.188rem",
                          fontWeight: "600",
                          background: "#2DCE88",
                          border: "1px solid #2AD587",
                          borderRadius: "5px",
                          marginTop: "32px",
                          cursor: "pointer",
                        }}
                        onClick={handleSubmit}
                      >
                        Kaydet
                      </Button>
                    </Col>
                  </Row>
                )}
              </Grid>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};
export default CreateBillInnerView;
const yearsData = [
  { value: 1, label: 2022 },
  { value: 2, label: 2021 },
  { value: 3, label: 2020 },
  { value: 4, label: 2019 },
  { value: 5, label: 2018 },
  { value: 6, label: 2017 },
  { value: 7, label: 2016 },
  { value: 8, label: 2015 },
  { value: 9, label: 2014 },
  { value: 10, label: 2013 },
  { value: 11, label: 2012 },
  { value: 12, label: 2011 },
  { value: 13, label: 2010 },
  { value: 14, label: 2009 },
  { value: 15, label: 2008 },
  { value: 16, label: 2007 },
  { value: 17, label: 2006 },
  { value: 18, label: 2005 },
  { value: 19, label: 2004 },
  { value: 20, label: 2003 },
  { value: 21, label: 2002 },
  { value: 22, label: 2001 },
  { value: 23, label: 2000 },
  { value: 24, label: 1999 },
  { value: 25, label: 1998 },
  { value: 26, label: 1997 },
  { value: 27, label: 1996 },
  { value: 28, label: 1995 },
  { value: 29, label: 1994 },
  { value: 30, label: 1993 },
  { value: 31, label: 1992 },
  { value: 32, label: 1991 },
  { value: 33, label: 1990 },
  { value: 34, label: 1989 },
  { value: 35, label: 1988 },
  { value: 36, label: 1987 },
  { value: 37, label: 1986 },
  { value: 38, label: 1985 },
  { value: 39, label: 1984 },
  { value: 40, label: 1983 },
  { value: 41, label: 1982 },
  { value: 42, label: 1981 },
  { value: 43, label: 1980 },
];
const monthData = [
  { value: 1, label: "Ocak" },
  { value: 2, label: "Şubat" },
  { value: 3, label: "Mart" },
  { value: 4, label: "Nisan" },
  { value: 5, label: "Mayıs" },
  { value: 6, label: "Haziran" },
  { value: 7, label: "Temmuz" },
  { value: 8, label: "Ağustos" },
  { value: 9, label: "Eylül" },
  { value: 10, label: "Ekim" },
  { value: 11, label: "Kasım" },
  { value: 12, label: "Aralık" },
];
