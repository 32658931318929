import React from "react";
import {
    CardBody,
    Form,
    FormGroup,
    Input,
    Button, Card, Container, Row, Col, CardHeader, Table
} from "reactstrap"

import Header from "../../components/Headers/Header.jsx";
import translator from '../../actions/translator.js';
import { apiActions } from '../../apiCall/api.services.js';
import { DropzoneArea } from "material-ui-dropzone";
import Swal from "sweetalert2";



class BilkomWarrantyCodeActivation extends React.Component {
    constructor(props) {
        super(props);
        let userAuth = JSON.parse(localStorage.getItem("userAuth"));

        this.state = {
            userAuthOptions: {
                accessToken: userAuth.access_token,
                apiKey: userAuth.apiKey,
                clientID: userAuth.clientID,
                roleID: userAuth.roleID,
            },
            serialInputValue: "",
            activationCodeInputValue: "",
            files: [],
            deviceInfo: {
                deviceBrand: "-",
                deviceType: "-",
                deviceModel: "-",
                insertDate: "-",
                serialnoId: 0
            }
        };

        this.handleSubmit = this.handleSubmit.bind(this);

    }

    componentDidMount() {
    }

    handleSerialNoInput = (e) => {
        this.setState({ serialInputValue: e.target.value });
    };

    handleActivationCodeText = (e) => {
        this.setState({ activationCodeInputValue: e.target.value });
    }


    handleSave = (files) => {
        this.setState({ files }, () => { });
        console.log("files", this.state.files);
    };

    handleSerialNoSearch(e) {
        e.preventDefault();
        const currentComponent = this;
        let userAuth = JSON.parse(localStorage.getItem("userAuth"));
        var serialNoExactPromise = apiActions.SearchSerialNoExact(userAuth.access_token, this.state.serialInputValue);
        serialNoExactPromise.then(function (result) {

            if (result.data.data == null) {
                Swal.fire({
                    title: `Seri numarası bulunamadı!`,
                    icon: "info",
                    html: "Girdiğiniz seri numarası sistemde bulunamadı.",
                    allowOutsideClick: false,
                    focusConfirm: false,
                    cancelButtonText: "OK",
                    cancelButtonAriaLabel: "OK",
                }).then((result) => {
                    console.log("OK CLI3CK");
                });
                return;
            }
            var serialNoExact = result.data.data;
            let deviceInfoBody = {
                deviceBrand: serialNoExact.BrandName,
                deviceType: serialNoExact.DeviceTypeName,
                deviceModel: serialNoExact.DeviceName,
                insertDate: "-",
                serialnoId: serialNoExact.SerialNoID
            };
            currentComponent.setState({ deviceInfo: deviceInfoBody });
        });


    }

    handleSubmit(event) {
        event.preventDefault();
        const currentComponent = this;
        if (this.state.serialInputValue == undefined || this.state.serialInputValue == "") {
            Swal.fire({
                title: `İşlem Başarısız!`,
                icon: "info",
                html: "Seri Numarası zorunludur.",
                allowOutsideClick: false,
                focusConfirm: false,
                cancelButtonText: "OK",
                cancelButtonAriaLabel: "OK",
            }).then((result) => {
                console.log("OK CLI2CK");
            });
            return;
        }

        if (this.state.activationCodeInputValue == undefined || this.state.activationCodeInputValue == "") {
            Swal.fire({
                title: `İşlem Başarısız!`,
                icon: "info",
                html: "Aktivasyon kodu zorunludur.",
                allowOutsideClick: false,
                focusConfirm: false,
                cancelButtonText: "OK",
                cancelButtonAriaLabel: "OK",
            }).then((result) => {
                console.log("OK CLI2CK");
            });
            return;
        }

        if (this.state.files.length == 0) {
            Swal.fire({
                title: `İşlem Başarısız!`,
                icon: "info",
                html: "Fatura dosyası zorunludur.",
                allowOutsideClick: false,
                focusConfirm: false,
                cancelButtonText: "OK",
                cancelButtonAriaLabel: "OK",
            }).then((result) => {
                console.log("OK CLI2CK");
            });
            return;
        }
        if (this.state.deviceInfo.serialnoId == 0) {
            Swal.fire({
                title: `İşlem Başarısız!`,
                icon: "info",
                html: "Lütfen önce ara butonu ile seri numarasını aratınız.",
                allowOutsideClick: false,
                focusConfirm: false,
                cancelButtonText: "OK",
                cancelButtonAriaLabel: "OK",
            }).then((result) => {
                console.log("OK CLI2CK");
            });
            return;
        }




        apiActions.BilkomWarrantyCodeActivation(
            this.state.userAuthOptions.accessToken,
            this.state.deviceInfo.serialnoId,
            this.state.activationCodeInputValue
        ).then(function (result) {

            var response = result.data.data;
            console.log(response);
            if (response == "OK") {
                Swal.fire({
                    title: `İşlem Başarılı!`,
                    icon: "success",
                    html: "Garanti tarihi uzatılmıştır.",
                    allowOutsideClick: false,
                    focusConfirm: false,
                    cancelButtonText: "OK",
                    cancelButtonAriaLabel: "OK",
                }).then((result) => {
                    console.log("OK CLI2CK");
                });
                return;
            }
            else if (response == "UsedBefore") {
                Swal.fire({
                    title: `İşlem Başarısız!`,
                    icon: "info",
                    html: "Aktivasyon kodu daha önce kullanılmış.",
                    allowOutsideClick: false,
                    focusConfirm: false,
                    cancelButtonText: "OK",
                    cancelButtonAriaLabel: "OK",
                }).then((result) => {
                    console.log("OK CLI2CK");
                });
                return;
            }
            else if (response == "NotFound") {
                Swal.fire({
                    title: `İşlem Başarısız!`,
                    icon: "info",
                    html: "Aktivasyon kodu sistemde bulunamadı.",
                    allowOutsideClick: false,
                    focusConfirm: false,
                    cancelButtonText: "OK",
                    cancelButtonAriaLabel: "OK",
                }).then((result) => {
                    console.log("OK CLI2CK");
                });
                return;
            }
            else if (response == "ActivationError") {
                Swal.fire({
                    title: `İşlem Başarısız!`,
                    icon: "info",
                    html: "İşlem sırasında bir hata oluştu.",
                    allowOutsideClick: false,
                    focusConfirm: false,
                    cancelButtonText: "OK",
                    cancelButtonAriaLabel: "OK",
                }).then((result) => {
                    console.log("OK CLI2CK");
                });
                return;
            }
            else if (response == "ModelNotMatches") {
                Swal.fire({
                    title: `İşlem Başarısız!`,
                    icon: "info",
                    html: "İlgili koda ait model ile seri numarasının model bilgisi eşleşmiyor.",
                    allowOutsideClick: false,
                    focusConfirm: false,
                    cancelButtonText: "OK",
                    cancelButtonAriaLabel: "OK",
                }).then((result) => {
                    console.log("OK CLI2CK");
                });
                return;
            }


            currentComponent.state.files.map(function (file, index) {
                var resultData = { "JobOrderID": currentComponent.state.deviceInfo.serialnoId };
                apiActions.postUploadViaWeb(currentComponent.state.userAuthOptions.accessToken, currentComponent.state.userAuthOptions.apiKey, resultData, file, "serialNoFile");
            });
        });
    }


    render() {


        return (<>
            <Header />
            {/* Page content */}
            <Container className="mt--7" fluid>
                <Row className="mt-5">
                    <Col className="order-xl-1" xl="12">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">{"Ürünüzü Kaydedin"}</h3>
                                        <h5>Ekstra bir yıl garanti için ürününüzü kaydedin.</h5>
                                    </Col>
                                </Row>
                            </CardHeader>
                            <Form id="register-product-form" onSubmit={this.handleSubmit}>
                                <CardBody>
                                    <div className="pl-lg-4">
                                        <Row>
                                            <Col lg="12">
                                                <label className="form-control-label" htmlFor="serialNoSearch">
                                                    {translator.serialNo}{" "}
                                                    {this.state.pageUrlLocation === "tt" ? (
                                                        <span class="label-info">
                                                            (*Bu alan zorunlu değildir.)
                                                        </span>
                                                    ) : (
                                                        ""
                                                    )}
                                                </label>
                                            </Col>
                                            <Col lg="11">
                                                <Input
                                                    type="text"
                                                    id="serialNoSearch"
                                                    name="serialNoSearch"
                                                    className="form-control-alternative"
                                                    placeholder={translator.enterSerialNumber}
                                                    value={this.state.serialInputValue}
                                                    onChange={(e) =>
                                                        this.handleSerialNoInput(e)
                                                    }
                                                />
                                            </Col>
                                            <Col lg="1">
                                                <Button
                                                    color="primary"
                                                    size="lg"
                                                    type="submit"
                                                    disabled={this.state.isSubmitForm}
                                                    onClick={(e) =>
                                                        this.handleSerialNoSearch(e)
                                                    }
                                                >
                                                    {"Ara"}
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12" style={{ paddingTop: 30, paddingBottom: 30 }}>
                                                <Card className="shadow">
                                                    <CardHeader className="border-0">
                                                        <h3 className="mb-0">
                                                            Cihaz Bilgileri
                                                        </h3>
                                                    </CardHeader>
                                                    <Table className="align-items-center table-flush" responsive>
                                                        <thead className="thead-light">
                                                            <tr>
                                                                <th scope="col" style={{ width: "25%" }}>
                                                                    Marka
                                                                </th>
                                                                <th scope="col" style={{ width: "25%" }}>
                                                                    Cihaz Türü
                                                                </th>
                                                                <th scope="col" style={{ width: "25%" }}>
                                                                    Model
                                                                </th>
                                                                <th scope="col" style={{ width: "25%" }}>
                                                                    Kayıt Tarihi
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>{this.state.deviceInfo.deviceBrand}</td>
                                                                <td>{this.state.deviceInfo.deviceType}</td>
                                                                <td>{this.state.deviceInfo.deviceModel}</td>
                                                                <td>{this.state.deviceInfo.insertDate}</td>
                                                            </tr>
                                                        </tbody>
                                                    </Table>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <label className="form-control-label" htmlFor="serialNoSearch">
                                                    {"Aktivasyon Kodu"}{" "}
                                                </label>
                                            </Col>
                                            <Col lg="12">
                                                <Input
                                                    type="text"
                                                    id="activationCode"
                                                    name="activationCode"
                                                    className="form-control-alternative"
                                                    placeholder={"Aktivasyon Kodunu Giriniz"}
                                                    value={this.state.activationCodeInputValue}
                                                    onChange={(e) =>
                                                        this.handleActivationCodeText(e)
                                                    }
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <FormGroup>
                                                    <label
                                                        className="form-control-label"
                                                        htmlFor="input-username"
                                                    >
                                                        {translator.uploadInvoice}
                                                        {this.state.pageUrlLocation === "snl" ? (
                                                            <span class="label-info">
                                                                (Lütfen faturanızı yükleyiniz.)
                                                            </span>
                                                        ) : (
                                                            ""
                                                        )}
                                                    </label>
                                                    <DropzoneArea
                                                        className="form-control-alternative"
                                                        maxFileSize={5000000}
                                                        filesLimit={10}
                                                        acceptedFiles={[
                                                            "image/jpeg",
                                                            "image/png",
                                                            "image/bmp",
                                                            "application/pdf"
                                                        ]}
                                                        showAlerts={true}
                                                        dropzoneText={
                                                            translator.uploadInvoice
                                                        }
                                                        onChange={this.handleSave}
                                                    />
                                                </FormGroup>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg="12">
                                                <div style={{ paddingTop: 30 }}>
                                                    <Button
                                                        color="primary"
                                                        size="lg"
                                                        type="submit"
                                                        disabled={this.state.isSubmitForm}
                                                        onClick={this.handleSubmit}
                                                    >
                                                        {translator.send}
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </CardBody>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>)
    }

}

export default BilkomWarrantyCodeActivation;
