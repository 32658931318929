import axios from 'axios';
import setAuthToken from '../utils/setAuthToken';
import { SET_CURRENT_USER } from './types';
import { apiActions } from '../apiCall/api.services';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

// REGISTER
export const registerUser = (userData, history) => {
  axios
    .post('/api/v1/auth/register', userData)
    .then((res) => history.push('/login'))
    .catch((err) => console.log(err));
};

// LOGIN
export const loginUser = (userData) => (dispatch) => {
  const url = 'https://api.servissoft.net/CustomerToken?';
  const requestBody =
    'grant_type=password&clientID=' +
    userData.username +
    '&clientSecret=' +
    userData.password;
  axios
    .post(url, requestBody)
    .then((res) => {
      localStorage.setItem('userAuth', JSON.stringify(res.data));
      localStorage.setItem('portalId', userData.username);

      // Gelen token'i kullanmak için değişken olarak saklıyoruz
      const token = res.data.access_token;

      // Token'i localStorage'da saklıyoruz
      localStorage.setItem('jwtToken', token);
      // Daha önce yazdığımız fonksiyon ile gelecek istekler için        kaydediyoruz
      setAuthToken(token);

      // Token'i Çözümlüyoruz

      // ve Çözümlediğimiz Token'i Reducer'a iletmesi için       setCurrentUser Action'una gönderiyoruz
      dispatch(setCurrentUser(token));
      window.location.reload();
    })
    .catch((err) => {
      console.log(err);
      const options = {
        title: 'Title',
        message: 'Message',
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      };
      confirmAlert(options);
      confirmAlert({
        title: 'Hata',
        message: 'Kullanıcı Adı veya Şifre Yanlış',
        buttons: [
          {
            label: 'Ok',
          },
        ],
      });
    });
};

// KULLANICIYI REDUCER'A GÖNDERİYORUZ
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded, // Bilgi taşıyan kutular
  };
};

// REGISTER
export const logoutUser = () => {
  localStorage.removeItem('userAuth');
  window.location.replace('/');
};
