import React from "react";

function translate() {
  const translatorNew = [
    {
      allProducts: "All Products",
      productID: "Product ID",
      deviceType: "Device Type",
      brand: "Brand",
      model: "Model",
      serialNo: "Serial No",
      store: "Store",
      openWorkOrder: "Open Work Order",
      dashboard: "New Work Order",
      activeOrders: "Open Work Orders",
      pastOrders: "Closed Work Orders",
      myAccount: "My Account",
      userInformation: "User Information",
      name: "Name",
      surname: "Surname",
      phone: "Phone",
      email: "E-Mail",
      province: "Province",
      selectProvince: "Select Province",
      county: "County",
      selectCounty: "Select County",
      district: "District",
      selectDistrict: "Select District",
      streetBuildingNumber: "Street / Building Number",
      addressDirections: "Address Directions",
      update: "Update",
      referenceID: "Reference ID",
      customerName: "Customer Name",
      status: "Status",
      passedTime: "Passed Time",
      insertDate: "Insert Date",
      finishDate: "Finish Date",
      jobFailed: "Reopen work order",
      jobFailedTch: "Job Incomplete",
      detail: "Detail",
      details: "Details",
      supportDetail: "Support Detail",
      jobStatus: "Job Status",
      detectedFault: "Detected Fault",
      deviceInfo: "Device Info",
      complaint: "Description",
      descriptionOfTheTransaction: "Description of the Transaction",
      transactions: "Transactions",
      totalPrice: "Total Price",
      files: "Files",
      files: "File Name",
      ticket: "Notes",
      send: "Send",
      enterYourTicketNote: "Enter Your Notes",
      oneDirection: "One Direction",
      twoDirection: "Double Direction",
      congratulations: "Congratulations",
      yourMessageHasBeenSentSuccessfully:
        "Your Message Has Been Sent Successfully",
      pay: "Pay",
      paymentStatus: "Payment Status",
      fullDeviceName: "Full Device Name",
      search: "Search",
      productType: "Product Type",
      faultDescription: "Fault Description",
      note: "Note",
      requiredFields: "Do not forget to fill in the required fields.",
      supportRequest: "Work Order",
      enterSerialNumber: "Enter Serial Number",
      enterFaultDescription: "Enter Fault Description",
      enterNote: "Enter Note",
      chooseProductType: "Choose Product Type",
      chooseModel: "Choose Model",
      select: "Select",
      dragAndDropAnImageFileHereOrClick:
        "Drag & drop or click here to add file",
      chooseModel: "Choose Brand",
      chooseSerialNo: "Choose Serial No",
      claimList: "Claim List",
      technician: "Technician",
      invoiceNumber: "Invoice Number",
      invoiceDate: "Invoice Date",
      claimID: "Claim ID",
      claimRefID: "Claim Ref ID",
      companyName: "Company Name",
      total: "Total",
      grandTotal: "Grand Total",
      submitDate: " Submit Date",
      status: "Status",
      finalized: "Finalized",
      partialRejection: "Partial Rejected",
      underAppraisal: "Under Appraisal",
      notEvaluated: "Not Evaluated",
      seeClaim: "See Claim",
      finalizedCount: "Finalized",
      partialRejectionCount: "Partial Rejected",
      underAppraisalCount: "Under Appraisal",
      waitingAppraisalCount: "Waiting Appraisal",
      serviceCode: "Service Code",
      serviceName: "Service Name",
      serviceProvince: "Service Province",
      serviceCounty: "Service County",
      createDate: "Create Date",
      changedParts: "Changed Parts",
      startDate: "Start Date",
      endDate: "End Date",
      detectedFault: "Detected Fault",
      unitCost: "Unit Cost",
      totalCost: "Total Cost",
      usedPieces: "Used Pieces",
      deviceName: "Device Name",
      approve: "Approve",
      reject: "Reject",
      undo: "Undo",
      approvedCost: "Approved Cost",
      deleteClaim: "Delete Claim",
      finalizeClaim: "Finalize Claim",
      reOpenWorkOrder: "ReOpen Work Order",
      customer: "Customer",
      serviceTypes: "Product Types",
      selectServiceType: "Select Product Type",
      enterCustomerName: "Enter Customer Name",
      enterSerialNo: "Enter Serial No",
      selectBrand: "Select Brand",
      selectModel: "Select Model",
      selectDeviceType: "Select Device Type",
      selectSerialNo: "Select Servis Type",
      serviceNo: "Service No",
      demands: "All Demands",
      demandsItem: "Demanded Item",
      createNewDemands: "Add New Demands",
      demandsManagement: "Demands Management",
      demandsID: "Demand No",
      demandant: "Demandant",
      demandedStore: "Demanded Store",
      costCenter: "Cost Center",
      process: "Process",
      supplier: "Supplier",
      product: "Product",
      piece: "Piece",
      chooseFile: "Choose File",
      addNewProduct: "Add New Product",
      createDemand: "Create Demand",
      description: "Description",
      uploadFile: "Upload File",
      noMatchInTable: "Sorry, no matching records found",
      productName: "Product Name",
      demandItemId: "Demand Item Id",
      demandId: "Demand Id",
      estimatedAmount: "Estimated Amount",
      noPayment: "Valid payment has not been found",
      paymentSuccess:
        "Your payment link has been created succesfully. You have to pay this amount end of the day",
      buyerName: "Buyer Name",
      buyerSurname: "Buyer Surname",
      makePayment: "Make a payment",
      createPaymentLink: "Create Payment Link",
      processError: "Sorry, Something went wrong ...",
      successDemand: "Demand created successfully",
      forgotPasswordEnterEmail:
        "Enter your e-mail address registered in the system in the field below. We will send your password reset information to your e-mail address.",
      sendPassword: "Send password",
      backToLogin: "Back to login",
    },
    {
      allProducts: "Tüm Ürünler",
      productID: "Ürün ID",
      deviceType: "Cihaz Tipi",
      brand: "Marka",
      model: "Model",
      serialNo: "Seri No",
      Store: "Mağaza",
      openWorkOrder: "İş Emri Aç",
      dashboard: "Yeni Servis Kaydı",
      activeOrders: "Açık Servis Kayıtları",
      pastOrders: "Kapanmış Servis Kayıtları",
      myAccount: "Hesabım",
      userInformation: "Kullanıcı Bilgileri",
      name: "Ad",
      surname: "Soyad",
      phone: "Telefon",
      email: "E-Posta",
      province: "İl",
      selectProvince: "İl Seçiniz",
      county: "İlçe",
      selectCounty: "İlçe Seçiniz",
      district: "Mahalle",
      selectDistrict: "Mahalle Seçiniz",
      streetBuildingNumber: "Sokak / Bina No / Daire",
      addressDirections: "Adres Tarifi",
      update: "Güncelle",
      referenceID: "Referans ID",
      customerName: "Müşteri Adı",
      status: "Durum",
      passedTime: "Geçen Zaman",
      insertDate: "Eklenme Tarihi",
      finishDate: "Bitiş Tarihi",
      jobFailed: "İş emrini aç",
      jobFailedTch: "İş Tamamlanmamış",
      detail: "Detay",
      details: "Detaylar",
      supportDetail: "Destek Detayı",
      jobStatus: "İşlem Durumu",
      detectedFault: "Tespit Edilen Arıza",
      deviceInfo: "Cihaz Bilgisi",
      complaint: "Açıklama",
      descriptionOfTheTransaction: "Yapılan İşlem Açıklaması",
      transactions: "Yapılan İşlemler",
      totalPrice: "Toplam Ücret",
      files: "Dosyalar",
      files: "Dosya Adı",
      ticket: "Notlar",
      send: "Gönder",
      enterYourTicketNote: "Notunuzu Giriniz",
      oneDirection: "Tek Yön",
      twoDirection: "Çift Yön",
      congratulations: "Tebrikler",
      yourMessageHasBeenSentSuccessfully:
        "Mesajınız Başarılı Şekilde İletilmiştir.",
      pay: "Öde",
      paymentStatus: "Ödeme Durumu",
      fullDeviceName: "Cihaz Adı",
      search: "Ara",
      productType: "Ürün Türü",
      faultDescription: "Arıza Açıklaması",
      note: "Not",
      requiredFields: "Zorunlu alanları doldurmayı unutmayınız.",
      supportRequest: "Servis Kaydı",
      enterSerialNumber: "Seri Numarası Giriniz",
      enterFaultDescription: "Arıza Açıklaması Giriniz",
      enterNote: "Not Giriniz",
      chooseProductType: "Ürün Türü Seçiniz",
      chooseModel: "Model Seçiniz",
      chooseModel: "Marka Seçiniz",
      select: "Seçiniz",
      dragAndDropAnImageFileHereOrClick:
        "Fotoğrafları buraya sürükleyip bırakabilir veya seçmek için tıklayabiliirsiniz.",
      chooseSerialNo: "Choose Serial No",
      claimList: "Hakediş Listesi",
      technician: "Teknisyen",
      invoiceNumber: "Fatura Numarası",
      invoiceDate: "Fatura Tarihi",
      claimID: "Hakediş ID",
      claimRefID: "Hakediş Ref ID",
      companyName: "Firma Adı",
      total: "Toplam",
      grandTotal: "Genel Toplam",
      submitDate: "Gönderme Tarihi",
      status: "Durum",
      finalized: "Kesinleşti",
      partialRejection: "Kısmi Red",
      underAppraisal: "Değerlendiriliyor",
      notEvaluated: "Değerlendirilmedi",
      seeClaim: "Hakedişi Gör",
      finalizedCount: "Kesinleşen",
      partialRejectionCount: "Kısmi Red",
      underAppraisalCount: "Değerlendirilen",
      waitingAppraisalCount: "Değerlendirilmeyen",
      serviceCode: "Servis Kodu",
      serviceName: "Servis İsmi",
      serviceProvince: "Servis İl",
      serviceCounty: "Servis İlçe",
      createdDate: "Oluşturulma Tarihi",
      changedParts: "Değişen Parçalar",
      startDate: "Başlama Tarihi",
      endDate: "Bitiş Tarihi",
      detectedFault: "Tespit Edilen Arıza",
      unitCost: "Parça Fiyatı",
      totalCost: "Toplam Maliyet",
      approvedCost: "Kabul Edilen Tutar",
      usedPieces: "Kullanılan Parçalar",
      deviceName: "Cihaz Adı",
      approve: "Onayla",
      reject: "Reddet",
      undo: "Geri al",
      approvedCost: "Onaylanan Tutar",
      deleteClaim: "Hakedişi Sil",
      finalizeClaim: "Hakedişi Kesinleştir",
      reOpenWorkOrder: "İş Emrini Tekrar aç",
      customer: "Müşteri",
      serviceTypes: "Ürün Türü",
      selectServiceType: "Ürün Türü Seçiniz",
      enterCustomerName: "Müşteri adını giriniz",
      enterSerialNo: "Seri No Giriniz",
      selectBrand: "Marka Seçiniz",
      selectModel: "Model Seçiniz",
      selectDeviceType: "Ürün Türü Seçiniz",
      selectSerialNo: "Seri No Seçiniz",
      serviceNo: "Servis No",
      demands: "Tüm Talepler",
      demandsItem: "Talep Edilen Ürünler",
      createNewDemands: "Yeni Talep Ekle",
      demandsManagement: "Talep Yönetimi",
      demandsID: "Talep No",
      demandant: "Talep Eden",
      demandedStore: "Talep Edilen Mağaza",
      costCenter: "Cost Center",
      process: "Süreç",
      supplier: "Tedarikçi",
      product: "Ürün",
      piece: "Adet",
      chooseFile: "Dosya Seçimi",
      addNewProduct: "Yeni Ürün Ekle",
      createDemand: "Talebi Oluştur",
      description: "Açıklama",
      uploadFile: "Dosya Seçiniz..",
      noMatchInTable: "Üzgünüz, Aramanızla eşleşen kayıt bulunamadı",
      productName: "Ürün Adı",
      demandItemId: "Talep Edilen Ürün Id",
      demandId: "Talep Id",
      estimatedAmount: "Tahmin Edilen Miktar",
      noPayment: "Ödemeniz alınmıştır",
      paymentSuccess:
        "Ödeme linkiniz başarıyla oluşturuldu gün sonuna kadar size gönderilen link ile ödeme yapabilirsiniz",
      buyerName: "Alıcı Adı",
      buyerSurname: "Alıcı Soyadı",
      makePayment: "Ödeme Yap",
      createPaymentLink: "Ödeme Linki Oluştur",
      processError: "İşlem sırasında bir sorun oluştu",
      successDemand: "Ürün başarıyla oluşturuldu",
      forgotPasswordEnterEmail:
        "Sisteme kayıtlı e-posta adresinizi alttaki alana girin. E-posta adresinize şifre yenileme bilgilerinizi göndereceğiz.",
      sendPassword: "Şifre Gönder",
      backToLogin: "Giriş'e Dön",
      registerProduct:"Ürün Kaydet  (+1 yıl ek garanti)",
      uploadInvoice:"Fatura ve diğer belgeleri sürükleyip bırakabilir veya seçmek için tıklayabilirsiniz."
    },
  ];

  //En 0
  //Tr 1

  if (localStorage.getItem("userLang") === null) {
    localStorage.setItem("userLang", 1);
  }

  const userLang = localStorage.getItem("userLang");

  let currentLang = userLang;

  return translatorNew[currentLang];
}

export default translate();
