import React from 'react';

function checkRole(text) {
  let userAuth = JSON.parse(localStorage.getItem('userAuth'));
  let userObj = userAuth.permissionSet;
  let userLastObj = JSON.parse(userObj);
  
  let roleAccessListDefault = {
    openNewOrder: true, // Yeni İş emri açabilme
    workOrderButton: true, // İş Emri Atama Butonu Görebilme
    detailPageConversation: true, // Detay sayfasında Chat konuşmalarını görebilme
    addressContactUpdate: false, // Profil Bilgileri update edebilme
    operationFailedButton: true, // İşlem tamamlanamadı Butonu görebilme
    canSeeDeviceDetails: true,
    canSeeDemandManagement: true,
    canSeeCompanyClaims: true,
    canSeePaymentButton: true,
    canSeeDetectedFault:true,
    canSeeFiles:true
  };

  userLastObj.forEach((item) => {
    if(item.IsSelected === 1) {
      roleAccessListDefault[item.PermissionName] = true
    }else if(item.IsSelected === 0) {
      roleAccessListDefault[item.PermissionName] = false
    }
  });

  /*
  const roleAccessCustom = {
    openNewOrder: false,
    workOrderButton: false,
    detailPageConversation: false,
    addressContactUpdate: false,
    operationFailedButton: false,
  };
  const roleAccessOne = {
    openNewOrder: false,
    workOrderButton: false,
    detailPageConversation: false,
    addressContactUpdate: false,
    operationFailedButton: false,
  };
  const roleAccessTwo = {
    openNewOrder: false,
    workOrderButton: false,
    detailPageConversation: true,
    addressContactUpdate: false,
    operationFailedButton: false,
  };
  const roleAccessThree = {
    workOrderButton: false,
    openNewOrder: false,
    detailPageConversation: true,
    addressContactUpdate: false,
    operationFailedButton: false,
  };
  const roleAccessFour = {
    workOrderButton: true,
    openNewOrder: true,
    detailPageConversation: true,
    addressContactUpdate: false,
    operationFailedButton: false,
  };
  const roleAccessFive = {
    workOrderButton: false,
    openNewOrder: false,
    detailPageConversation: true,
    addressContactUpdate: true,
    operationFailedButton: false,
  };
  const roleAccessSix = {
    workOrderButton: false,
    openNewOrder: false,
    detailPageConversation: false,
    addressContactUpdate: false,
    operationFailedButton: false,
  };
  //const BusyContext = React.createContext(false);
  let roleID = userAuth.roleID;
  let workOrderButton = userAuth.workOrderButton;
  let openNewOrder = userAuth.openNewOrder;
  let detailPageConversation = userAuth.detailPageConversation;
  let addressContactUpdate = userAuth.addressContactUpdate;
  let operationFailedButton = userAuth.operationFailedButton;
  
  if (roleID === '0') {
    roleAccessCustom = {
      openNewOrder: openNewOrder,
      workOrderButton: workOrderButton,
      detailPageConversation: detailPageConversation,
      addressContactUpdate: addressContactUpdate,
      operationFailedButton: operationFailedButton,
    };
  } else if (roleID === '1') {
    roleAccessListDefault = roleAccessOne;
  } else if (roleID === '2') {
    roleAccessListDefault = roleAccessTwo;
  } else if (roleID === '3') {
    roleAccessListDefault = roleAccessThree;
  } else if (roleID === '4') {
    //   roleAccessListDefault = roleAccessFour;
  } else if (roleID === '5') {
    roleAccessListDefault = roleAccessFive;
  } else if (roleID === '6') {
    roleAccessListDefault = roleAccessSix;
  }
  if (workOrderButton != null) {
    roleAccessCustom.workOrderButton = workOrderButton;
  }
  if (openNewOrder != null) {
    roleAccessCustom.openNewOrder = openNewOrder;
  }
  if (detailPageConversation != null) {
    roleAccessCustom.detailPageConversation = detailPageConversation;
  }
  if (addressContactUpdate != null) {
    roleAccessCustom.addressContactUpdate = addressContactUpdate;
  }
  if (operationFailedButton != null) {
    roleAccessCustom.operationFailedButton = operationFailedButton;
  }
*/
  return roleAccessListDefault;
}

export default checkRole;
