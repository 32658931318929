import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import MUIDataTable from "mui-datatables";
import { IconButton } from "@material-ui/core";
import React from "react";
import { Digital } from "react-activity";
import "react-activity/dist/react-activity.css";
import Modal from "react-modal";
import { Button, Card, Container, Row } from "reactstrap";
import translator from "../../actions/translator";
import userRole from "../../actions/userRole";
import { apiActions } from "../../apiCall/api.services";
import Header from "../../components/Headers/Header.jsx";
import CreatePdf from "../../components/createPdf";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { logoutUser } from "../../actions/authActions";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0",
    backgroundColor: "transparent",
    zIndex: "130",
  },
};

class PastOrders extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem("userAuth"));
    this.state = {
      testVal: "",
      checktch: false,
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      getActiveOrdersOptions: [],
      loadingAnimate: true,
      modalIsOpen: true,
      pdfData: [],
      pdfHeaders: [],
      isPdfVisible: false,
    };

    this.getActiveOrders = this.getActiveOrders.bind(this);
    this.getOrderDetails = this.getOrderDetails.bind(this);
    this.postReOpenOrder = this.postReOpenOrder.bind(this);
    this.removeSeriNoOpen = this.removeSeriNoOpen.bind(this);
  }

  componentDidMount() {
    const urlLocation = window.location.hostname;
    let checktch = urlLocation === "tchibodestek.servissoft.net";
    this.setState({ checktch });
    this.getActiveOrders();
    this.removeSeriNoOpen();
  }

  removeSeriNoOpen() {
    localStorage.removeItem("seriNoOpen");
  }

  getOrderDetails(getOrderID) {
    localStorage.setItem("orderDetails", JSON.stringify(getOrderID));
    this.props.history.push("/admin/order-details");
  }

  getActiveOrders() {
    let currentComponent = this;
    apiActions
      .pastOrdersByCustomer(
        this.state.userAuthOptions.accessToken,
        this.state.userAuthOptions.clientID,
        this.state.userAuthOptions.apiKey
      )
      .then((res) => {
        currentComponent.setState({ loadingAnimate: false });
        currentComponent.setState({ modalIsOpen: false });
        res.data.data.map((item) => {
          item.InsertDate = item.InsertDate
            ? Intl.DateTimeFormat("tr-TR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }).format(Date.parse(item.InsertDate))
            : "Tarih Bulunamadı";
          item.FinishDate = item.FinishDate
            ? Intl.DateTimeFormat("tr-TR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
              }).format(Date.parse(item.FinishDate))
            : "Tarih Bulunamadı";
        });
        currentComponent.setState({ getActiveOrdersOptions: res.data.data });
      })
      .catch((error) => {
        console.log("error", error.response.status);
        error.response.status == 401 && logoutUser();
      });
  }

  postReOpenOrder(JobOrderID) {
    let currentComponent = this;
    let postReOpenOrderPromise = apiActions.postReOpenOrder(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      JobOrderID
    );
    postReOpenOrderPromise.then();
  }

  createPdfForDownload = () => {
    this.setState({ isPdfVisible: true });
    console.log("pdfData", this.state.pdfData);

    console.log("pdfHeaders", this.state.pdfHeaders);
  };

  handleClosePdf = () => {
    this.setState({ isPdfVisible: false });
  };

  render() {
    const { getActiveOrdersOptions } = this.state;
    const columns = [
      {
        name: "ReferenceID",
        field: "ReferenceID",
        label: translator.referenceID,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "FullName",
        field: "FullName",
        label: translator.customerName,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "FullDeviceName",
        field: "FullDeviceName",
        label: translator.fullDeviceName,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "StateText",
        field: "StateText",
        label: translator.status,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "",
        // field: "PassedTime",
        options: {
          filter: true,
          sort: true,
        },
      },
      //Passed time kaldırıldı.ColumnIndex kontrolleri için boş bir column bırakıldı
      {
        name: "InsertDate",
        field: "InsertDate",
        label: translator.insertDate,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "FinishDate",
        field: "FinishDate",
        label: translator.finishDate,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "JobOrderID",
        field: "JobOrderID",
        label: translator.jobStatus,
        options: {
          filter: true,
          sort: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <Button
              className="m-1 tableButtonStyle"
              color="primary"
              value={value}
              type="button"
            >
              {this.state.checktch
                ? translator.jobFailedTch
                : translator.jobFailed}
            </Button>
          ),
        },
      },
      {
        name: "JobOrderID",
        field: "JobOrderID",
        label: translator.supportDetail,
        options: {
          display: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <Button
              className="m-1 tableButtonStyle"
              color="primary"
              value={value}
              type="button"
            >
              {translator.detail}
            </Button>
          ),
        },
      },
    ];

    const options = {
      filterType: "dropdown",
      downloadOptions: {
        filename: "excel-format.csv",
        separator: ";",
        filterOptions: {
          useDisplayedColumnsOnly: true,
          useDisplayedRowsOnly: true,
        },
      },
      onTableChange: (action, tableState) => {
        console.log("pdfData", this.state.pdfData);
        console.log("tableState", tableState.displayData);
        if (tableState.displayData.length > 0) {
          if (this.state.pdfData.length < 1) {
            this.setState({ pdfHeaders: tableState.columns });
            this.setState({ pdfData: tableState.displayData });
          } else if (
            this.state.pdfData[0].data[0] !== tableState.displayData[0].data[0]
          ) {
            console.log("tableState Array", tableState.columns);

            console.log("tableState 1", this.state.pdfData[0].data[0]);
            console.log("tableState 2", tableState.displayData[0].data[0]);
            this.setState({ pdfHeaders: tableState.columns });
            this.setState({ pdfData: tableState.displayData });
          }
        }
      },
      customToolbar: () => (
        <>
          <span>
            <IconButton
              aria-label="delete"
              onClick={() => {
                this.createPdfForDownload();
              }}
            >
              <PictureAsPdfIcon />
            </IconButton>
          </span>
        </>
      ),
      onDownload: (buildHead, buildBody, columns, data) => {
        return "\uFEFF" + buildHead(columns) + buildBody(data);
      },
      jsonMode: true,
      onCellClick: (cellIndex, rowIndex) => {
        if (userRole().operationFailedButton === false) {
          if (rowIndex.colIndex === 7) {
            if (cellIndex.props.value !== undefined) {
              this.getOrderDetails(cellIndex.props.value);
            }
          }
        } else {
          if (rowIndex.colIndex === 8) {
            if (cellIndex.props.value !== undefined) {
              this.getOrderDetails(cellIndex.props.value);
            }
          } else if (rowIndex.colIndex === 7) {
            if (cellIndex.props.value !== undefined) {
              this.postReOpenOrder(cellIndex.props.value);
            }
          }
        }
      },
    };

    if (userRole().operationFailedButton === false) {
      columns.splice(7, 1);
    }

    const userAuthUpdate = JSON.parse(localStorage.getItem("userAuth"));
    if (userAuthUpdate.isCustomerInfoUpdated === "1") {
      window.location.replace("/admin/user-profile");
    }

    return (
      <>
        <Header />
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <CreatePdf
              headersData={this.state.pdfHeaders}
              tableData={this.state.pdfData}
              isPdfVisible={this.state.isPdfVisible}
              handleClosePdf={this.handleClosePdf}
              columnSliceQty={2}
            />
            <div className="col">
              <Card className="shadow">
                <MuiThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    title={translator.pastOrders}
                    data={getActiveOrdersOptions}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          textAlign: "center",
          padding: "5px 10px",
          fontSize: "1.1vw",
        },
      },
      MUIDataTableBodyCell: {
        root: {
          textAlign: "center",
          padding: "20px 5px",
          fontSize: "1vw",
        },
      },
    },
  });

export default PastOrders;
