/* eslint-disable no-unused-vars */
import React from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Form,
  Input,
  FormGroup,
  Col,
  CardBody,
  CardTitle,
} from "reactstrap";
import { DropzoneArea } from "material-ui-dropzone";

// core components
import HeaderWithoutCards from "../../components/Headers/HeaderWithoutCards.jsx";
import Header from "../../components/Headers/Header.jsx";
import { apiActions } from "../../apiCall/api.services";
import PaymentModal from "../../components/PaymentModal";
import axios from "axios";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import videoButton from "../../assets/img/button_portal.png";

import "react-vertical-timeline-component/style.min.css";
import { If, Then, ElseIf, Else } from "react-if-elseif-else-render";
import Moment from "react-moment";
import ReactFancyBox from "react-fancybox";
import "react-fancybox/lib/fancybox.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import userRole from "../../actions/userRole";
import oktetApiCall from "../../apiCall/oktetApiCall";
import translator from "../../actions/translator";
import { Digital } from "react-activity";
import "react-activity/dist/react-activity.css";
import Modal from "react-modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    border: "0",
    backgroundColor: "transparent",
    zIndex: "130",
  },
};

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem("userAuth"));
    this.state = {
      testVal: "",
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      getActiveOrdersOptions: [],
      getOrdersDetailsOptions: [],
      detectedFaults: [],
      serviceLabourName: [],
      serviceOtherExpenses: [],
      serviceSparePieceExpenses: [],
      serviceTransportationExpenses: [],
      serviceFiles: [],
      ticketNote: "",
      files: [],
      existTicketNotes: [{ Text: "" }],
      canOpenOrder: "",
      loadingAnimate: true,
      modalIsOpen: true,
      paymentModal: false,
      pageUrlLocation: "",
      copied: false,
    };
    console.log(this.state.files, "files");
    this.getActiveOrders = this.getActiveOrders.bind(this);
    this.getOrdersDetails = this.getOrdersDetails.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getAddNotes = this.getAddNotes.bind(this);
    this.postUploadViaWeb = this.postUploadViaWeb.bind(this);
    this.getCustomerSummary = this.getCustomerSummary.bind(this);
    this.isPaymentModalOpen = this.isPaymentModalOpen.bind(this);
    this.checkPaymentStatus = this.checkPaymentStatus.bind(this);
  }

  getActiveOrders() {
    const orderID = JSON.parse(localStorage.getItem("orderDetails"));
    const currentComponent = this;
    const getActiveOrdersPromise = apiActions.getOrdersDetails(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      orderID
    );
    getActiveOrdersPromise.then(function (res) {
      currentComponent.setState({ getActiveOrdersOptions: res.data.data });
    });
  }

  getOrdersDetails() {
    const orderID = JSON.parse(localStorage.getItem("orderDetails"));
    const currentComponent = this;
    const getOrdersDetailsPromise = apiActions.getSingleOrderForPortal(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      orderID
    );
    getOrdersDetailsPromise.then(function (res) {
      console.log("getOrdersDetailsPromise", res);
      if (res.data.ServiceRecords.LabourExpenses.length > 0) {
        currentComponent.setState({
          serviceLabourName: res.data.ServiceRecords.LabourExpenses,
        });
      }

      if (res.data.ServiceRecords.OtherExpenses.length > 0) {
        currentComponent.setState({
          serviceOtherExpenses: res.data.ServiceRecords.OtherExpenses,
        });
      }

      if (res.data.ServiceRecords.SparePieceExpenses.length > 0) {
        currentComponent.setState({
          serviceSparePieceExpenses: res.data.ServiceRecords.SparePieceExpenses,
        });
      }

      if (res.data.ServiceRecords.TransportationExpenses.length > 0) {
        currentComponent.setState({
          serviceTransportationExpenses:
            res.data.ServiceRecords.TransportationExpenses,
        });
      }
      if (res.data.DetectedFaults.length > 0) {
        currentComponent.setState({ detectedFaults: res.data.DetectedFaults });
      }
      if (res.data.Files.length > 0) {
        currentComponent.setState({ serviceFiles: res.data.Files });
      }
      currentComponent.setState({ getOrdersDetailsOptions: res.data });
      currentComponent.setState({ loadingAnimate: false });
      currentComponent.setState({ modalIsOpen: false });
    });
  }

  getAddNotes(noteText) {
    let currentComponent = this;
    const orderID = JSON.parse(localStorage.getItem("orderDetails"));
    const getAddNotesPromise = apiActions.getAddNotes(
      currentComponent.state.userAuthOptions.accessToken,
      currentComponent.state.userAuthOptions.clientID,
      currentComponent.state.userAuthOptions.apiKey,
      orderID,
      noteText
    );
    getAddNotesPromise.then(function (res) {
      const options = {
        title: "Title",
        message: "Message",
        childrenElement: () => <div />,
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      };
      confirmAlert(options);
      confirmAlert({
        title: translator.congratulations,
        message: translator.yourMessageHasBeenSentSuccessfully,
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              window.location.reload();
            },
          },
        ],
      });

      currentComponent.getNotesData();
      currentComponent.setState({ ticketNote: "" });
      const alertOptions = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      };
      confirmAlert(alertOptions);
      confirmAlert({
        title: "Başarılı",
        message: "Yeni not ekledi",
        buttons: [
          {
            label: "Ok",
          },
        ],
      });
    });
  }

  postUploadViaWeb(files) {
    let currentComponent = this;
    const returnData = {
      JobOrderID: JSON.parse(localStorage.getItem("orderDetails")),
    };
    const postUploadViaWebPromise = apiActions.postUploadViaWeb(
      currentComponent.state.userAuthOptions.accessToken,
      currentComponent.state.userAuthOptions.apiKey,
      returnData,
      files
    );
    postUploadViaWebPromise.then(function (res) {
      currentComponent.setState({ files: [] });
      currentComponent.getOrdersDetails();
    });
  }

  getNotesData = () => {
    let currentComponent = this;
    const orderID = JSON.parse(localStorage.getItem("orderDetails"));
    const getNotesPromise = apiActions.getNotesForPortal(
      currentComponent.state.userAuthOptions.accessToken,
      currentComponent.state.userAuthOptions.apiKey,
      orderID
    );
    getNotesPromise.then((res) => {
      currentComponent.setState({ existTicketNotes: res.data.data });
    });
  };

  componentDidMount() {
    const urlLocation = window.location.hostname;
    let checktt = urlLocation === "servis.ttteknoloji.com.tr";
    //let checktt = true;

    if (checktt) {
      this.setState({ pageUrlLocation: "tt" });
    }
    this.checkShippingCode();
    this.getActiveOrders();
    this.getOrdersDetails();
    this.getCustomerSummary();
    this.getNotesData();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { ticketNote } = this.state;
    if (ticketNote) {
      this.getAddNotes(ticketNote);
    }
  }

  async handleSubmitFiles() {
    const { files } = this.state;
    if (files) {
      await this.postUploadViaWeb(files);
      this.setState({ files: [] });
      this.getOrdersDetails();
    }
  }

  handleSave = (files) => {
    const _self = this;
    _self.setState({ files }, () => {
      if (files && files.length > 0) {
        files.map(function (file, index) {
          _self.postUploadViaWeb(file);
        });
      }
    });
  };

  getCustomerSummary() {
    let currentComponent = this;
    let getCustomerSummaryPromise = apiActions.getCustomerSummary(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey
    );
    getCustomerSummaryPromise.then(function (res) {
      currentComponent.setState({
        canOpenOrder: res.data.CanOpenOrder,
      });
    });
  }

  checkShippingCode = () => {
    var xmlData =
      '<soap:Envelope xmlns:soap="http://www.w3.org/2003/05/soap-envelope" xmlns:tem="http://tempuri.org/"><soap:Header/><soap:Body><tem:KargoBilgileriByReferans><tem:pMusteriNo>524219442</tem:pMusteriNo><tem:pSifre>EHXFQSDJ</tem:pSifre><tem:pSiparisNo>SIPNO_8217_886660</tem:pSiparisNo><tem:pGonderiNo></tem:pGonderiNo><tem:pFaturaSeri></tem:pFaturaSeri><tem:pFaturaNo></tem:pFaturaNo><tem:pIrsaliyeNo></tem:pIrsaliyeNo><tem:pEFaturaNo></tem:pEFaturaNo><tem:pRaporType></tem:pRaporType></tem:KargoBilgileriByReferans></soap:Body></soap:Envelope>';

    axios
      .post(
        "http://service.mngkargo.com.tr/musterikargosiparis/musterikargosiparis.asmx",
        xmlData,
        {
          headers: {
            "Content-Type": "text/xml",
            "access-control-allow-origin": "*",
            SOAPAction: "http://tempuri.org/KargoBilgileriByReferans",
          },
        }
      )
      .then((res) => {
        console.log("AXIOS", res);
      })
      .catch((err) => {
        console.log("AXIOS ERR", err);
      });
  };

  checkPaymentStatus = () => {
    apiActions.checkPaymentStatus(this.state.userAuthOptions.accessToken);
  };

  isPaymentModalOpen(isModalOpen) {
    this.setState({ paymentModal: isModalOpen });
  }

  // getOrdersDetailsOptions.TotalCost - Ücret
  // getOrdersDetailsOptions.ReferenceID - order ID

  render() {
    const {
      getActiveOrdersOptions,
      getOrdersDetailsOptions,
      ticketNote,
      detectedFaults,
      serviceLabourName,
      serviceOtherExpenses,
      serviceSparePieceExpenses,
      serviceTransportationExpenses,
      serviceFiles,
      existTicketNotes,
    } = this.state;
    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          //onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Digital
            animating={this.state.loadingAnimate}
            color="#52BFE8"
            size={64}
            speed={1}
          />
        </Modal>

        {this.state.pageUrlLocation === "tt" ? (
          <HeaderWithoutCards />
        ) : (
          <Header />
        )}

        {/**
        * 
        * <Row style={{paddingBottom:20}}>
                <Col lg="6" xl="6" onClick={() => this.getActiveOrders()}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                          Kargo Gönderim Bilgileri
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                          {getOrdersDetailsOptions.IncomingCargoNumber && (<>
                         {getOrdersDetailsOptions.IncomingCargoName} :  {getOrdersDetailsOptions.IncomingCargoNumber}
                         </>
                          )}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i class="fas fa-plane-departure"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="6" onClick={() => this.getPastOrders()}>
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Gelen kargo bilgileri
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {getOrdersDetailsOptions.OutgoingCargoNumber && (<>
                          {getOrdersDetailsOptions.OutgoingCargoFirm} :  {getOrdersDetailsOptions.OutgoingCargoNumber}
                          </>
                          )}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                          <i class="fas fa-plane-arrival"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

        */}

        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}

          {this.state.pageUrlLocation === "tt" && (
            <Row style={{ paddingBottom: 20 }}>
              <Col lg="12" xl="12" onClick={() => this.getActiveOrders()}>
                <Card className="card-stats mb-4 mb-xl-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="h5"
                          className="text-uppercase text-muted mb-0"
                        >
                          Kargo Gönderim Bilgileri
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0">
                          <>
                            MNG KARGO{" "}
                            {/*  {getOrdersDetailsOptions.IncomingCargoName}*/}{" "}
                            : 560122545{" "}
                            {/*  {getOrdersDetailsOptions.IncomingCargoNumber} */}
                          </>
                          {/*  {getOrdersDetailsOptions.IncomingCargoNumber && (  )} */}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                          <i class="fas fa-plane-departure"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}

          <Row>
            <div className="col">
              <Card className="shadow">
                <CardHeader className="border-0">
                  <h3 className="mb-0">
                    {translator.referenceID}:{" "}
                    {getOrdersDetailsOptions.ReferenceID}
                  </h3>
                </CardHeader>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.status}
                      </th>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.detectedFault}
                      </th>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.insertDate}
                      </th>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.deviceInfo}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{getOrdersDetailsOptions.StateText}</td>
                      <td>
                        { userRole().canSeeDetectedFault ? detectedFaults.map((row) => " - " + row.FaultName): "-"}
                      </td>
                      <td>
                        <Moment format="HH:mm DD/MM/YYYY">
                          {getOrdersDetailsOptions.InsertDate}
                        </Moment>
                      </td>
                      <td>
                        {getOrdersDetailsOptions.BrandName}{" "}
                        {getOrdersDetailsOptions.DeviceTypeName}{" "}
                        {getOrdersDetailsOptions.DeviceName}{" "}
                        {getOrdersDetailsOptions.SerialNo}
                      </td>
                    </tr>
                  </tbody>
                </Table>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.complaint}
                      </th>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.descriptionOfTheTransaction}
                      </th>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.transactions}
                      </th>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.totalPrice}
                      </th>
                      <th scope="col" style={{ width: "25%" }}>
                        {translator.paymentStatus}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{getOrdersDetailsOptions.Description}</td>
                      <td>{getOrdersDetailsOptions.CurrentFullFilled}</td>
                      {userRole().canSeeCostDetails ? (
                        <td>
                          {serviceLabourName.map(
                            (row) =>
                              row.LabourName +
                              " - " +
                              row.Cost +
                              " - " +
                              row.Currency
                          )}
                          <br />
                          {serviceOtherExpenses.map(
                            (row) =>
                              row.Description +
                              " - ( " +
                              row.CurrencyCost +
                              " Adet ) - " +
                              row.Cost +
                              " - " +
                              row.Currency
                          )}
                          <br />
                          {serviceSparePieceExpenses.map(
                            (row) =>
                              row.ItemName +
                              " - ( " +
                              row.ActionCurrencyCost +
                              " Adet ) - " +
                              row.Cost +
                              " - " +
                              row.ActionCurrency
                          )}
                          <br />
                          {serviceTransportationExpenses.map((row) => (
                            <If condition={row.TransportationKind === 1}>
                              <Then>
                                {translator.onDirection} {row.Distance} KM -{" "}
                                {row.Cost}
                              </Then>
                              <Else>
                                {translator.doubleDirection} {row.Distance} KM -{" "}
                                {row.Cost}
                              </Else>
                            </If>
                          ))}
                        </td>
                      ) : (
                        <td>---</td>
                      )}
                      {userRole().canSeeCostDetails ? (
                        <td>
                          {getOrdersDetailsOptions.TotalCost -
                            getOrdersDetailsOptions.AlreadyPaid <=
                          0
                            ? 0
                            : getOrdersDetailsOptions.TotalCost}
                        </td>
                      ) : (
                        <td>---</td>
                      )}
                      {userRole().canSeeCostDetails ? (
                        <td>
                          {userRole().canSeePaymentButton ? (
                            getOrdersDetailsOptions.TotalCost -
                              getOrdersDetailsOptions.AlreadyPaid <=
                            0 ? (
                              getOrdersDetailsOptions.AlreadyPaid && (
                                <h5>{translator.noPayment}</h5>
                              )
                            ) : (
                              <div className="d-flex">
                                <button
                                  onClick={() =>
                                    this.setState({ paymentModal: true })
                                  }
                                  className="btn btn-primary"
                                >
                                  {translator.pay}
                                </button>
                                <button
                                  onClick={this.checkPaymentStatus}
                                  className="ml-3 btn btn-primary"
                                >
                                  Check Payment
                                </button>
                              </div>
                            )
                          ) : null}
                        </td>
                      ) : (
                        <td>---</td>
                      )}
                    </tr>
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <br />
          <br />
          <Row>
            {userRole().canSeeFiles ? (
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">Dosyalar</h3>
                  </CardHeader>
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">{translator.fileName}</th>
                        <th scope="col" />
                      </tr>
                    </thead>
                    <tbody>
                      {serviceFiles.map((row) => {
                        console.log("servise", row.FileName);
                        var str = row.FileName;
                        var isVideo = str.includes("mp4");

                        return (
                          <tr>
                            <th scope="row">
                              <Media className="align-items-center">
                                {isVideo ? (
                                  <>
                                    <a href={row.FileLocation} target="_blank">
                                      <img src={videoButton} width={40} />
                                    </a>
                                  </>
                                ) : (
                                  <div>
                                    <ReactFancyBox
                                      thumbnail={row.FileLocation}
                                      image={row.FileLocation}
                                    />
                                  </div>
                                )}

                                <Media>
                                  <span className="mb-0 text-sm ml-2">
                                    <a href={row.FileLocation}>
                                      {row.FileName}
                                    </a>
                                  </span>
                                </Media>
                              </Media>
                            </th>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Card>
              </div>
            ) : (
              <td>---</td>
            )}
            =
            {userRole().canSeeNotes ? (
              <div className="col">
                <Card className="shadow">
                  <CardHeader className="border-0">
                    <h3 className="mb-0">{translator.ticket}</h3>
                  </CardHeader>
                  {getOrdersDetailsOptions.Status === 1 && (
                    <>
                      <Form role="form" onSubmit={this.handleSubmit}>
                        <Col lg="12">
                          <Input
                            type="textarea"
                            rows="5"
                            id="ticketNote"
                            name="ticketNote"
                            className="form-control-alternative"
                            placeholder="Ticket Notunuzu Giriniz"
                            value={ticketNote}
                            onChange={this.handleChange}
                          />
                        </Col>
                        <br />
                        <Col lg="6">
                          <button className="btn btn-primary">
                            {translator.send}
                          </button>
                        </Col>
                        <br />
                      </Form>
                      <Col lg="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            {translator.files}
                          </label>
                          <DropzoneArea
                            className="form-control-alternative"
                            maxFileSize={5000000}
                            filesLimit={10}
                            acceptedFiles={[
                              "image/jpeg",
                              "image/png",
                              "image/bmp",
                              "application/pdf",
                              "application/vnd.ms-excel	"
                            ]}
                            initialFiles={this.state.files}
                            showAlerts={true}
                            dropzoneText={
                              translator.dragAndDropAnImageFileHereOrClick
                            }
                            onChange={this.handleSave}
                            
                          />
                        </FormGroup>
                      </Col>
                    </>
                  )}
                </Card>
              </div>
            ) : (
              <div>---</div>
            )}
          </Row>
          {userRole().detailPageConversation ? (
            <div className="col">
              <VerticalTimeline>
                {getActiveOrdersOptions.map((row) => (
                  <If condition={row.SentBy === "Customer"}>
                    <Then>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date={
                          <Moment format="HH:mm DD/MM/YYYY">
                            {row.InsertDate}
                          </Moment>
                        }
                        position="right"
                        iconStyle={{
                          background: "rgb(33, 150, 243)",
                          color: "#fff",
                        }}
                      >
                        <h3 className="vertical-timeline-element-title">
                          {row.CustomerName}
                        </h3>
                        <p>{row.Text}</p>
                      </VerticalTimelineElement>
                    </Then>
                    <ElseIf condition={row.SentBy === "Staff"}>
                      <VerticalTimelineElement
                        className="vertical-timeline-element--work"
                        date={
                          <Moment format="HH:mm DD/MM/YYYY">
                            {row.InsertDate}
                          </Moment>
                        }
                        position="left"
                        iconStyle={{
                          background: "rgb(33, 150, 243)",
                          color: "#fff",
                        }}
                      >
                        <h3 className="vertical-timeline-element-title">
                          {row.StaffName}
                        </h3>
                        <p>{row.Text}</p>
                      </VerticalTimelineElement>
                    </ElseIf>
                  </If>
                ))}
              </VerticalTimeline>
            </div>
          ) : (
            "---"
          )}

          {/* <Col lg="6">
            <button
              onClick={() => this.handleSubmitFiles()}
              className="btn btn-primary mb-3"
            >
              {translator.send}
            </button>
          </Col> */}
          <PaymentModal
            jobOrderId={getOrdersDetailsOptions.JobOrderID}
            totalCost={getOrdersDetailsOptions.TotalCost}
            paymentModal={this.state.paymentModal}
            isModalOpen={this.isPaymentModalOpen}
          />
        </Container>
      </>
    );
  }
}

export default OrderDetails;
