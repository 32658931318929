import { Box, Fab } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import {
  Document,
  Font,
  Image,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import fontRoboto from "../../assets/fonts/Roboto.ttf";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const styles = StyleSheet.create({
  // page: {
  // 	flexDirection: 'row',
  // 	backgroundColor: '#E4E4E4'
  // },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Roboto",
  },
  hr: {
    backgroundColor: "black",
    height: 1,
    width: "100%",
    marginTop: 10,
    marginBottom: 10,
  },
  formRow: {
    padding: 5,
    marginHorizontal: 30,
  },
  border: { borderColor: "black", borderWidth: 1, borderStyle: "solid" },
  h6: {
    fontWeight: 500,
    fontSize: 12,
    lineHeight: 1.6,
    letterSpacing: 0.12,
  },
  body1: {
    fontWeight: 400,
    fontSize: 8,
    lineHeight: 1.5,
    letterSpacing: 0.15008,
  },
  footerType: {
    fontWeight: 400,
    fontSize: 6,
    lineHeight: 1.5,
    letterSpacing: 0.15008,
  },
  tableCell: {
    width: "25%",
    paddingLeft: 5,
    paddingTop: 5,
  },
  flexRow: { display: "flex", flexDirection: "row" },
  flexGrow1: { flexGrow: 1, paddingLeft: 5, paddingVertical: 5 },
  flexGrow2: { flexGrow: 2, paddingLeft: 5, paddingVertical: 5 },
});

Font.register({
  family: "Roboto",
  src: fontRoboto,
});

const CreatePdf = ({
  headersData,
  tableData,
  isPdfVisible,
  handleClosePdf,
  columnSliceQty,
}) => {
  const classes = useStyles();

  const [pdfReady, setPdfReady] = useState(false);
  const [textData, setTextData] = useState("TEST");
  // const handleOpen = () => {
  // 	setModalOpen(true);
  // };

  const handleClose = () => {
    handleClosePdf();
  };

  useEffect(() => {
    setPdfReady(false);
    setTimeout(() => {
      setPdfReady(true);
    }, 1);
  }, []);

  const MyDocument = (
    <Document>
      <Page size="A4" style={styles.body}>
        <View style={[{ justifyContent: "space-between" }, styles.flexRow]}>
          {headersData.map((header, index) => {
            const calcWidth = 100 / headersData.length - columnSliceQty;
            return (
              <>
                {index < headersData.length - columnSliceQty && (
                  <>
                    <View
                      style={{ width: `${calcWidth}%`, textAlign: "center" }}
                    >
                      <Text style={([{ fontWeight: "bold" }], styles.body1)}>
                        {header.label}
                      </Text>
                    </View>
                  </>
                )}
              </>
            );
          })}
        </View>
        <View style={styles.hr} />
        {tableData.map((tData, index) => {
          const calcWidth = 100 / headersData.length - columnSliceQty;
          return (
            <>
              <View
                style={[{ justifyContent: "space-between" }, styles.flexRow]}
              >
                {tData.data.map((value, index) => {
                  return (
                    <>
                      {index < tData.data.length - columnSliceQty && (
                        <View
                          style={{
                            width: `${calcWidth}%`,
                            textAlign: "center",
                          }}
                        >
                          <Text style={styles.body1}>{value}</Text>
                        </View>
                      )}
                    </>
                  );
                })}
              </View>
              <View style={styles.hr} />
            </>
          );
        })}
      </Page>
    </Document>
  );

  return (
    <Modal
      aria-labelledby="spring-modal-pdf"
      aria-describedby="spring-modal-ticket-info"
      className={classes.modal}
      open={isPdfVisible}
      onClose={handleClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 1000,
      }}
    >
      <Box width="100%" height="100%">
        <Box position="absolute" right={48} top={55}>
          <Fab size="small" aria-label="close form" onClick={handleClose}>
            <CloseIcon />
          </Fab>
        </Box>
        {pdfReady && (
          <PDFViewer children={MyDocument} width="100%" height="100%" />
        )}
      </Box>
    </Modal>
  );
};

export default CreatePdf;
