/*!

=========================================================
* Argon Dashboard React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { apiActions } from '../../apiCall/api.services';

// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from 'reactstrap';
import translator from '../../actions/translator';

class ClaimHeader extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      openOrderCount: '',
      fullName: '',
      firmName: '',
      closedOrderCount: '',
    };

    this.getCustomerSummary = this.getCustomerSummary.bind(this);
  }

  getCustomerSummary() {
    let currentComponent = this;
    let getCustomerSummaryPromise = apiActions.getCustomerSummary(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
    );
    getCustomerSummaryPromise.then(function(res) {
      currentComponent.setState({
        openOrderCount: res.data.OpenOrderCount,
        closedOrderCount: res.data.ClosedOrderCount,
        fullName: res.data.FullName,
      });
    });
  }

  componentDidMount() {
    this.getCustomerSummary();
  }

  render() {
    return (
      <>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="6" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {translator.activeOrders}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.openOrderCount}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-chart-bar" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6" xl="6">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {translator.pastOrders}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.closedOrderCount}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-chart-pie" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default ClaimHeader;
