
export const formatDate = (date = new Date()) => {
    let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

export const generateHexSha1Key = (securityKeyString) => {
    const crypto = require('crypto');
    const shasum = crypto.createHash('sha1');
    shasum.update(securityKeyString);
    return shasum.digest('hex');
}