import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { formatDate, generateHexSha1Key } from "../components/helperMethod";
import translator from "../actions/translator";
export const apiActions = {
  onLoadApi,
  getAuthenticationApi,
  getCustomerSummary,
  getDeviceTypesByCustomer,
  getBrandListByDeviceType,
  getModelListByCustomer,
  getModelList,
  getDevicesByCustomerID,
  postJobOrderAddOrder,
  getOrdersByCustomer,
  pastOrdersByCustomer,
  getDevicesOfCustomer,
  getOrdersDetails,
  getNotesForPortal,
  getSingleOrderForPortal,
  getAddNotes,
  getUploadFiles,
  getCountries,
  getProviences,
  getDistricts,
  getStreetorVillages,
  postUpdateCustomer,
  getResendPassword,
  getSingle,
  postAddCustomer,
  getSearchSerialNoByCustomer,
  postReOpenOrder,
  postUploadViaWeb,
  getClaimList,
  getClaimExpenses,
  postClaimStatus,
  finalizeClaim,
  getOtherCustomersOfCompanyByCustomer,
  getBrandList,
  getPageDefinitions,
  getPortalDemands,
  getCustomerInfo,
  getPortalDemandsItem,
  postAddDemandsAndItems,
  createPaymentLink,
  checkPaymentStatus,
  scanBarcode,
  postRegisterProduct,
  SearchSerialNoExact,
  BilkomWarrantyCodeActivation
};

// https://localhost:13759/0/DemandManagement/PortalGetDemands
//TÇ -Cloudda localhost kullan !!
//var apiURL = 'http://localhost:13759/v1';

// TÇ - Localde url kullan !!
var apiURL = "https://api.servissoft.net/v1";

function onLoadApi() {
  var getCurrentURL = window.location.hostname;
  console.log(getCurrentURL);
  // if (getCurrentURL == "localhost") {
  //   getCurrentURL = "http://portal.servissoft.net";
  // }

  //localStorage.setItem('userGetURL', getCurrentURL);
  //fetch('https://api.servissoft.net/v1/Company/GetCompanyDetails?url=' + getCurrentURL) yayına alırken bu aktif olacak satır 44 pasif olacak

  return fetch(apiURL + "/Company/GetCompanyDetails?url=" + getCurrentURL)
    .then((res) => res.json())
    .then(function (result) {
      document.title = result.data[1];
      const favicon = document.getElementById("favicon");
      favicon.href = result.data[3];
      return result;
    });
}

function getAuthenticationApi(clientID, clientSecret) {
  const url = "https://api.servissoft.net/CustomerToken?";
  const requestBody =
    "grant_type=password&clientID=" +
    clientID +
    "&clientSecret=" +
    clientSecret;

  return axios
    .post(url, requestBody)
    .then((result) => {
      return result.data;
    })
    .catch((err) => {
      console.log(err);
    });
}

async function getCustomerSummary(Auth, clientID, apiKey) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
    },
  };

  const response = await axios.get(
    "https://api.servissoft.net/v1/Customer/GetCustomerSummary",
    config
  );

  return response;
}

function getDeviceTypesByCustomer(Auth, clientID, apiKey, brandID) {
  let getBrandId = brandID ? brandID.toString() : "";
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      brandID: getBrandId,
      term: "",
    },
  };
  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetDeviceTypesByCustomer",
    config
  );
}

function getOtherCustomersOfCompanyByCustomer(
  Auth,
  customerOfCustomerID,
  apiKey,
  term
) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      term: term,
      customer: customerOfCustomerID,
      apiKey: apiKey,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Customer/GetOtherCustomersOfCompanyByCustomer",
    config
  );
}

function getBrandListByDeviceType(Auth, clientID, apiKey, deviceTypeID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      deviceTypeID: deviceTypeID,
      term: "",
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetBrandListByDeviceType",
    config
  );
}

function getPageDefinitions(Auth, apiKey) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      groupID: 22,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetPageDefinitions_Simple",
    config
  );
}

function getBrandList(Auth, clientID, apiKey) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      term: "",
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetBrandList",
    config
  );
}

function getModelList(Auth, apiKey, clientID, brandID, deviceTypeID, term) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      deviceTypeID: deviceTypeID,
      brandID: brandID,
      term: term,
    },
  };
  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetModelListNoLimit",
    config
  );
}

function getModelListByCustomer(Auth, clientID, apiKey, deviceTypeID, brandID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      deviceTypeID: deviceTypeID,
      brandID: brandID,
      term: "",
    },
  };
  console.log(config.params);
  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetModelListByCustomer",
    config
  );
}

function getDevicesByCustomerID(Auth, clientID, apiKey, deviceModelID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      deviceModelID: deviceModelID,
      term: "",
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Device/GetDevicesByCustomerID",
    config
  );
}

function postJobOrderAddOrder(
  Auth,
  clientID,
  apiKey,
  deviceType,
  deviceBrand,
  deviceModel,
  serialNumber,
  deviceDescription,
  deviceNote,
  deviceFile,
  customerOfCustomerID,
  serviceType
) {
  const url = "https://api.servissoft.net/v1/JobOrder/AddOrder";
  const config = {
    params: {
      customer: clientID,
      apiKey: apiKey,
      DeviceTypeID: deviceType,
      DeviceBrandID: deviceBrand,
      DeviceModelID: deviceModel,
      Description: deviceDescription,
      Note: deviceNote,
      SerialNo: serialNumber,
      CreatedBy: clientID,
      Source: 4,
      jobOrderFiles: deviceFile,
      CustomerOfCustomerID: customerOfCustomerID,
      serviceType: serviceType,
    },
  };
  console.log(config.params);
  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => {
      /*
      const options = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => {},
        onKeypressEscape: () => {},
      };
      confirmAlert(options);
//Ürününüzü MNG Kargo 560122545 anlaşma kodu ile teknik servisimize gönderebilirsiniz.
      confirmAlert({
        title: `Referans No : ${result.data.ReferenceID}`,
        message: "Servis kaydınız gönderilmiştir",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              window.location.replace("/admin/active-orders");
            },
          },
        ],
      });
      */
      localStorage.removeItem("seriNoOpen");

      deviceFile.map(function (file, index) {
        var orderResult = { "JobOrderID": result.data.JobOrderID };
        postUploadViaWeb(Auth, apiKey, orderResult, file);
      });

      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}

function getOrdersByCustomer(Auth, clientID, apiKey) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      type: 1,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/JobOrder/GetOrdersByCustomer",
    config
  );
}

function pastOrdersByCustomer(Auth, clientID, apiKey) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      type: 0,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/JobOrder/GetOrdersByCustomer",
    config
  );
}

async function getDevicesOfCustomer(
  Auth,
  clientID,
  apiKey,
  startCount,
  finishCount
) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      id: clientID,
      apiKey: apiKey,
      iDisplayStart: 0,
      iDisplayLength: 500,
    },
  };

  const response = await axios.get(
    "https://api.servissoft.net/v1/Customer/GetDevicesOfCustomer",
    config
  );
  return response;
}

function getOrdersDetails(Auth, clientID, apiKey, orderID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      customer: clientID,
      apiKey: apiKey,
      orderID: orderID,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/JobOrder/GetOrderNotesForPortal",
    config
  );
}

function getSingleOrderForPortal(Auth, clientID, apiKey, orderID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      apiKey: apiKey,
      customer: clientID,
      orderID: orderID,
      type: 1,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/JobOrder/GetSingleOrderForPortal",
    config
  );
}

function getNotesForPortal(Auth, apiKey, orderID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      apiKey: apiKey,
      orderID: orderID,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/JobOrder/GetOrderNotesForPortal",
    config
  );
}

function getAddNotes(Auth, clientID, apiKey, orderID, noteText) {
  const url = "https://api.servissoft.net/v1/JobOrder/AddNote";
  const config = {
    params: {
      apiKey: apiKey,
      customer: clientID,
      JobOrderID: orderID,
      type: 3,
      Text: noteText,
    },
  };

  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => { })
    .catch((err) => {
      console.log(err);
    });
}

function getUploadFiles(Auth, apiKey, orderID, files) {
  const url = "https://api.servissoft.net/v1/Upload/UploadFile";
  const config = {
    params: {
      apiKey: apiKey,
      operationName: "jobOrderFile",
      relatedID: orderID,
      File: files,
    },
  };

  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => { })
    .catch((err) => {
      console.log(err);
    });
}

//function getCountries(Auth,apiKey){
function getCountries() {
  const config = {
    headers: {
      "Content-Type": "application/json",
      //'Authorization': 'Bearer ' + Auth,
      Authorization: "Bearer ",
    },
    params: {
      //'apiKey':apiKey,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetCountries",
    config
  );
}

//function getProviences(Auth,apiKey){
function getProviences() {
  const config = {
    headers: {
      "Content-Type": "application/json",
      //'Authorization': 'Bearer ' + Auth,
      Authorization: "Bearer ",
    },
    params: {
      //'apiKey':apiKey,
      countryID: 1,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetProvinces",
    config
  );
}

//function getDistricts(Auth,apiKey,ProvinceID){
function getDistricts(ProvinceID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      //'Authorization': 'Bearer ' + Auth,
      Authorization: "Bearer ",
    },
    params: {
      //'apiKey':apiKey,
      provinceID: ProvinceID,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetDistricts",
    config
  );
}

//function getStreetorVillages(Auth,apiKey,DistrictID){
function getStreetorVillages(DistrictID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      //'Authorization': 'Bearer ' + Auth,
      Authorization: "Bearer ",
    },
    params: {
      //'apiKey':apiKey,
      districtID: DistrictID,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Definitions/GetStreetOrVillages",
    config
  );
}

function postUpdateCustomer(
  Auth,
  clientID,
  apiKey,
  userName,
  userLastName,
  userPhone,
  userEmail,
  allAddressData
) {
  const url = "https://api.servissoft.net/v1/Customer/UpdateCustomer";
  const config = {
    params: {
      apiKey: apiKey,
      CustomerID: clientID,
      Name: userName,
      Surname: userLastName,
      GsmNo: userPhone,
      Email: userEmail,
      Address: allAddressData.addressInfo,
      AddressDescription: allAddressData.direction,
      Province: allAddressData.province.label,
      ProvinceID: allAddressData.province.value,
      District: allAddressData.district.label,
      StreetOrVillage: allAddressData.street.label,
      StreetOrVillageID: allAddressData.street.value,
      DistrictID: allAddressData.district.value,
      IsCustomerInfoUpdated: 0,
      customerType: 1,
    },
  };
  console.log("config TOLGA", config);
  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => {
      let userAuthUpdate = JSON.parse(localStorage.getItem("userAuth"));
      userAuthUpdate.isCustomerInfoUpdated = 1;
      localStorage.setItem("userAuth", JSON.stringify(userAuthUpdate));
      const options = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => { },
        onKeypressEscape: () => { },
      };
      confirmAlert(options);
      confirmAlert({
        title: "Tebrikler",
        message: "Güncellemeniz başarılı bir şekilde yapılmıştır",
        buttons: [
          {
            label: "Ok",
            onClick: () => {
              window.location.replace("/admin/index");
            },
          },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
    });
}

//function postAddCustomer(Auth,clientID,apiKey,userName,userLastName,userPhone,userEmail,userCity,userDisctrict,userRegion,userStreet,userAddress){
function postAddCustomer(
  userName,
  userLastName,
  userPhone,
  userEmail,
  allAddressData,
  emailAccepted,
  smsAccepted,
  callAccepted
) {
  const url = "https://api.servissoft.net/v1/Customer/AddCustomerForPortal";
  console.log(emailAccepted);
  var getCurrent = window.location.hostname;
  const config = {
    params: {
      Name: userName,
      Surname: userLastName,
      GsmNo: userPhone,
      Email: userEmail,
      Address: allAddressData.addressInfo,
      AddressDescription: allAddressData.direction,
      Province: allAddressData.province.label,
      ProvinceID: allAddressData.province.value,
      District: allAddressData.district.label,
      StreetOrVillageID: allAddressData.street.value,
      StreetOrVillage: allAddressData.street.label,
      DistrictID: allAddressData.district.value,
      PortalURL: getCurrent,
      //PortalURL: 'http://portal.servissoft.net',
      IsCustomerInfoUpdated: 1,
      CustomerType: 1,
      Source: 4,
      smsAccepted: smsAccepted,
      emailAccepted: emailAccepted,
      callAccepted: callAccepted
    },
  };

  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer ",
      },
    })
    .then((result) => {
      if (result.data.data !== null) {
        confirmAlert({
          closeOnClickOutside: false,
          title: "Tebrikler",
          message: "Kayıt işleminiz başarılı bir şekilde yapılmıştır",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                window.location.replace("/auth/login");
              },
            },
          ],
        });
      } else {
        const options = {
          title: "Title",
          message: "Message",
          closeOnEscape: true,
          closeOnClickOutside: true,
          onClickOutside: () => { },
          onKeypressEscape: () => { },
        };
        confirmAlert(options);
        confirmAlert({
          title: "Hata",
          message: result.data.error,
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function getResendPassword(email, portalUrl) {
  const url = "https://api.servissoft.net/v1/Customer/ResendPassword";
  const config = {
    params: {
      Email: email,
      PortalUrl: portalUrl,
    },
  };

  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer ",
      },
    })
    .then((result) => {
      if (result.data === "OK") {
        const options = {
          title: "Title",
          message: "Message",
          closeOnEscape: true,
          closeOnClickOutside: true,
          onClickOutside: () => { },
          onKeypressEscape: () => { },
        };
        confirmAlert(options);
        confirmAlert({
          title: "Tebrikler",
          message: "Şifreniz email adresinize gönderilmiştir",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                window.location.replace("/auth/login");
              },
            },
          ],
        });
      } else {
        const options = {
          title: "Title",
          message: "Message",
          closeOnEscape: true,
          closeOnClickOutside: true,
          onClickOutside: () => { },
          onKeypressEscape: () => { },
        };
        confirmAlert(options);
        confirmAlert({
          title: "Hata",
          message: "Email adresi yanlış",
          buttons: [
            {
              label: "Ok",
            },
          ],
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function getSingle(Auth, apiKey, clientID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      apiKey: apiKey,
      id: clientID,
    },
  };

  return axios.get("https://api.servissoft.net/v1/Customer/GetSingle", config);
}

function getSearchSerialNoByCustomer(Auth, apiKey, serialNo) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      apiKey: apiKey,
      serialNo: serialNo,
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/Device/SearchSerialNoByCustomer",
    config
  );
}
function scanBarcode(Auth, apiKey, serialNo) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      apiKey: apiKey,
      serialNo: serialNo,
    },
  };

  return axios.get("https://api.servissoft.net/v1/Device/ScanBarcode", config);
}

function postReOpenOrder(Auth, clientID, apiKey, JobOrderID) {
  const url = "https://api.servissoft.net/v1/JobOrder/ReOpenOrder";
  const config = {
    params: {
      apiKey: apiKey,
      CustomerID: clientID,
      JobOrderID: JobOrderID,
    },
  };
  console.log("config", config);
  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => {
      console.log(result.data.data.Result);
      const options = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => { },
        onKeypressEscape: () => { },
      };
      confirmAlert(options);
      if (result.data.data.Result === false) {
        confirmAlert({
          title: translator.processError,
          message: result.data.error,
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                
              },
            },
          ],
        });
      } else {
        confirmAlert({
          title: "Tebrikler",
          message: "İş emri başarıyla açıldı",
          buttons: [
            {
              label: "Ok",
              onClick: () => {
                window.location.reload();
              },
            },
          ],
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
}

function postUploadViaWeb(Auth, apiKey, returnData, uploadFiles, operationName) {
  console.log(returnData);
  const url = "https://api.servissoft.net/v1/Upload/UploadViaWeb";
  //const url = 'https://api.imgur.com/3/image';
  console.log(uploadFiles, "uploadFiles");
  const formData = new FormData();
  formData.append("relatedID", returnData.JobOrderID);
  formData.append("apiKey", apiKey);
  formData.append("operationName", (operationName == null ? "jobOrderFile" : operationName));
  //formData.append('qqfile', uploadFiles);
  formData.append("qqfile", uploadFiles);
  console.log(uploadFiles);
  const config = {
    params: {
      apiKey: apiKey,
      relatedID: 1292606,
      operationName: operationName == null ? "jobOrderFile" : operationName,
    },
  };

  return axios
    .post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: "Bearer " + Auth,
        Accept: "*/*",
      },
    })
    .then((result) => {
      console.log(result);
    })
    .catch((err) => {
      console.log(err);
    });
}

function getClaimList(Auth) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      skip: 0,
      take: 100,
      searchKey: "",
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/CompanyClaims/SubmittedClaimList",
    config
  );
}

function getClaimExpenses(Auth, claimID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      claimID: claimID,
      skip: 0,
      take: 100,
      searchKey: "",
    },
  };

  return axios.get(
    "https://api.servissoft.net/v1/CompanyClaims/GetExpensesInClaim",
    config
  );
}

function postClaimStatus(claimData, Auth) {
  const url = "http://api.servissoft.net/v1/CompanyClaims/ReviewClaim";

  return axios
    .post(url, claimData, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => {
      const options = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => { },
        onKeypressEscape: () => { },
      };
      confirmAlert(options);
      confirmAlert({
        title: "Tebrikler",
        message: "Hakediş Güncellemesi Yapılmıştır",
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
      });
    });
}

function finalizeClaim(claimID, Auth) {
  const url = "http://api.servissoft.net/v1/CompanyClaims/FinalizeClaim";

  const claimObj = { claimID: claimID.toString() };
  return axios
    .post(url, claimObj, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => {
      const options = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => { },
        onKeypressEscape: () => { },
      };
      confirmAlert(options);
      confirmAlert({
        title: "Tebrikler",
        message: "Hakediş Kesinleştirilmiştir.",
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
      });
    });
}

async function getCustomerInfo(Auth, clientID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      portalID: clientID,
    },
  };

  const response = await axios.get(
    "http://api.servissoft.net/v1/DemandManagement/GetCustomerInfo",
    config
  );
  console.log("response.data", response);
  return response.data;
}

async function getPortalDemands(Auth) {
  const response = await axios.get(
    "http://api.servissoft.net/v1/DemandManagement/GetDemands",
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    }
  );
  return response.data.data;
}

async function getPortalDemandsItem(Auth, demandID) {
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      demandID: demandID,
    },
  };

  const response = await axios.get(
    "http://api.servissoft.net/v1/DemandManagement/GetDemandItems",
    config
  );
  return response.data.data;
}

async function postAddDemandsAndItems(Auth, formData) {
  console.log("formData", formData);
  console.log("Auth", Auth);
  return await axios
    .post(
      "http://api.servissoft.net/v1/DemandManagement/AddDemandAndItem",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + Auth,
          Accept: "*/*",
        },
      }
    )
    .then((result) => {
      const options = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => { },
        onKeypressEscape: () => { },
      };
      confirmAlert(options);
      confirmAlert({
        title: "Tebrikler",
        message: translator.successDemand,
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
      });
    });
}

async function createPaymentLink(Auth, data) {
  const response = await axios.post(
    "http://api.servissoft.net/v1/Accounting/AddPortalPayment",
    data,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    }
  );
  return response.data;
}

async function checkPaymentStatus(Auth) {
  await axios
    .get("http://api.servissoft.net/v1/Accounting/CheckPayments", {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(() => {
      window.location.reload(false);
    })
    .catch(() => {
      const options = {
        title: "Title",
        message: "Message",
        closeOnEscape: true,
        closeOnClickOutside: true,
        onClickOutside: () => { },
        onKeypressEscape: () => { },
      };
      confirmAlert(options);
      confirmAlert({
        title: "",
        message: translator.processError,
        buttons: [
          {
            label: "Ok",
            onClick: () => { },
          },
        ],
      });
    });
}


function postRegisterProduct(Auth, apiKey, deviceModelID, serialNo, files, invoiceDate) {
  const url = "https://api.servissoft.net/v1/Customer/RegisterProduct";
  const config = {
    params: {
      deviceModelID: deviceModelID,
      serialNo: serialNo,
      invoiceDate: invoiceDate
    },
  };
  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => {
      console.log("SEND RESULT", result);
      console.log("filesfiles", files);
      files.map(function (file, index) {
        var resultData = { "JobOrderID": result.data };
        postUploadViaWeb(Auth, apiKey, resultData, file, "serialNoFile");
      });
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}


function SearchSerialNoExact(Auth, serialNo) {
  const url = "https://api.servissoft.net/v1/Device/SearchSerialNoExact";
  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + Auth,
    },
    params: {
      serialNo: serialNo
    }
  };

  return axios.get(url, config);
}



function BilkomWarrantyCodeActivation(Auth, serialNoID, code) {
  const url = "https://api.servissoft.net/v1/Customer/BilkomActivateWarrantyCode";
  const config = {
    params: {
      serialNoID: serialNoID,
      code: code
    },
  };
  return axios
    .post(url, config.params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + Auth,
      },
    })
    .then((result) => {
   
      return result;
    })
    .catch((err) => {
      console.log(err);
    });
}