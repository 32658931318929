import React, { useState, useEffect } from 'react';
import translator from '../../actions/translator';
import Header from '../../components/Headers/Header';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import { apiActions } from '../../apiCall/api.services';
import {
    Card,
    Container,
    Row,
  } from 'reactstrap';


const DemandsDetail = () => {
    const [tableData, setTableData] = useState([]);
    const userAuth = JSON.parse(localStorage.getItem('userAuth'));
    const demandId = JSON.parse(localStorage.getItem('DemandsDetail'));



    useEffect(()=>{

console.log("tableData",tableData);
    },[tableData]);



    /*

{
            name: 'EstimatedAmount',
            label: translator.estimatedAmount,
            field: 'EstimatedAmount',
            options: {
            filter: true,
            sort: true,
            },
        },

    */
    
    const columns = [
        {
            name: 'DemandItemID',
            label: translator.demandItemId,
            field: 'DemandItemID',
            options: {
            filter: true,
            sort: true,
            },
        },
        {
            name: 'DemandID',
            label: translator.demandId,
            field: 'DemandID',
            options: {
            filter: true,
            sort: true,
            },
        },
        {
            name: 'Name',
            label: translator.productName,
            field: 'Name',
            options: {
            filter: true,
            sort: true,
            },
        },
        {
            name: 'Piece',
            label: translator.piece,
            field: 'Piece',
            options: {
            filter: true,
            sort: true,
            },
        },
        
        {
            name: 'CreatedTime',
            label: translator.createdDate,
            field: 'CreatedTime',     
            options: {
            filter: true,
            sort: true,
            },
        },
        {
            name: 'FileURL',
            field: 'FileURL',
            label: translator.files,
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => (
                <a href={value}
                className="mt-4"
                color="primary"
                value={value}
                download
                >
                  {value}
                </a>
              ),
            },
          },
    ];


    const options = {
        textLabels: {
            body: {
            noMatch: `${translator.noMatchInTable}`
            }
        },
        filterType: 'dropdown',
    };

    useEffect(() => {
        apiActions.getPortalDemandsItem(userAuth.access_token, demandId)
        .then((data) => data ? data.map((item) => setTableData((prevData) => [...prevData, item])) : null)
        .catch(error => console.log(error));
    },[])

    return (
        <>
            <Header />
            <Container className="mt--7" fluid>   
                <Row>
                    <div className="col">
                        <Card className="shadow">
                            <MuiThemeProvider theme={getMuiTheme()}>
                                <MUIDataTable
                                    title={translator.demandsItem}
                                    data={tableData}
                                    columns={columns}
                                    options={options}
                                />
                            </MuiThemeProvider>
                        </Card>
                    </div>
                </Row>
            </Container>
        </>
    );
}

const getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableHeadCell: {
        root: {
          textAlign: 'center',
          padding: '5px',
          fontSize: '1.1vw'
        }
      },
      MUIDataTableBodyCell: {
        root: {
          textAlign: 'center',
          padding: '20px 5px',
          fontSize: '1vw'
        }
      }
    }
  })

export default DemandsDetail;