import React from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  InputGroup,
} from 'reactstrap';
// core components
import UserHeader from '../../components/Headers/UserHeader.jsx';
import { apiActions } from '../../apiCall/api.services';

import 'react-confirm-alert/src/react-confirm-alert.css';

import userRole from '../../actions/userRole';
import translator from '../../actions/translator';
import { logoutUser } from '../../actions/authActions';
import GooglePlacesAutocomplete, {
  geocodeByPlaceId,
} from 'react-google-places-autocomplete';
// If you want to use the provided css
import 'react-google-places-autocomplete/dist/assets/index.css';
import AddressSelection from "../../components/AddressSelection";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      form: {
        userName: '',
        userLastName: '',
        userPhone: '',
        userEmail: '',
        userCity: '',
        userCityName: '',
        userDisctrict: '',
        userRegion: '',
        userStreet: '',
        userAddress: '',
      },
      getProvincesID: '',
      getCountriesData: [],
      getProvincesData: [],
      getDistrictsData: [],
      getStreetorVillagesData: [],
      allAddressData: {},
    };

    this.postUpdateCustomer = this.postUpdateCustomer.bind(this);
    this.getSingle = this.getSingle.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.getSingle();
  }

  handleChange(event) {
    const tempVal = event.target.value;
    const tempID = event.target.id;
    const store = this.state;
    store.form[event.target.name] = tempVal;
    this.setState(store);
    if (tempID === 'userCity') {
      this.getDistricts(
        tempVal,
        this.state.getProvincesData[tempVal - 1].ProvinceName,
      );
      this.setState({
        getProvincesID: this.state.getProvincesData[tempVal - 1].ProvinceName,
      });
    }

    if (tempID === 'userDisctrict') {
      this.getStreetorVillages(tempVal);
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    this.postUpdateCustomer(
      this.state.form.userName,
      this.state.form.userLastName,
      this.state.form.userPhone,
      this.state.form.userEmail,
      this.state.allAddressData
    );
  }

  getSingle() {
    const currentComponent = this;
    const getSinglePromise = apiActions.getSingle(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.apiKey,
      this.state.userAuthOptions.clientID,
    );
    getSinglePromise.then(function(res) {
      console.log("exist data",res.data);
      currentComponent.setState({
        form: {
          userName: res.data[0].Name,
          userLastName: res.data[0].Surname,
          userPhone: res.data[0].GsmNo,
          userEmail: res.data[0].Email,
          userAddress: res.data[0].AddresSpecs,
          gAdress: res.data[0].Address,
          
        },
      });

      currentComponent.setState({
        existProfileData: res.data
      });
      /*
      currentComponent.setState({ googleAddress: fullData });
        currentComponent.setState({ googleRawAddress: results[0].formatted_address });
        currentComponent.setState({ googleAddressDescription: {country: res.data[0].AddresSpecs,administrative_area_level_1: "",administrative_area_level_2:""} });
        */
    }).catch(error=>{

      console.log("error",error.response.status);
      error.response.status == 401 && logoutUser();
            })
  }

  postUpdateCustomer(userName, userLastName, userPhone, userEmail, address) {
    console.log(this.state.userAuthOptions.accessToken);
    let postUpdateCustomerPromise = apiActions.postUpdateCustomer(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      userName,
      userLastName,
      userPhone,
      userEmail,
      address
    );
    postUpdateCustomerPromise.then();
  }
/*
  parseGoogleAddress(place_id) {
    geocodeByPlaceId(place_id)
      .then(results => {
        const addressComponents = results[0].address_components;
console.log("address results",results);
        var lastData = {};
        var fullData = '';

        var addressComponentsLong = ' ';
        var addressCompType = ' ';

        addressComponents.map(function(address_components, i) {
          addressCompType = address_components.types[0];
          addressComponentsLong = address_components.long_name;

          if (
            addressCompType === 'country' ||
            addressCompType === 'administrative_area_level_1' ||
            addressCompType === 'administrative_area_level_2'
          ) {
            lastData[addressCompType] = addressComponentsLong;
          } else {
            fullData += addressComponentsLong + ',';
          }
        });
        fullData = fullData.slice(0, -1);
        this.setState({ googleAddress: fullData });
        this.setState({ googleRawAddress: results[0].formatted_address });
        this.setState({ googleAddressDescription: lastData });
      })
      .catch(error => console.error(error));
  }
*/
  handleUpdateAdress = (newAddressData) => {
    console.log("handleUpdateAdress");

    this.setState({ allAddressData: newAddressData });
  };


  render() {
    return (
      <>
        <UserHeader />
        {/* Page content */}
        <Container className="mt--7" fluid>
          <Row>
            <Col className="order-xl-1" xl="8">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{translator.myAccount}</h3>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Form role="form" onSubmit={this.handleSubmit}>
                    <h6 className="heading-small text-muted mb-4">
                      {translator.userInformation}
                    </h6>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="userName"
                            >
                              {translator.name}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="userName"
                              placeholder={this.state.form.userName}
                              type="text"
                              name="userName"
                              value={this.state.form.userName}
                              onChange={this.handleChange}
                              required
                              disabled={!userRole().addressContactUpdate}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-surname"
                            >
                              {translator.surname}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="userLastName"
                              placeholder={this.state.form.userLastName}
                              type="text"
                              name="userLastName"
                              value={this.state.form.userLastName}
                              onChange={this.handleChange}
                              required
                              disabled={!userRole().addressContactUpdate}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-phone"
                            >
                              {translator.phone}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="userPhone"
                              placeholder={this.state.form.userPhone}
                              type="text"
                              name="userPhone"
                              value={this.state.form.userPhone}
                              onChange={this.handleChange}
                              required
                              disabled={!userRole().addressContactUpdate}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-email"
                            >
                              {translator.email}
                            </label>
                            <Input
                              className="form-control-alternative"
                              id="userEmail"
                              placeholder={this.state.form.userEmail}
                              type="email"
                              value={this.state.form.userEmail}
                              onChange={this.handleChange}
                              name="userEmail"
                              disabled={!userRole().addressContactUpdate}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr className="my-4" />

                   
                    <AddressSelection updateAddress={this.handleUpdateAdress} existAddress={this.state.existProfileData} />
                    {this.state.allAddressData.street ?(<>
                    <div className="pl-lg-4">
                      <Row>
                                              
                        <Col>
                          <Button color="primary" size="lg" type="submit">
                            {translator.update}
                          </Button>
                        </Col>
                      </Row>
                    </div>

</>
                    ) : (

<>

<div className="text-center text-muted mb-1">
Lütfen adres bilgilerinizi tam girin
              </div>


</>



)}

                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}
//test
export default Profile;
