const initState = {
  authError: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "register":
      return {
        ...state,
        loggedIn: action.payload,
      };
    case "login success":
      return {
        ...state,
        loggedIn: action.payload,
        authError: null,
      };
    case "login error":
      return {
        ...state,
        loggedIn: action.payload,
        authError: "Login Failed",
      };
    default:
      return state;
  }
};

export default authReducer;
