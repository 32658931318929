import React from 'react';
//  reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Media,
  Table,
  Container,
  Row,
  Form,
  FormGroup,
  Input,
  Col,
  Button,
  Label,
  Text,
} from 'reactstrap';
import MUIDataTable from 'mui-datatables';
import { render } from 'react-dom';
import { Digital } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import Modal from 'react-modal';
// core components
import Header from '../../components/Headers/Header.jsx';
import { apiActions } from '../../apiCall/api.services';
import { logoutUser } from '../../actions/authActions';
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { If, Then, ElseIf, Else } from 'react-if-elseif-else-render';
import Moment from 'react-moment';
import ReactFancyBox from 'react-fancybox';
import 'react-fancybox/lib/fancybox.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import userRole from '../../actions/userRole';
import oktetApiCall from '../../apiCall/oktetApiCall';
import translator from '../../actions/translator';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    backgroundColor: 'transparent',
    zIndex: '130',
  },
};

const customStylesReason = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    backgroundColor: 'white',
    zIndex: '130',
  },
};

class OrderDetails extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      testVal: '',
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      getClaimOptions: [],
      getTableData: [],
      getClaimOptionsInfo: [],
      getClaimOptionsClaimDetails: [],
      getClaimOptionsClaimDetailsDate: [],
      getOrdersDetailsOptions: [],
      detectedFaults: [],
      serviceLabourName: [],
      serviceOtherExpenses: [],
      serviceSparePieceExpenses: [],
      serviceTransportationExpenses: [],
      serviceFiles: [],
      ticketNote: '',
      canOpenOrder: '',
      loadingAnimate: true,
      modalIsOpen: true,
      singleReasonModalIsOpen: false,
      selectedClaimRow: [],
      isNoteEmpty: false,
      singleReason: '',
      singleRejectValue: '',
      singleRejectTableMeta: '',
      multipleReason: '',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getClaimDetails = this.getClaimDetails.bind(this);
    this.postStatus = this.postStatus.bind(this);
    this.finalizeClaim = this.finalizeClaim.bind(this);
    this.updateClaim = this.updateClaim.bind(this);
    this.returnButtonForStatus = this.returnButtonForStatus.bind(this);
    this.returnInputForStatus = this.returnInputForStatus.bind(this);
    this.prepareForWhole = this.prepareForWhole.bind(this);
    this.updateInputState = this.updateInputState.bind(this);
    this.checkEmpty = this.checkEmpty.bind(this);
    this.postRejectStatus = this.postRejectStatus.bind(this);
    this.getOrderDetails = this.getOrderDetails.bind(this);
  }

  getOrderDetails(getOrderID) {
    localStorage.setItem('singleClaimID', JSON.stringify(getOrderID));
    //this.props.history.push('/admin/order-details');
    window.open('/admin/single-claim-details');
  }

  postRejectStatus(tableMeta, value) {
    this.setState({ singleReasonModalIsOpen: true });
    this.setState({ singleReason: '' });
    this.setState({ singleRejectValue: value });
    this.setState({ singleRejectTableMeta: tableMeta });
  }

  checkEmpty(getThis) {
    if (this.state.singleReason != '') {
      this.postStatus(
        this.state.singleRejectTableMeta,
        this.state.singleRejectValue,
        true,
      );

      this.setState({ singleReasonModalIsOpen: false });
    }
  }

  updateInputState(value, tableMeta) {
    var updateState = this.state.getClaimOptions;

    updateState[tableMeta.rowIndex].ApprovedCost = value;
  }
  prepareForWhole(statusNo) {
    const selectedRows = this.state.selectedClaimRow;
    const fullTableData = this.state.getClaimOptions;
    var lastDataPost = [];

    const getClaim = this.state.getClaimOptions[0].OwnerClaim;
    var i;
    for (i = 0; i < selectedRows.length; i++) {
      const fulltableOneData = fullTableData[selectedRows[i].index];

      const checkdedApprovedCost = fulltableOneData.ApprovedCost;

      if (fulltableOneData.ApprovedCost == null) {
        checkdedApprovedCost = fulltableOneData.TotalCost;
      }

      lastDataPost.push({
        reviewType: fulltableOneData.Type.toString(),
        reviewObjectID: fulltableOneData.ReviewObjectID.toString(),
        costAgainst: checkdedApprovedCost.toString(),
        note: fulltableOneData.ClaimNote,
        status: statusNo.toString(),
        claimID: getClaim.toString(),
      });
    }

    var statusMessage = '';
    if (statusNo == 0) {
      statusMessage = 'Geri Al ';
    } else if (statusNo == 1) {
      statusMessage = 'Onayla ';
    } else if (statusNo == 2) {
      statusMessage = 'Reddetme ';
    } else if (statusNo == 3) {
      statusMessage = 'Çıkarma İşlemi ';
    }

    const options = {
      title: 'Title',
      message: 'Message',
      closeOnEscape: true,
      closeOnClickOutside: true,
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
    confirmAlert({
      title: 'Uyarı',
      message:
        selectedRows.length +
        ' ' +
        'Adet Hakediş için' +
        ' ' +
        statusMessage +
        ' ' +
        'işlemi yapmak istediğinize emin misiniz ?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => this.updateClaim(lastDataPost, statusNo, false),
        },
      ],
    });
  }

  returnInputForStatus(value, tableMeta, updateValue) {
    const status = tableMeta.rowData[8];
    const approvedCost = tableMeta.rowData[7];
    // Approved value null ise totalcost gönderilir
    var exactValue = value;
    if (value == null) {
      exactValue = tableMeta.rowData[6];
    }

    if (status == 1 || status == 2) {
      return (
        <>
          <div>{approvedCost}</div>
        </>
      );
    } else {
      return (
        <>
          <Input
            type="number"
            name="number"
            id="approvedInput"
            bsSize="sm"
            value={exactValue}
            step="0.11"
            onChange={e => updateValue(Number(e.target.value))}
            onBlur={e => this.updateInputState(value, tableMeta)}
          />
        </>
      );
    }
  }

  returnButtonForStatus(value, tableMeta) {
    const statusMessageLAbel = ['Onaylandı', 'Reddedildi'];

    if (value == 1 || value == 2) {
      return (
        <>
          <div>{statusMessageLAbel[value - 1]}</div>

          <div>
            <Button
              color="link"
              value="{value}"
              type="button"
              size="sm"
              onClick={() => this.postStatus([tableMeta.rowData], 0)}
            >
              {translator.undo}
            </Button>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div>
            <Button
              color="link"
              value="{value}"
              type="button"
              size="sm"
              onClick={() => this.postStatus([tableMeta.rowData], 1)}
            >
              {translator.approve}
            </Button>
          </div>

          <div>
            <Button
              color="link"
              value="{value}"
              type="button"
              size="sm"
              onClick={() => this.postRejectStatus([tableMeta.rowData], 2)}
            >
              {translator.reject}
            </Button>
          </div>

          <div>
            <Button
              color="link"
              value="{value}"
              type="button"
              size="sm"
              onClick={() => this.postStatus([tableMeta.rowData], 0)}
            >
              {translator.undo}
            </Button>
          </div>
        </>
      );
    }
  }
  finalizeClaim() {
    const getClaimFin = this.state.getClaimOptions[0].OwnerClaim;

    const options = {
      title: 'Title',
      message: 'Message',
      closeOnEscape: true,
      closeOnClickOutside: true,
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
    confirmAlert({
      title: 'Uyarı',
      message:
        'Hakedişi kesinleştirme işlemi yapmak istediğinize emin misiniz ?',
      buttons: [
        {
          label: 'Evet',
          onClick: () =>
            apiActions.finalizeClaim(
              getClaimFin,
              this.state.userAuthOptions.accessToken,
            ),
        },
      ],
    });
  }

  postStatus(claimData, statusNo, isSingle) {
    var lastDataPost = [];
    const getClaim = this.state.getClaimOptions[0].OwnerClaim;
    var note = '';

    if (isSingle == true) {
      note = this.state.singleReason;
    } else {
      note = this.state.multipleReason;
    }

    claimData.map(function(claim, i) {
      var approvedData = claim[7];
      if (approvedData == null) {
        approvedData = claim[6];
      }

      lastDataPost.push({
        reviewType: claim[9].toString(),
        reviewObjectID: claim[10].toString(),
        costAgainst: approvedData.toString(),
        note: note,
        status: statusNo.toString(),
        claimID: getClaim.toString(),
      });
    });
    var statusMessage = '';
    if (statusNo == 0) {
      statusMessage = 'Geri Al ';
    } else if (statusNo == 1) {
      statusMessage = 'Onayla ';
    } else if (statusNo == 2) {
      statusMessage = 'Reddetme ';
    } else if (statusNo == 3) {
      statusMessage = 'Çıkarma İşlemi ';
    }

    const options = {
      title: 'Title',
      message: 'Message',
      closeOnEscape: true,
      closeOnClickOutside: true,
      onClickOutside: () => {},
      onKeypressEscape: () => {},
    };
    confirmAlert(options);
    confirmAlert({
      title: 'Uyarı',
      message:
        statusMessage + ' ' + 'işlemi yapmak istediğinize emin misiniz ?',
      buttons: [
        {
          label: 'Evet',
          onClick: () => this.updateClaim(lastDataPost),
        },
      ],
    });
  }

  updateClaim(data) {
    const currentComponent = this;
    const updateClaimPromise = apiActions.postClaimStatus(
      data,
      this.state.userAuthOptions.accessToken,
    );

    updateClaimPromise.then(function(res) {});
    currentComponent.setState({ loadingAnimate: true });
    currentComponent.setState({ modalIsOpen: true });
    this.getClaimDetails();
  }
  getClaimDetails() {
    const orderID = JSON.parse(localStorage.getItem('claimDetails'));
    const currentComponent = this;
    const getClaimDetailsPromise = apiActions.getClaimExpenses(
      this.state.userAuthOptions.accessToken,
      orderID,
    );
    getClaimDetailsPromise.then(function(res) {
      currentComponent.setState({ getClaimOptions: res.data.data });
      currentComponent.setState({ getClaimOptionsInfo: res.data });
      currentComponent.setState({
        getClaimOptionsClaimDetails: res.data.ClaimDetails,
      });
      currentComponent.setState({ loadingAnimate: false });
      currentComponent.setState({ modalIsOpen: false });
    }).catch(error=>{

      console.log("error",error.response.status);
      error.response.status == 401 && logoutUser();
            })
  }

  componentDidMount() {
    this.getClaimDetails();
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();
  }
  // getOrdersDetailsOptions.TotalCost - Ücret
  // getOrdersDetailsOptions.ReferenceID - order ID

  columns = [
    {
      name: 'RefNo',
      field: 'RefNo',
      label: translator.serviceNo,
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'CustomerFullname',
      field: 'CustomerFullname',
      label: translator.customerName,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'SerialNo',
      field: 'SerialNo',
      label: translator.serialNo,
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'EndDate',
      field: 'EndDate',
      label: translator.endDate,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Details',
      field: 'Details',
      label: translator.details,
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'UnitCost',
      field: 'UnitCost',
      label: translator.unitCost,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'TotalCost',
      field: 'TotalCost',
      label: translator.totalCost,
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'ApprovedCost',
      field: 'ApprovedCost',
      label: translator.approvedCost,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{this.returnInputForStatus(value, tableMeta, updateValue)}</div>
        ),
      },
    },
    {
      name: 'ClaimStatus',
      field: 'ClaimStatus',
      label: ' ',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{this.returnButtonForStatus(value, tableMeta)}</div>
        ),
      },
    },
    {
      name: 'Type',
      field: 'Type',
      options: {
        display: 'false',
      },
    },
    {
      name: 'ReviewObjectID',
      field: 'ReviewObjectID',
      options: {
        display: 'false',
      },
    },
  ];

  options = {
    filter: true,
    selectableRows: 'multiple',
    selectableRowsOnClick: false,
    filterType: 'checkbox',
    responsive: 'scrollMaxHeight',
    rowsPerPage: '15',

    onRowsSelect: (currentRowsSelected, allRows) => {
      this.setState({ selectedClaimRow: allRows });
    },
    onRowClick: (rowData, rowMeta) => {},
    onCellClick: (cellIndex, cellMeta) => {
      if (cellMeta.colIndex < 11) {
        this.getOrderDetails(
          this.state.getClaimOptions[cellMeta.colIndex].JobOrderID,
        );
      }
    },
  };

  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          //onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Loading Modal"
        >
          <Digital
            animating={this.state.loadingAnimate}
            color="#52BFE8"
            size={64}
            speed={1}
          />
        </Modal>

        <Modal
          isOpen={this.state.singleReasonModalIsOpen}
          //onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={customStylesReason}
          contentLabel="Reason Modal"
        >
          <div>
            <Label>Lütfen Reddedilme Nedeni giriniz</Label>
            <Input
              type="textarea"
              name="text"
              id="reason"
              onChange={e => this.setState({ singleReason: e.target.value })}
            />

            <Button
              color="success"
              value="asd"
              type="button"
              size="sm"
              className="mt-3"
              style={{ marginRight: 'auto' }}
              onClick={() => this.checkEmpty()}
            >
              Onayla
            </Button>
            <Button
              color="danger"
              value="asd"
              type="button"
              size="sm"
              className="mt-3"
              style={{ marginRight: 'auto' }}
              onClick={() => this.setState({ singleReasonModalIsOpen: false })}
            >
              İptal
            </Button>
          </div>
        </Modal>

        <div className="header bg-gradient-info pb-8 pt-5 pt-md-7">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="12" xl="12">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col text-center">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0 text-center"
                          >
                            {translator.claimID}
                          </CardTitle>
                          <span className="h4 font-weight-bold mb-0 text-center">
                            {this.state.getClaimOptionsClaimDetails.ClaimRefID}
                          </span>
                        </div>

                        <div className="col text-center">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0 text-center"
                          >
                            {translator.serviceCode}
                          </CardTitle>
                          <span className="h4 font-weight-bold mb-0 text-center">
                            {this.state.getClaimOptionsClaimDetails.CompanyCode}
                          </span>
                        </div>

                        <div className="col text-center">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0 text-center"
                          >
                            {translator.serviceName}
                          </CardTitle>
                          <span className="h4 font-weight-bold mb-0 text-center">
                            {this.state.getClaimOptionsClaimDetails.CompanyName}
                          </span>
                        </div>

                        <div className="col text-center">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0 text-center"
                          >
                            {translator.serviceProvince}
                          </CardTitle>
                          <span className="h4 font-weight-bold mb-0 text-center">
                            {this.state.getClaimOptionsClaimDetails.Province}
                          </span>
                        </div>

                        <div className="col text-center">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0 text-center"
                          >
                            {translator.serviceCounty}
                          </CardTitle>
                          <span className="h4 font-weight-bold mb-0 text-center">
                            {this.state.getClaimOptionsClaimDetails.District}
                          </span>
                        </div>

                        <div className="col text-center">
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0 text-center"
                          >
                            {translator.createDate}
                          </CardTitle>
                          <span className="h4 font-weight-bold mb-0 text-center">
                            {this.state.getClaimOptionsClaimDetails.InsertDate}
                          </span>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <MUIDataTable
                title={translator.pastOrders}
                data={this.state.getClaimOptions}
                columns={this.columns}
                options={this.options}
              />
            </div>
          </Row>
          <Row>
            <div className="col" lg={12}>
              <Col lg="7" xl="7" className="float-right">
                <Col lg="6" xl="6" className="float-left mt-5">
                  <div md={6}>
                    <Form>
                      <Row className="mb-2">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          Malzeme Toplam
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateOne"
                            value={this.state.getClaimOptionsInfo.PieceTotal}
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          KDV
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateTwo"
                            value={this.state.getClaimOptionsInfo.PieceKDV}
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          Malzeme Genel Toplam
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateThree"
                            value={this.state.getClaimOptionsInfo.PieceGeneral}
                            disabled
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>

                <Col lg="6" xl="6" className="float-right mt-5">
                  <div md={6}>
                    <Form>
                      <Row className="mb-2">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          Yol Toplam
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateFour"
                            value={
                              this.state.getClaimOptionsInfo.TransportationTotal
                            }
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          İşçilik Toplam
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateFive"
                            value={this.state.getClaimOptionsInfo.LabourTotal}
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          Toplam
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateSix"
                            value={this.state.getClaimOptionsInfo.TotalCost}
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="mb-2">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          KDV
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateSeven"
                            value={this.state.getClaimOptionsInfo.KDVCost}
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="mb-1">
                        <Col
                          md={5}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            fontSize: 14,
                          }}
                        >
                          Genel Toplam
                        </Col>
                        <Col md={7}>
                          <Input
                            type="text"
                            name="state"
                            id="stateEight"
                            value={this.state.getClaimOptionsInfo.GeneralCost}
                            disabled
                          />
                        </Col>
                      </Row>

                      <Row className="mt-2">
                        <Col md={12}>
                          <Label for="exampleText">Reddedilme Nedeni</Label>

                          <Input
                            type="textarea"
                            name="text"
                            id="multi"
                            onChange={e =>
                              this.setState({ multipleReason: e.target.value })
                            }
                          />
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Col>
              </Col>
            </div>
          </Row>
          <Row>
            <div className="col mt-3">
              <Col md={12}>
                <Row
                  style={{
                    display: 'flex',
                  }}
                >
                  <div>
                    <Button
                      color="danger"
                      value="asd"
                      type="button"
                      size="lg"
                      className="mt-3"
                      style={{ marginRight: 'auto' }}
                    >
                      {translator.deleteClaim}
                    </Button>
                  </div>
                  <Button
                    color="primary"
                    value="asd"
                    type="button"
                    size="lg"
                    style={{ marginLeft: 'auto' }}
                    className="mt-3"
                    onClick={() => this.prepareForWhole(1)}
                  >
                    {translator.approve}
                  </Button>

                  <Button
                    color="danger"
                    value="asd"
                    type="button"
                    size="lg"
                    className="mt-3"
                    onClick={() => this.prepareForWhole(2)}
                  >
                    {translator.reject}
                  </Button>

                  <Button
                    color="info"
                    value="asd"
                    type="button"
                    size="lg"
                    className="mt-3"
                    onClick={() => this.prepareForWhole(0)}
                  >
                    {translator.undo}
                  </Button>
                  <Button
                    color="warning"
                    value="asd"
                    type="button"
                    size="lg"
                    className="mt-3"
                  >
                    {translator.reOpenWorkOrder}
                  </Button>
                  <Button
                    color="success"
                    value="asd"
                    type="button"
                    size="lg"
                    className="mt-3"
                    onClick={() => this.finalizeClaim()}
                  >
                    {translator.finalizeClaim}
                  </Button>
                </Row>
              </Col>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default OrderDetails;

/*

  columns = [
    {
      name: 'RefNo',
      field: 'RefNo',
      label: translator.referenceID,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'UsedPieces',
      field: 'UsedPieces',
      label: translator.usedPieces,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'CustomerFullname',
      field: 'CustomerFullname',
      label: translator.customerName,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'SerialNo',
      field: 'SerialNo',
      label: translator.serialNo,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'DeviceName',
      field: 'DeviceName',
      label: translator.deviceName,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'DeviceBrand',
      field: 'DeviceBrand',
      label: translator.brand,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'StartDate',
      field: 'StartDate',
      label: translator.startDate,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'EndDate',
      field: 'EndDate',
      label: translator.endDate,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Details',
      field: 'Details',
      label: translator.details,
      options: {
        filter: true,
        sort: true,
      },
    },

    {
      name: 'UnitCost',
      field: 'UnitCost',
      label: translator.unitCost,
      options: {
        filter: true,
        sort: true,
      },
    },
    {10
      name: 'TotalCost',
      field: 'TotalCost',
      label: translator.totalCost,
      options: {
        filter: true,
        sort: true,
      },
    },

    {11
      name: 'ApprovedCost',
      field: 'ApprovedCost',
      label: translator.approvedCost,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{this.returnInputForStatus(value, tableMeta, updateValue)}</div>
        ),
      },
    },
    {12
      name: 'ClaimStatus',
      field: 'ClaimStatus',
      label: ' ',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{this.returnButtonForStatus(value, tableMeta)}</div>
        ),
      },
    },
    {
      name: 'Type',
      field: 'Type',
      options: {
        display: 'false',
      },
    },
    {
      name: 'ReviewObjectID',
      field: 'ReviewObjectID',
      options: {
        display: 'false',
      },
    },
  ];
  */
