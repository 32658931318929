import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import translator from "../actions/translator";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import personSvg from "../assets/img/person.svg";
import storeSvg from "../assets/img/store.svg";
import centerSvg from "../assets/img/center.svg";
import { apiActions } from "../apiCall/api.services";
import _ from "lodash";

import {
  Button,
  Card,
  CardTitle,
  CardHeader,
  CardImg,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
  CardSubtitle,
} from "reactstrap";
import { responsiveFontSizes } from "@material-ui/core";
import { create } from "nouislider";

const CreateNewDemands = (props) => {
  const [tableData, setTableData] = useState([]);
  const [inputs, setInputs] = useState({});
  const [productInput, setProductInput] = useState("");
  const [qtyInput, setQtyInput] = useState("");
  const [files, setFiles] = useState({});
  const [visibility, setVisibility] = useState(true);
  const userAuth = JSON.parse(localStorage.getItem("userAuth"));
  const portalId = JSON.parse(localStorage.getItem("portalId"));
  const [customerInfo, setCustomerInfo] = useState({});
  const [description, setDescription] = useState("");
  const [filesArray, setFilesArray] = useState([]);

  const columns = [
    {
      name: "DemandItemName",
      field: "DemandItemName",
      label: translator.product,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "DemandItemPiece",
      field: "DemandItemPiece",
      label: translator.piece,
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "File",
      field: "File",
      label: translator.files,
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <a
            href={value.dataUrl}
            className="mt-4"
            color="primary"
            value={value}
            download
          >
            {value.fileName}
          </a>
        ),
      },
    },
  ];

  const options = {
    onRowsDelete: (rows) => {
      let indexArr = [];
      rows.data.map((item) => {
        indexArr.push(item.index);
      });
      _.pullAt(tableData, indexArr);
      setTableData(tableData);
    },
    textLabels: {
      body: {
        noMatch: `${translator.noMatchInTable}`,
      },
    },
    filterType: "dropdown",
  };

  useEffect(() => {
    apiActions
      .getCustomerInfo(userAuth.access_token, portalId)
      .then((response) => {
        response.data
          ? setCustomerInfo({
              demandant: response.data.FullName,
              demandedStore: response.data.FirmName,
              costCenter: response.data.CustomerID,
            })
          : setCustomerInfo({});
      })
      .catch((error) => console.log(error));
  }, []);

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleNewItemSubmit = (event) => {
    event.preventDefault();
    let newItem = {
      DemandItemName: productInput,
      DemandItemPiece: qtyInput,
      File: files ? files : "",
    };

    if (productInput && qtyInput) {
      setTableData([...tableData, newItem]);
    } else {
      confirmAlert({
        title: "",
        message: "Ürün adı ve Parça sayısı eklemelisiniz",
        buttons: [
          {
            label: "Ok",
            onClickOutside: () => {},
          },
        ],
      });
    }
    setProductInput("");
    setQtyInput("");
    setFiles([]);
    //document.getElementById('add-demand-item').reset();
  };

  const handleSave = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.addEventListener(
      "load",
      () => {
        // convert image file to base64 string
        setFiles({
          file: file,
          fileName: file.name,
          dataUrl: reader.result,
        });
      },
      false
    );

    setFilesArray((prevData) => [...prevData, file]);

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const createNewDemands = () => {
    props.setNewDemandVisibility(setVisibility(false));
    let formData = new FormData();

    let data = {
      UserID: parseInt(userAuth.clientID),
      FirmName: "firm name",
      CostCenter: customerInfo.costCenter.toString(),
      DemandItemList: tableData,
      Description: inputs.description,
      DemandType: 0,
    };
    /*
    let data = {
      CustomerID: userAuth.clientID,
      FirmName: customerInfo.FirmName,
      CostCenter: customerInfo.costCenter,
      DemandItemList: tableData,
      Description: inputs.description,
    };
    */

    formData.append("UserID", parseInt(userAuth.clientID));
    formData.append("FirmName", customerInfo.demandedStore);
    formData.append("CostCenter", customerInfo.costCenter.toString());
    formData.append("DemandItemList", JSON.stringify(tableData));
    formData.append("Description", inputs.description);
    formData.append("DemandType", 0);

    apiActions
      .postAddDemandsAndItems(userAuth.access_token, formData)
      .then((response) => {})
      .catch((err) => {});

    /*
    apiActions
      .postAddDemandsAndItems(userAuth.access_token, data)
      .then((response) => {
      });
      */
  };

  return (
    <>
      <div className="row px-3 py-3">
        <Col xs={6} md={4} className="mb-2">
          <Card body outline color="info" style={cardContainer}>
            <CardImg top style={imageContainer} src={personSvg} />
            <CardTitle>{translator.demandant}</CardTitle>
            <CardSubtitle>{customerInfo.demandant}</CardSubtitle>
          </Card>
        </Col>
        <Col xs={6} md={4} className="mb-2">
          <Card body outline color="info" style={cardContainer}>
            <CardImg top style={imageContainer} src={storeSvg} />
            <CardTitle>{translator.demandedStore}</CardTitle>
            <CardSubtitle>{customerInfo.demandedStore}</CardSubtitle>
          </Card>
        </Col>
        <Col xs={6} md={4} className="mb-2">
          <Card body outline color="info" style={cardContainer}>
            <CardImg top style={imageContainer} src={centerSvg} />
            <CardTitle>{translator.costCenter}</CardTitle>
            <CardSubtitle>{customerInfo.costCenter}</CardSubtitle>
          </Card>
        </Col>
      </div>

      <Row>
        <Form
          id="add-demand-item"
          onSubmit={handleNewItemSubmit}
          className="mt-5"
          style={formRowContainer}
        >
          <FormGroup className="mx-3">
            <Label>{translator.product}</Label>
            <Input
              name="product"
              onChange={(e) => {
                setProductInput(e.target.value);
              }}
              type="text"
              value={productInput}
            />
          </FormGroup>
          <FormGroup className="mx-3">
            <Label>{translator.piece}</Label>
            <Input
              name="piece"
              onChange={(e) => {
                setQtyInput(e.target.value);
              }}
              type="text"
              value={qtyInput}
            />
          </FormGroup>
          <FormGroup className="mx-3">
            <Label>{translator.chooseFile}</Label>
            <div className="upload-btn-wrapper" style={{ cursor: "copy" }}>
              <button
                className="upload-btn"
                style={{ cursor: "copy", width: 155 }}
              >
                {files.fileName
                  ? files.fileName.slice(0, 10)
                  : translator.uploadFile}
              </button>
              <Input
                onChange={handleSave}
                type="file"
                id="file"
                className="custom-file-input"
                style={{ cursor: "copy" }}
              />
            </div>
          </FormGroup>
          <div>
            <Button type="submit" className="m-3" color="success">
              {translator.addNewProduct}
            </Button>
          </div>
        </Form>
      </Row>
      {tableData.length > 0 && (
        <MUIDataTable
          title={translator.createNewDemands}
          data={tableData}
          columns={columns}
          options={options}
        />
      )}
      <div className="col-12 mt-4">
        <Card className="shadow">
          <CardHeader className="border-0">
            <h3 className="mb-0">{translator.description}</h3>
          </CardHeader>
          <Form role="form">
            <Input
              onChange={handleInputChange}
              type="textarea"
              rows="5"
              id="description"
              name="description"
              className="form-control-alternative"
              placeholder={translator.description}
            />
          </Form>
        </Card>
      </div>

      <div style={containerRight}>
        <Button onClick={createNewDemands} className="m-3" color="success">
          {translator.createDemand}
        </Button>
      </div>
    </>
  );
};

const formRowContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexWrap: "wrap",
};

const cardContainer = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  fontWeight: "bold",
  textAlign: "center",
};

const containerRight = {
  backgroundColor: "white",
  display: "flex",
  justifyContent: "flex-end",
  alignItems: "center",
  padding: "10px",
};

const imageContainer = {
  maxWidth: "100px",
  marginBottom: "5px",
};

export default CreateNewDemands;
